// Librerías
import React from "react";
// Componentes
// Recursos
import "../../../../assets/styles/PropertyPage/Utils/_location.sass";
import { cancelationsEs, cancelationsEn, cancelationsPo  } from "../../../Utils/CancelationPolicy.js";
import MapPropertyLocation from "./MapPropertyLocation.js";
import useCurrentLanguaje from "../../../../services/currentlenguaje.js";
import tradGlobalJson from "../../../../utilities/traduccGlobal.json";
import { useLanguage } from "../../../../../src/store/lenguaje/useLenguaje.js";


const nearPlaces = [
  {
    id: 1,
    place: "Playa Varese",
    distance: 3.4,
  },
  {
    id: 2,
    place: "Playa Varese",
    distance: 0.3,
  },
  {
    id: 3,
    place: "Playa Chica",
    distance: 0.8,
  },
  {
    id: 4,
    place: "Playa Chica",
    distance: 7.1,
  },
  {
    id: 5,
    place: "Alem",
    distance: 1.4,
  },
  {
    id: 6,
    place: "Alem",
    distance: 0.8,
  },
  {
    id: 7,
    place: "Güemes",
    distance: 1.4,
  },
  {
    id: 8,
    place: "Güemes",
    distance: 2.6,
  },
];

const listNearPlaces = nearPlaces.map((item) => {
  return (
    <div className="PropertyLocation__near__container__item" key={item.id}>
      <p className="PropertyLocation__near__container__item__place">
        {item.place}
      </p>
      <p className="PropertyLocation__near__container__item__distance">
        {item.distance}km
      </p>
    </div>
  );
});

const PropertyLocation = ({ housing }) => {
  const { handleFilterLang } = useCurrentLanguaje();
  const { currentLang } = useLanguage();

  const cancelations = currentLang === "es" ? cancelationsEs 
  : currentLang === "en" ? cancelationsEn
  : currentLang === "po" ? cancelationsPo
  : cancelationsEs


  let {
    startCheckInTime,
    endCheckInTime,
    checkOutTime,
    housingRule,
    ruleExtra,
  } = housing;

  if (startCheckInTime && endCheckInTime && checkOutTime) {
    startCheckInTime = startCheckInTime.slice(0, -3);
    endCheckInTime = endCheckInTime.slice(0, -3);
    checkOutTime = checkOutTime.slice(0, -3);
  }

  if (housingRule === undefined) housingRule = [];

  const marker = {
    lat: parseFloat(housing.lat),
    lng: parseFloat(housing.lng),
  };
  return (
    <>
      <div className="PropertyLocation">
        <MapPropertyLocation marker={marker} />

        <div className="PropertyLocation__rules">
          <div className="PropertyLocation__rules__container">
            <h4 className="PropertyLocation__rules__container__title">
            {handleFilterLang(tradGlobalJson).normasCasa}

              
            </h4>
            <ul className="PropertyLocation__rules__container__list">
              <li className="PropertyLocation__rules__container__list__item">
                Check-in: 
            {handleFilterLang(tradGlobalJson).de}
                 {startCheckInTime} a {endCheckInTime}
              </li>
              <li className="PropertyLocation__rules__container__list__item">
                Check-out: {checkOutTime}
              </li>

              <li className="PropertyLocation__rules__container__list__item">
                {housingRule.includes("smoke")
                  ? handleFilterLang(tradGlobalJson).siFumar

                  : handleFilterLang(tradGlobalJson).noFumar}
              </li>
              <li className="PropertyLocation__rules__container__list__item">
                {housingRule.includes("pets")
                  ? handleFilterLang(tradGlobalJson).siMascotas
                  : handleFilterLang(tradGlobalJson).noMascotas}
              </li>
              <li className="PropertyLocation__rules__container__list__item">
                {housingRule.includes("party")
                  ? handleFilterLang(tradGlobalJson).siFiestas
                  : handleFilterLang(tradGlobalJson).noFiestas}
              </li>
            </ul>
            <br />
            {ruleExtra !== "" ? (
              <>
                <b>{handleFilterLang(tradGlobalJson).reglaExtra} </b>
                <p style={{ marginTop: "20px" }}>{ruleExtra}</p>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="PropertyLocation__rules__container">
            <h4 className="PropertyLocation__rules__container__title">
            {handleFilterLang(tradGlobalJson).politicaCancelacion} 
            </h4>
            <ul className="PropertyLocation__rules__container__list">
              <li className="PropertyLocation__rules__container__list__item">
                {cancelations[housing.cancelation]}
              </li>
              <i style={{ fontSize: "10px" }}>
                *{handleFilterLang(tradGlobalJson).infoCheckin} 
              </i>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyLocation;
