export const BASE_URL = process.env.REACT_APP_URL;
export const BASE_URL_API_PROD =  BASE_URL.includes(3000) ?  process.env.REACT_APP_URL_API_PROD : BASE_URL;

export function validateAuthenticated() {
  // TODO Verificar que el token sea valido cuando recarga la pagina
  //return localStorage.getItem('x-access-token') && localStorage.getItem('x-access-token-expiration') > Date.now()
}

export const loadUserData = () => {
  try {
    const serializedData = localStorage.getItem("userData");
    if (serializedData === null) {
      // Si no existe el state en el local storage
      // devolvemos undefined para que cargue el state inicial
      // que hayamos definido
      return undefined;
    }
    const data = JSON.parse(serializedData);
    return data;
  } catch (error) {
    // Cargar el state inicial.
    console.log(error);
    return undefined;
  }
};
export const getRole = () => {
  try {
    const serializedData = localStorage.getItem("role");
    if (serializedData === null) {
      // Si no existe el state en el local storage
      // devolvemos undefined para que cargue el state inicial
      // que hayamos definido
      return undefined;
    }
    const data = JSON.parse(serializedData);
    return data;
  } catch (error) {
    // Cargar el state inicial.
    console.log(error);
    return undefined;
  }
};

export const loadHousingPublicProcessData = () => {
  try {
    const serializedData = localStorage.getItem("housingPublicProcessData");
    if (serializedData === null) {
      return undefined;
    }

    const data = JSON.parse(serializedData);
    return data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const storeUserData = (state) => {
  try {
    localStorage.setItem("userData", JSON.stringify(state));
  } catch (error) {
    console.log(error);
  }
};

export const storeHousingPublicProcessData = (state) => {
  try {
    localStorage.setItem("housingPublicProcessData", JSON.stringify(state));
  } catch (error) {
    console.log(error);
  }
};

export const parseMoney = (string) => {
  return string
    .toString()
    .split(/(?=(?:\d{3})+(?:,|$))/g)
    .join(".");
};
