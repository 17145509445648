
export const cancelationsEs = {
  'flex': 'Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.',
  'moderate': 'Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos 7 días antes del Check in*.',
  'stric': 'Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos 30 días antes del Check in*.',
  'heavy': 'Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado.'
};
export const cancelationsEn = {
  "flex": "Guests will receive a full refund of the reservation (minus the guest service fee) if they communicate their decision to cancel at least 24 hours before Check-in*. Otherwise, there will be no right to any refund. Once the guest has checked in and is using the services, if they decide to abandon them, they will have no right to a refund for the unused nights.",
  "moderate": "Guests will receive a full refund of the reservation (minus the guest service fee) if they cancel at least 7 days before Check-in*.",
  "stric": "Guests will receive a 50% refund (minus the guest service fee) if they cancel at least 30 days before Check-in*.",
  "heavy": "If the guest cancels the reservation, no amount paid will be refunded."

};
export const cancelationsPo = {
  "flex": "Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se comunicarem sua decisão de cancelar com pelo menos 24 horas de antecedência ao Check-in*. Caso contrário, não haverá direito a qualquer reembolso. Uma vez que o hóspede tenha feito o check-in e esteja usando os serviços, se decidir abandoná-los, não terá direito a reembolso pelas noites não utilizadas.",
  "moderate": "Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se cancelarem pelo menos 7 dias antes do Check-in*.",
  "stric": "Os hóspedes receberão um reembolso de 50% (menos a taxa de serviço do hóspede) se cancelarem pelo menos 30 dias antes do Check-in*.",
  "heavy": "Se o hóspede cancelar a reserva, nenhum valor pago será reembolsado."

}
