import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosAuth from "../../services/requestApi";
import queryString from "query-string";
// Componentes
import { Helmet } from "react-helmet";
import ConfirmedMap from "./Utils/ConfirmedMap";
// Recursos
import "../../assets/styles/Confirmed/__confirmed.sass";
import previajeLegal from "../../assets/static/previaje.pdf";
import { cancelationsEs, cancelationsEn, cancelationsPo } from "../Utils/CancelationPolicy";
import loadingGif from "../../assets/static/loading.gif";
import moment from "moment";
import { parseMoney } from "../../Helpers";
import SuccessReservation from "../../containers/landings/success-reservation-googleTag";
import { useLanguage } from "../../store/lenguaje/useLenguaje"
import useCurrentLanguage from "../../services/currentlenguaje"
import confirmedJson from "./tradConfirmed.json"
import globalJson from "../../utilities/traduccGlobal.json"

moment.locale("es");


const CheckoutComponent = () => {

  const { currentLang } = useLanguage();

  const cancelations = currentLang === "es" ? cancelationsEs
    : currentLang === "en" ? cancelationsEn
      : currentLang === "po" ? cancelationsPo
        : cancelationsEs


  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [reservation, setReservation] = useState();
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [rules, setRules] = useState([]);
  const [price, setPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [previaje, setPreviaje] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [email, setEmail] = useState();
  const [housingTransfer, setHousingTransfer] = useState(null);
  const query = queryString.parse(window.location.search);
  const { from } = query;
  const timestamp = new Date().getUTCMilliseconds();
  const { handleFilterLang } = useCurrentLanguage();


  const { id } = useParams();
  const url = `reservation/${id}`;
  const transferReservation = JSON.parse(
    localStorage.getItem("infoReservation")
  );
  useEffect(() => {
    setHousingTransfer(transferReservation);

    setInit(true);
    AxiosAuth.get(url)
      .then((response) => {
        setReservation(response.data);
        const ci = moment(response.data.checkin).utc(true);
        setCheckin(ci.format("dddd D MMMM"));
        const co = moment(response.data.checkout).utc(true);
        setCheckout(co.format("dddd D MMMM"));
        const _rules = response.data.housing.housingRule.map((r) => r.rule);
        setRules(_rules);
        setTotalPrice(truncar(response.data.totalPrice, 2));
        const prices = response.data.prices;
        setEmail(response.data.email);
        prices.forEach((p) => {
          if (p.priceType === "SERVICE_FEE")
            setServicePrice(truncar(p.total, 2));
          if (p.priceType === "BASE_PRICE") setPrice(truncar(p.total, 2));
          if (p.priceType === "CLEANING_PRICE")
            setCleanPrice(truncar(p.total, 2));
          if (p.priceType === "WEEKLY_DISCOUNT")
            setWeeklyDiscount(truncar(p.total, 2));
          if (p.priceType === "MONTHLY_DISCOUNT")
            setMonthlyDiscount(truncar(p.total, 2));
        });
        setPreviaje(truncar(response.data.previajeAmount, 2));
        setTaxes(truncar(response.data.taxes, 2));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const detailStyle = () => {
    if (reservation && reservation.status === "pending") {
      return {
        height: `70%`,
      };
    } else {
      return {
        height: `auto`,
      };
    }
  };

  const truncar = (num, digitos) => {
    if (num.toString().length < 3) return num;
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);

    return _num ? _num[1] : num;
  };
  return (
    <>
      <SuccessReservation />

      <div className="confirmedContainer">
        <div className="Confirmed">
          {reservation && from === "checkout" ? (
            // reservation && reservation.status === "confirmed" && from === "checkout"?
            <Helmet>
              <script>
                {`
              gtag('event', 'conversion', {
                  'send_to': 'AW-453872768/e0cNCOvIj_EBEICZttgB',
                  'value':${totalPrice},
                  'currency': 'ARS',
                  'transaction_id': ${timestamp}
              });

              fbq('track', 'Purchase', {currency: "ARS", value: ${totalPrice}});
            `}
              </script>
            </Helmet>
          ) : (
            ""
          )}

          {loading ? (
            <img
              style={{ margin: "auto" }}
              src={loadingGif}
              alt={handleFilterLang (globalJson).cargando}
            />
          ) : (
            <>
              <div className="Confirmed__container">
                <h2 className="Confirmed__container__title">
                  {reservation && reservation.status === "pending"
                    ?
                    handleFilterLang (confirmedJson).faltaConfirmacion
                    : ""}
                  {reservation && reservation.status === "confirmed" ? (
                    <>
                    {handleFilterLang (confirmedJson).teVasDeViajeA}
                      
                      {reservation ? reservation.housing.location.location : ""}
                      !
                    </>
                  ) : (
                    ""
                  )}
                </h2>
                <p className="Confirmed__container__text"></p>
                <p className="Confirmed__container__text">
                {handleFilterLang (confirmedJson).confirmacionPorCorreo}
                 
                  <b>{` ${housingTransfer ? housingTransfer.email : email}`}</b>
                  .
                </p>
                {housingTransfer && (
                  <p className="Confirmed__container__text">
                    {handleFilterLang (confirmedJson).infoReserva}
                   
                    <Link
                      to="/profile/travels"
                      className="Confirmed__container__link"
                    >
                       {handleFilterLang (globalJson).misReservas}
                     
                    </Link>
                  </p>
                )}

                <div className="Confirmed__container__separator"></div>
                <div className="Confirmed__container__details">
                  <div className="Confirmed__container__details__rules">
                    <h3 className="Confirmed__container__details__rules__title">
                      {housingTransfer
                        ? handleFilterLang (globalJson).normasCasa
                        : handleFilterLang (confirmedJson).informacionReserva}
                    </h3>
                    <ul className="Confirmed__container__details__rules__list">
                      {housingTransfer && (
                        <>
                          <li className="Confirmed__container__details__rules__list__item">
                            {`Check In: ${housingTransfer.checkin}`}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {`Check Out: ${housingTransfer.checkout}`}
                          </li>
                        </>
                      )}
                      {reservation && (
                        <>
                          <li className="Confirmed__container__details__rules__list__item">
                            Check-in: {handleFilterLang (globalJson).de}
                            {reservation
                              ? reservation.startCheckInTime.slice(0, -3)
                              : ""}
                            {handleFilterLang (globalJson).a}
                            {reservation
                              ? reservation.endCheckinTime.slice(0, -3)
                              : ""}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            Check-out:
                            {reservation
                              ? reservation.checkoutTime.slice(0, -3)
                              : ""}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("smooke") ? handleFilterLang (globalJson).se  : handleFilterLang (globalJson).noSe}{handleFilterLang (globalJson).fumar}
                            
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("pets") ? handleFilterLang (globalJson).se  : handleFilterLang (globalJson).noSe}
                            {handleFilterLang (globalJson).mascotas}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("party") ? handleFilterLang (globalJson).se : handleFilterLang (globalJson).noSe}
                            {handleFilterLang (globalJson).eventos}
                          </li>
                        </>
                      )}
                      <li className="Confirmed__container__details__rules__list__item">
                        {reservation ? reservation.ruleExtra : ""}
                      </li>
                    </ul>
                  </div>
                  <div className="Confirmed__container__details__policy">
                    <h3 className="Confirmed__container__details__policy__title">
                    {handleFilterLang (globalJson).politicaCancelacion}
                     
                    </h3>
                    <p className="Confirmed__container__details__rules__text">
                      {reservation
                        ? cancelations[reservation.cancellationType]
                        : ""}
                      <i style={{ fontSize: "10px" }}>
                      
                        *{handleFilterLang (globalJson).infoCheckin}
                      </i>
                    </p>
                  </div>
                </div>
              </div>
              {/*  && reservation.status === "confirmed"  */}

              {housingTransfer === null && reservation ? (
                <div className="Confirmed__detail" style={detailStyle()}>
                  <h2 className="Confirmed__detail__title">
                    {reservation ? reservation.housing.title : ""}
                  </h2>
                  {reservation.status === "confirmed" ? (
                    <>
                      <p className="Confirmed__detail__location">
                        {reservation ? reservation.housing.adress : ""}{" "}
                        {reservation ? reservation.housing.adressExtra : ""},{" "}
                        {reservation
                          ? reservation.housing.location.location
                          : ""}
                      </p>
                      <div className="Confirmed__detail__map">
                        <ConfirmedMap
                          lat={reservation ? reservation.housing.lat : ""}
                          lng={reservation ? reservation.housing.lng : ""}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="Confirmed__detail__container">
                    <p className="Confirmed__detail__container__dates">
                      Checkin: {checkin}
                    </p>
                    <p className="Confirmed__detail__container__dates">
                      Checkout: {checkout}
                    </p>
                    <p className="Confirmed__detail__container__people">
                    {handleFilterLang (globalJson).huespedes}: {reservation.guests}
                    </p>
                  </div>
                  <ul className="Confirmed__detail__list">
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                      {handleFilterLang (globalJson).tarifaAlojamiento}:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(price)}
                      </p>
                    </li>
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                      {handleFilterLang (globalJson).tarifaLimpieza}:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(cleanPrice)}
                      </p>
                    </li>
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                      {handleFilterLang (globalJson).tarifaServicio}:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(servicePrice)}
                      </p>
                    </li>
                    {taxes > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                        {handleFilterLang (globalJson).impuestosTasas}:
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          $ {parseMoney(taxes)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    {weeklyDiscount > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                        {handleFilterLang (globalJson).descuentoSemanal}:
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          -${parseMoney(weeklyDiscount)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    {monthlyDiscount > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                        {handleFilterLang (globalJson).descuentoMensual}:
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          -${parseMoney(monthlyDiscount)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                      {handleFilterLang (globalJson).total}:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(totalPrice)}
                      </p>
                    </li>
                  </ul>
                  {previaje > 0 ? (
                    <div className="Checkout__detail__list">
                      <div className="Checkout__detail__list__item">
                        <p className="Checkout__detail__list__item__desc">
                        {handleFilterLang (globalJson).creditoPreviaje}:
                        </p>
                        <p className="Checkout__detail__list__item__amount">
                          ${parseMoney(previaje)}
                        </p>
                      </div>
                      <div className="Checkout__detail__list__item">
                        <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                          * 
                        {handleFilterLang (globalJson).leerTyc}
                        {" "}
                          <a href={previajeLegal} target="_blank">
                          {handleFilterLang (globalJson).terminosBasesCondiciones}:
                            
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="Confirmed__detail" style={detailStyle()}>
                  {housingTransfer !== null && (
                    <>
                      <h2 className="Confirmed__detail__title">
                        {housingTransfer ? housingTransfer.title : ""}
                      </h2>
                      <div className="Confirmed__detail__container">
                        <p className="Confirmed__detail__container__dates">
                          Checkin: {housingTransfer.checkin}
                        </p>
                        <p className="Confirmed__detail__container__dates">
                          Checkout: {housingTransfer.checkout}
                        </p>
                        <p className="Confirmed__detail__container__people">
                        {handleFilterLang (globalJson).huespedes}: {housingTransfer.persons}
                        </p>
                      </div>
                      <ul className="Confirmed__detail__list">
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                          {handleFilterLang (globalJson).alojamiento}:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {housingTransfer.price}
                          </p>
                        </li>
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                          {handleFilterLang (globalJson).tarifaLimpieza}:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {housingTransfer.serviceClean}
                          </p>
                        </li>
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                          {handleFilterLang (globalJson).tarifaServicio}:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {housingTransfer.servicePrice}
                          </p>
                        </li>
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                          {handleFilterLang (globalJson).descuentoTransferencia}:
                          
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {housingTransfer.discount}
                          </p>
                        </li>
                        {weeklyDiscount > 0 ? (
                          <li className="Confirmed__detail__list__item">
                            <p className="Confirmed__detail__list__item__desc">
                            {handleFilterLang (globalJson).descuentoSemanal}:

                            </p>
                            <p className="Confirmed__detail__list__item__amount">
                              -${housingTransfer.weeklyDiscount}
                            </p>
                          </li>
                        ) : (
                          ""
                        )}
                        {monthlyDiscount > 0 ? (
                          <li className="Confirmed__detail__list__item">
                            <p className="Confirmed__detail__list__item__desc">
                            {handleFilterLang (globalJson).descuentoMensual}:

                            </p>
                            <p className="Confirmed__detail__list__item__amount">
                              -{housingTransfer.monthlyDiscount}
                            </p>
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                          {handleFilterLang (globalJson).total}:

                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {housingTransfer.total}
                          </p>
                        </li>
                      </ul>
                    </>
                  )}
                  {previaje > 0 ? (
                    <div className="Checkout__detail__list">
                      <div className="Checkout__detail__list__item">
                        <p className="Checkout__detail__list__item__desc">
                        {handleFilterLang (globalJson).creditoPreviaje}:

                        </p>
                        <p className="Checkout__detail__list__item__amount">
                          {parseMoney(previaje)}
                        </p>
                      </div>
                      <div className="Checkout__detail__list__item">
                        <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                          * {handleFilterLang (globalJson).leerTyc}{" "}
                          <a href={previajeLegal} target="_blank">
                          {handleFilterLang (globalJson).terminosBasesCondiciones}
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutComponent;
