import React from "react";
import ImageLazyLoad from "../../ui/image-lazyLoad";
import hertzMobile from "../../../assets/static/Promos/Slide_2_Banner_HERTZ2.webp";
import { useDispatch } from "react-redux";
import { openLoginModal } from "../../../store/modal/actions";
import { loadUserData } from "../../../Helpers";
import tradHertz from "./tradLanding.json";
import useCurrentLanguage from "../../../services/currentlenguaje"

const BannerHertz = ({ codeDiscount }) => {
  const { handleFilterLang } = useCurrentLanguage();
  const dispatch = useDispatch();
  const userData = loadUserData();
  return (
    <div className="relative">
      <div
        className={` py-[26%] hidden   items-center overflow-hidden  h-full w-full  relative sm:flex flex-col justify-center`}
      >
        <span className="absolute top-0 right-0 z-0 w-full h-full bg-center bg-no-repeat bg-contain bg-hertz"></span>

        <p
          aria-label="25% de descuento en el alquiler de tu vehículo."
          className=" text-[2vw] sm:text-[1.3vw] RobotoBold text-center text-white absolute w-full flex justify-center items-center left-[50%] translate-x-[-50%] bottom-[12%]"
        >
          {handleFilterLang(tradHertz).descuentoHertz}
           <br />
          {handleFilterLang(tradHertz).codigoHertz}

          
        </p>
      </div>
      <ImageLazyLoad
        src={hertzMobile}
        classNameContent={"w-full h-full"}
        alt="Europ assitence"
        className={`z-0  bg-hertzMobile sm:hidden  bg-center bg-cover  sm:py-[26%]  h-screen sm:h-full w-full bg-no-repeat   flex items-center justify-center`}
      />
      <button
        className="absolute top-0 left-0 w-full h-full transparent"
        onClick={userData ? codeDiscount : () => dispatch(openLoginModal())}
      >
        
      </button>
    </div>
  );
};

export default BannerHertz;
