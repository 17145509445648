import React from "react";
import useCurrentLanguaje from "../../services/currentlenguaje";
import transferJson from "./tradCheckout.json";

export const TarjetInfoV2 = ({}) => {
  const { handleFilterLang } = useCurrentLanguaje();

  return (
    <>
      <div className="Checkout_form_inputContainer">
        <h1 className="Checkout_forminputContainer_title">
          PROMOCIONES BANCARIAS
        </h1>
        <h2>(únicamente para tarjetas emitidas en Argentina)</h2>
        <br></br>

        <h3 className="Checkout_forminputContainer_description">
          <ul>
            <li>
              - BANCO PROVINCIA DE BUENOS AIRES Visa y Mastercard 6 cuotas SIN
              INTERÉS
            </li>
            <br></br>
            <li>
              - BANCO NACIÓN Visa y Mastercard 6 cuotas SIN INTERÉS por medio de
              QR generado por atención al cliente.
            </li>
            <br></br>
            <li>
              - YOY crédito Mastercard 6 cuotas SIN INTERÉS + REINTEGRO 20%
              (Tope $10.000). Débito REINTEGRO 20% (Tope $10.000)
            </li>
            <br></br>
            <li>
              - CUOTA SIMPLE Visa y Mastercard 3, 6, 9 y 12 cuotas CON INTERÉS
            </li>
            <br></br>
            <li>- AMERICAN EXPRESS 2, 3, 4, 5 Y 6 cuotas CON INTERÉS</li>
            <br></br>
            <li>- NARANJA 5 y 10 cuotas CON INTERÉS y PLAN Z</li>
            <br></br>
          </ul>
        </h3>
        <h2>
          NOTA: Por favor recordá verificar disponibilidad con el alohador.
        </h2>
      </div>
    </>
  );
};
