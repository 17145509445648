import React, { useEffect } from "react";
import { parseMoney } from "../../Helpers";
import lock from "../../assets/static/lock.svg";
import { useHistory } from "react-router-dom";
import useCurrentLanguaje from "../../services/currentlenguaje";
import transferJson from "./tradCheckout.json";


const ButtonTransfer = ({
  urlWhatsApp,
  firstName,
  percentageTransfer,
  discountTransfer,
  id,
  housingTitle,
  persons,
  checkin,
  checkout,
  price,
  cleanPrice,
  servicePrice,
  authorizeTransfer,
  loading,
  pushPath,
  monthlyDiscount,
  weeklyDiscount,
  couponDiscount
}) => {
  const { handleFilterLang } = useCurrentLanguaje();

  const router = useHistory();
  useEffect(() => {
    if (pushPath) {
      setTimeout(() => {
        router.push(`/confirmed/${id}`);
      }, 2000);
    }
  }, [pushPath]);

  return (
    <a
      className={
        loading ? "Checkout__detail__disabled" : "Checkout__detail__btn"
      }
      href={
        urlWhatsApp +
        "Reserva via transferencia bancaria." +
        "%0A" +
        "ID del alohamiento: " +
        id +
        "%0A" +
        "Titulo del alohamiento: " +
        housingTitle +
        "%0A" +
        "Nombre del cliente: " +
        firstName +
        "%0A" +
        "Cantidad de Huéspedes: " +
        parseMoney(persons) +
        "%0A" +
        "Checkin: " +
        parseMoney(checkin) +
        "%0A" +
        "Checkout: " +
        parseMoney(checkout) +
        "%0A" +
        "Alohamiento: " +
        price +
        "%0A" +
        "Descuento semanal: " +
        weeklyDiscount +
        "%0A" +
        "Descuento mensual: " +
        monthlyDiscount +
        "%0A" +
        "Descuento por transferencia: " +
        percentageTransfer +
        "%0A" +
        "Tarifa de limpieza: " +
        cleanPrice +
        "%0A" +
        "Tarifa por servicio: " +
        servicePrice +
        "%0A" +
        "descuento por cupon: " +
        couponDiscount+"%0A" +
        "Total: " + "%0A" +
        discountTransfer 
      }
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => {
        authorizeTransfer();
      }}
    >
      <img src={lock} alt="Confirmar y pagar" />
      {loading ? handleFilterLang(transferJson).procesandoPago : handleFilterLang(transferJson).confirmarTransferir}
    </a>
  );
};

export default ButtonTransfer;
