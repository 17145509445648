// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import Buttons from "../Utils/Buttons";
import Dropzone from "../Utils/Dropzone";
import ErrorMessage from "../../Alerts/ErrorAlert";
import Gallery from "../Utils/Gallery";
import ProgressBar from "../Utils/ProgressBar";
import ButtonEdit from "../Utils/ButtonEdit";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_photos.sass";
import { BASE_URL_API_PROD } from "../../../Helpers";
import ButtonEditAApp from "../Utils/ButtonEditAApp";

class Photos extends Component {
  constructor(props) {
    super(props);
    if (this.props.bodyParameters.length > 0) {
      const files = this.props.bodyParameters;
      this.props.bodyParameters.files = files.map((file) => {
        return {
          URL: BASE_URL_API_PROD + "/" + file.media.replace("public/", ""),
          description: file.description,
          cover: file.cover,
        };
      });
    }

    if (this.props.bodyParameters.length === 0) {
      this.props.bodyParameters.files = [];
    }

    this.state = {
      files: this.props.bodyParameters.files,
      displayDropzone: this.props.bodyParameters.files.length === 0,
      displayGallery: this.props.bodyParameters.files.length > 0,
      id: this.props.housingId,
      modeEdit: this.props.modeEdit || false,
      errors: {},
    };

    this.handleAddFile = this.handleAddFile.bind(this);
    this.handleSizeError = this.handleSizeError.bind(this);
    this.handleTypeError = this.handleTypeError.bind(this);
  }

  handleValidate = () => {
    let errors = {};

    const validate = this.state.files.length >= 5;

    if (this.state.files.length < 5)
      errors["files"] = "Debe elegir al menos 5 imágenes del alojamiento";

    this.setState({ errors: errors });
    this.props.validateStep(validate);
  };

  handleSizeError = (text) => {
    let errors = {};
    if (text !== null || text !== "")
      errors["size"] = "Algunas no fueron cargadas porque superan los 3MB.";
    this.setState({ errors: errors });
  };

  handleTypeError = (text) => {
    let errors = {};
    if (text !== null || text !== "")
      errors["type"] = "Los formatos aceptados son solo JPG, JPEG y PNG.";

    this.setState({ errors: errors });
  };

  componentDidMount() {
    this.handleValidate();
    this.props.photosStep({
      files: this.state.files,
    });
  }

  handleAddFile = (file) => {
    let files = this.state.files;
    files.push(file);
    this.setState(
      {
        files: [...this.state.files],
      },
      () => {
        this.props.photosStep(this.state);
        this.handleValidate();
      }
    );
  };

  async delRemoteFile(fileName) {
    const url = BASE_URL_API_PROD + "/housing/public/media/delete";

    const data = {
      fileName: fileName,
      id: this.state.id,
    };

    const response = await AxiosAuth.post(url, data)
      .then((data) => {})
      .catch((e) => {
        console.log(e);
        return false;
      });

    return response;
  }

  setCoverImage = async (fileName) => {
    const url = BASE_URL_API_PROD + "/housing/public/media/cover";

    const data = {
      fileName: fileName,
      housingId: this.state.id,
    };

    const response = await AxiosAuth.post(url, data)
      .then((data) => {})
      .catch((e) => {
        console.log(e);
        return false;
      });

    return response;
  };

  handleDelFile = (file) => {
    let files = this.state.files;
    let i = files.indexOf(file);
    if (i !== -1) {
      if (!file.URL.includes("blob")) {
        const _name = file.URL.split("/");
        const name = _name[_name.length - 1];
        this.delRemoteFile(name);
      }
      files.splice(i, 1);

      /* Antes se definia cover a la primera foto del array*/
      // if(files.length > 0 ){
      //   if(files[0].URL){
      //     const _name = files[0].URL.split("/");
      //     const name = _name[_name.length-1]
      //     this.setCoverImage(name)
      //   }
      // }
    }

    this.setState(
      {
        files: files,
      },
      () => {
        this.handleValidate();
      }
    );

    if (files.length === 0) {
      this.toggleView();
    }
    this.props.photosStep(this.state);
  };

  handleDescription = (file) => {
    let place = file.id - 1;
    let newFilesState = this.state.files;
    newFilesState[place] = file;

    this.setState(
      {
        files: this.state.files,
      },
      () => {
        this.props.photosStep(this.state);
      }
    );
  };

  toggleView = () => {
    let files = this.state.files;
    let actualFiles = undefined;
    if (files.length !== 0) {
      actualFiles = [...new Set(files)];
    }
    if (this.state.displayDropzone) {
      this.setState({
        displayDropzone: false,
        displayGallery: true,
      });
    } else {
      this.setState({
        displayDropzone: true,
        displayGallery: false,
      });
    }
    if (actualFiles !== undefined) {
      this.setState({
        files: actualFiles,
      });
    }
    this.props.photosStep(this.state);
  };

  render() {
    return (
      <React.Fragment>
        <div className="PhotosResponsive">
          <div className="Photos">
            {this.state.modeEdit ? (
              ""
            ) : (
              <ProgressBar title={"Fotos"} stepAt={"Paso 2: Fotos"} />
            )}
            <div className="Photos__containerError">
              {this.state.errors["files"] ? (
                <ErrorMessage message={this.state.errors["files"]} />
              ) : (
                ""
              )}
              {this.state.errors["size"] ? (
                <ErrorMessage message={this.state.errors["size"]} />
              ) : (
                ""
              )}
              {this.state.errors["type"] ? (
                <ErrorMessage message={this.state.errors["type"]} />
              ) : (
                ""
              )}
            </div>
            <div className="Photos__container">
              {/* {
                this.state.displayDropzone ? */}
              {/* <Dropzone validateStep={this.props.validateStep} files={ this.state.files } handleTypeError={ this.handleTypeError } handleSizeError={ this.handleSizeError } onFileUpload={ this.handleAddFile } toggle={this.toggleView } />
                : */}
              <Gallery
                setCoverImage={this.setCoverImage}
                files={this.state.files}
                handleTypeError={this.handleTypeError}
                handleSizeError={this.handleSizeError}
                onFileUpload={this.handleAddFile}
                delFile={this.handleDelFile}
                toggle={this.toggleView}
                description={this.handleDescription}
              />
              {/* } */}
            </div>
            {this.state.modeEdit ? <ButtonEditAApp /> : <Buttons />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return { ...reducers.userReducer, ...reducers.aloharFormReducer };
};

export default connect(mapStateToProps, aloharFormActions)(Photos);
