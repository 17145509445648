import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCity } from "../../store/search/actions";
import { getSearchedCities } from "../../services/api";
import flagUruguay from "../../assets/static/flags/uruguay.png";
import { isMobile } from "react-device-detect";
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCity: setCity }, dispatch);
}

class SelectCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.placeholder,
      selectedCity: props.selected,
      active: false,
      menuCitys: [],
      citys: [],
      cityId: props.city,
      initDataFromURL: false,
      updateQuery: props.updateQuery,
      newStyle: props.newStyle,
      cityName: props.cityName,
      neighborhood: props.neighborhood,
      cityFilter: [],
      neighborhoods: [],
    };
  }

  componentDidMount() {
    // this.fetchData();
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.initDataFromURL && this.props.selectedCity) {
      this.setState({
        cityId: this.props.selectedCity,
        initDataFromURL: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  async fetchData() {
    const allGlampings = {
      id: 2000,
      location: "Todos los glampings",
      lat: "-38.00517500",
      lng: "-57.55484300",
    };
    const hotSale = {
      id: 501,
      location: "Promos",
      lat: "-38.00517500",
      lng: "-57.55484300",
    };
    try {
      const response = await getSearchedCities();
      const responseCitys = response.data;
      responseCitys.unshift(allGlampings);
      responseCitys.push(hotSale);

      const cityId = parseInt(this.state.cityId);
      const selectedCity = this.state.cityId
        ? allGlampings +
          responseCitys.filter((city) => {
            return city.id === cityId;
          })[0]
        : responseCitys;
      this.setState({
        citys: responseCitys,
        menuCitys: responseCitys,
        selectedCity: selectedCity,
        // neighborhood: city?.neighborhood ? city?.neighborhood : false,
        active: true,
      });
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  }

  handleChange(city) {
    this.setState({
      selectedCity: city,
      active: false,
    });
    this.props.setCity({
      city: city.id,
      cityName: city.location,
      neighborhood: city.neighborhood ? city?.neighborhood : false,
    });
  }

  filterCitys(e) {
    const value = e.target.value.toLowerCase();
    const result = this.state.citys.filter((city) => {
      return (
        city.location.toLowerCase().includes(value.toLowerCase()) ||
        (city.country &&
          city.country.country.toLowerCase().includes(value.toLowerCase()))
      );
    });

    this.setState({
      selectedCity: e.target.value,
      menuCitys: result,
      active: true,
    });
  }

  hideFilter() {
    this.setState({
      active: false,
    });
  }

  handleClick = (e) => {
    if (this.node !== null && !this.node.contains(e.target)) {
      this.hideFilter();
    }
  };

  render() {
    const { selectedCity, active, menuCitys, newStyle } = this.state;
    const { placeholder } = this.props;
    return (
      <div
        className={
          newStyle ? "input_wrapperNew   relative h-min" : "input_wrapper p-0"
        }
        id="select_city"
        ref={(node) => (this.node = node)}
      >
        <input
          className={`${
            newStyle ? "text-left " : "text-left sm:text-start"
          } relative text-center sm:text-start input`}
          onClick={(e) => this.fetchData(e)}
          onChange={(e) => this.filterCitys(e)}
          type="text"
          value={selectedCity && selectedCity.location}
          placeholder={placeholder}
        />
        {active && (
          <div
            className={`z-10 flex h-[200px] min-w-full pb-[20px] ${
              isMobile ? "" : "absolute left-[-20px] top-[100%]"
            }  bg-white overflow-y-auto rounded-br-[30px] rounded-bl-[30px]`}
          >
            <div className={`h-full p-0  pb-2 ${isMobile ? "" : "px-5"}`}>
              <ul className="h-full scrollBar">
                {menuCitys.map((city, index) => {
                  return (
                    <li
                      className={`flex items-center gap-2 cursor-pointer hover:bg-gray-50 ${
                        isMobile ? "text-[4.2vw]" : "text-[1.4vw]"
                      }`}
                      key={index}
                      onClick={() => this.handleChange(city)}
                    >
                      {`${city.location}`}
                      {city.countryId === 2 ? (
                        <img width={16} src={`${flagUruguay}`} alt="uruguay" />
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.searchReducer;
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCity);
