import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingGift from "../../../../assets/components-icons/LoadingGift";
import { BASE_URL } from "../../../../Helpers";
import AxiosAuth from "../../../../services/requestApi";
import "./card-detail.sass";
import useCurrentLanguage from "../../../../services/currentlenguaje";
import tradGlobal from "../../../../utilities/traduccGlobal.json"


const CardDetail = ({ housing }) => {
  const { handleFilterLang } = useCurrentLanguage();

  const router = useHistory();
  const [housingDetail, setHousingDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cityName, setCityName] = useState([]);
  useEffect(() => {
    setLoading(true);
    const url = `${BASE_URL}/housing/card/${housing.id}`;
    AxiosAuth.get(url)
      .then((response) => {
        setHousingDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [housing.id]);

  useEffect(() => {
    const url = `${BASE_URL}/location/search`;
    axios.get(url).then((response) => {
      const city = response.data;
      setCityName(city);
    });
  }, []);
  const pushLocation = (location, housingId) => {
    const filterCity = cityName.find((e) => e.id === location);
    const city = filterCity.location.replace(/\s+/g, "-");
    router.push(`/${city}/property/${housingId}`);
  };
  return (
    <>
      {loading ? (
        <LoadingGift size={50} />
      ) : (
        housingDetail  && (
          <button
            onClick={() => pushLocation(housingDetail.locationId, housing.id)}
            disabled={housingDetail.public ? false : true}
            className="cardDetail"
          >
            <div className="cardDetail__card">
              <img
                className="cardDetail__card__img"
                src={housingDetail.cover && `${BASE_URL}/${housingDetail.cover.media.replace(
                  "public/",
                  ""
                )}`}
                alt={"Foto"}
              />
              <h2 className="cardDetail__card__title">{housingDetail.title}</h2>

              <p className="cardDetail__card__price">
              {`ARS $${housingDetail.basePrice} ${handleFilterLang(tradGlobal).laNoche}`}
              </p>
            </div>
            <div className="cardDetail__container">
              {!housingDetail.public && (
                <h2 className="cardDetail__card__title">
                  {handleFilterLang(tradGlobal).alojamientoNoDisponible}
                  
                </h2>
              )}
              <p className="cardDetail__container__detail">
                  {handleFilterLang(tradGlobal).huespedes}
                {`: ${housingDetail.guests}`}
              </p>
              <p className="cardDetail__container__detail">
                {handleFilterLang(tradGlobal).dormitorios}{`: ${housingDetail.rooms}`}
              </p>
              <p className="cardDetail__container__detail">
              {handleFilterLang(tradGlobal).camas} {`: ${housingDetail.simple_beds}`}
              </p>
              <p className="cardDetail__container__detail">
              {handleFilterLang(tradGlobal).baños}{`: ${housingDetail.bathrooms}`}
              </p>
            </div>
          </button>
        )
      )}
    </>
  );
};

export default CardDetail;
