// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
// Componentes
import Buttons from '../Utils/Buttons';
import ErrorMessage from '../../Alerts/ErrorAlert';
import Numbers from '../Utils/Numbers';
import ProgressBar from '../Utils/ProgressBar';
import ButtonEdit from '../Utils/ButtonEdit';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_quantities.sass';
import QuantitiesJson from "../json/tradAloharForm.json";


/**
 * Formulario:
 * Cantidad de huéspedes: guests_quantity
 * Cantidad de habitaciones: rooms_quantity
 * Cantidad de camas: beds_quantity
 * Cantidad de baños: bathrooms_quantity
 */

class Quantities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guests: this.props.bodyParameters.guests || 0,
      rooms: this.props.bodyParameters.rooms || 0,
      simple_beds: this.props.bodyParameters.simple_beds || 0,
      double_beds: this.props.bodyParameters.double_beds || 0,
      bathrooms: this.props.bodyParameters.bathrooms || 0,
      errors : {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate,
      lenguaje: this.props.lenguaje || "es",
    };
  }

  componentDidMount() {
    this.props.quantitiesStep(this.state)
    this.handleValidate({showError:false})
    this.setState({
      lenguaje: this.props.lenguaje,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if
    (this.props.count_validate !== prevProps.count_validate && !isNaN(this.props.count_validate)){
      this.handleValidate({showError:true});
    }
    if (this.props.lenguaje !== prevProps.lenguaje) {
      this.setState({
          lenguaje: this.props.lenguaje,
      });
  }
}



  handleValidate = ({inputName,showError}) =>{
    const messengers = {
      guests: this.currentLenguaje(QuantitiesJson).errorCantidadHuespedes,
      rooms: this.currentLenguaje(QuantitiesJson).errorCantidadHabitaciones,
      beds: this.currentLenguaje(QuantitiesJson).errorCantidadCamas,
      bathrooms: this.currentLenguaje(QuantitiesJson).errorCantidadBaños
    };
    let errors = {};

    const validate = (
        this.state.guests > 0
        && this.state.rooms > 0
        && (this.state.double_beds + this.state.simple_beds) > 0
        && this.state.bathrooms > 0
      )

    if (inputName) {
      if(inputName === "guests" && this.state.guests === 0)
        errors['guests'] = messengers['guests']
      if(inputName === "rooms" && this.state.rooms === 0)
        errors['rooms'] = messengers['rooms']
      if(inputName === "simple_beds" && (this.state.double_beds + this.state.simple_beds < 1))
        errors['beds'] = messengers["beds"]
      if(inputName === "double_beds" && (this.state.double_beds + this.state.simple_beds < 1))
        errors['beds'] = messengers["beds"]
      if(inputName === "bathrooms" && this.state.bathrooms === 0)
        errors['bathrooms'] = messengers["bathrooms"]
    } else {
      if(this.state.guests === 0)
        errors['guests'] = messengers['guests']
      if(this.state.rooms === 0)
        errors['rooms'] = messengers['rooms']
      if(this.state.double_beds + this.state.simple_beds < 1)
        errors['beds'] = messengers["beds"]
      if(this.state.bathrooms === 0)
        errors['bathrooms'] = messengers['bathrooms']
    }
    if(showError)
      this.setState({errors:errors})
    this.props.validateStep(validate);
  }

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({[nam]: val}, ()=>{
        this.props.quantitiesStep(this.state)
        this.handleValidate({inputName:nam, showError:true});
      }
    );
  }

  currentLenguaje = (json) => {
   
    return json[this.state.lenguaje];
  };

  render(){
    return(
      <React.Fragment>
        <div className='QuantitiesResponsive'>
          <div className="Quantities">
          {this.state.modeEdit ? '' :  <ProgressBar title={this.currentLenguaje(QuantitiesJson).cantidadDeHuespedes} stepAt={'Paso 1: Lo esencial'} />  }
            <div className="Quantities__container">
              <div className="Quantities__container__textcontainer">
                <h2 className="Quantities__title">
                {this.currentLenguaje(QuantitiesJson).cantidadHuespedes}
                  
                </h2>
                <div className="Quantities__container__numcontainer">
                  <Numbers
                      name="guests"
                      title={this.currentLenguaje(QuantitiesJson).huespedes}
                      customClickEvent={this.handleChange.bind(this)}
                      number={this.state.guests}
                  />
                 
                </div>
              </div>
              <hr className='Quantities__container__separator'/>
              <div className="Quantities__container__textcontainer">
                <div className="Quantities__container__textcontainer__dashed">
                <h2 className="Quantities__title">
                {this.currentLenguaje(QuantitiesJson).cantidadHabitaciones}

                  
                </h2>
                <p className="Quantities__text">
                {this.currentLenguaje(QuantitiesJson).aclaracionHabitaciones}

                </p>
                </div>
              <div className="Quantities__container__numcontainer">
                <Numbers name="rooms" title={this.currentLenguaje(QuantitiesJson).habitaciones}

                  customClickEvent={this.handleChange.bind(this)}
                  number={this.state.rooms}
                  value={this.state.rooms}
                  amount={.5}
                />
               
              </div>
              </div>
              <hr className='Quantities__container__separator'/>
              <div className="Quantities__container__textcontainer">
                <h2 className="Quantities__title">
                {this.currentLenguaje(QuantitiesJson).cantidadCamas}
                  
                </h2>
              <div className="Quantities__container__numcontainer">
                <Numbers name="simple_beds" title={this.currentLenguaje(QuantitiesJson).individuales}

                  customClickEvent={this.handleChange.bind(this)}
                  number={this.state.simple_beds}
                  value={this.state.simple_bed}
                />
                <Numbers name="double_beds" title={this.currentLenguaje(QuantitiesJson).matrimoniales}
                  customClickEvent={this.handleChange.bind(this)}
                  number={this.state.double_beds}
                  value={this.state.double_beds}
                />
               
              </div>
              </div>
              <hr className='Quantities__container__separator'/>
              <div className="Quantities__container__textcontainer">
                <div className="Quantities__container__textcontainer__dashed">
                  <h2 className="Quantities__title">
                  {this.currentLenguaje(QuantitiesJson).cantidadBaños}
                    
                  </h2>
                  <p className="Quantities__text">
                  {this.currentLenguaje(QuantitiesJson).aclaracionBaños}
                    
                  </p>
                </div>
              <div className="Quantities__container__numcontainer">
                <Numbers name="bathrooms" title={this.currentLenguaje(QuantitiesJson).baños}
                  customClickEvent={this.handleChange.bind(this)}
                  number={this.state.bathrooms}
                  value={this.state.bathrooms}
                  amount={.5}
                />
                
              </div>
              </div>
              <div  className="Quantities__container__error">
                    {
                    this.state.errors["guests"]
                    ? <ErrorMessage message={this.state.errors["guests"]} />
                    : ''
                  }
                   {
                  this.state.errors["rooms"]
                  ? <ErrorMessage message={this.state.errors["rooms"]} />
                  : ''
                }
                    {
                  this.state.errors["beds"]
                  ? <ErrorMessage message={this.state.errors["beds"]} />
                  : ''
                }
                {
                  this.state.errors["bathrooms"]
                  ? <ErrorMessage message={this.state.errors["bathrooms"]} />
                  : ''
                }
              </div>
            </div>
            { this.state.modeEdit ? <ButtonEdit lenguaje = {this.props.lenguaje}/> : <Buttons lenguaje = {this.props.lenguaje}/> }
          </div>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer
};

export default connect(mapStateToProps ,aloharFormActions)(Quantities);