import React from "react";
import { CircleMarker, Popup, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import CardSlider from "../CardSlider";
import "./styles/leaflet-map.sass";
import useCurrentLanguaje from "../../../../services/currentlenguaje";
import globalJson from "../../../../utilities/traduccGlobal.json";


const Markers = ({ geoLat }) => {
  const { handleFilterLang } = useCurrentLanguaje();

  const [cover, setCover] = useState("");

  return (
    <>
      {geoLat.map((e) => (
        <CircleMarker
          center={[e.lat, e.lng]}
          pathOptions={{ color: "transparent" }}
          radius={20}
          key={e.id}
        >
          <Tooltip direction="center" offset={[0, 0]} opacity={1} permanent>
            <div className="tooltip">
              <span className="tooltip__title">
                {`$ ${e.basePrice
                  .toString()
                  .split(/(?=(?:\d{3})+(?:,|$))/g)
                  .join(".")}`}
              </span>
              <Popup>
                <div className="tooltip__container">
                  <div className="InfoWindow">
                    <CardSlider
                      cover={cover}
                      housingMedia={e.housingMedia}
                      map={true}
                    />
                    <div className="InfoWindow__container">
                      <p className="InfoWindow__title">{e.title}</p>
                      <p className="InfoWindow__description">
                      {handleFilterLang(globalJson).precioNoche}
                        {`$ ${e.basePrice
                          .toString()
                          .split(/(?=(?:\d{3})+(?:,|$))/g)
                          .join(".")} ARS`}
                      </p>
                      <Link to={`property/${e.id}`} className="InfoWindow__btn">
                        <p className="InfoWindow__btn__text">
                        {handleFilterLang(globalJson).consultarDisponibilidad}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </Tooltip>
        </CircleMarker>
      ))}
    </>
  );
};

export default Markers;
