// src/components/PaymentForm.js
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../Helpers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import AxiosAuth from "../../../services/requestApi";
import moment from "moment";
import LoadingGift from "../../../assets/components-icons/LoadingGift";
import LocalStorageService from "../../../services/localStorageService";
import RequiredUserData from "../required-user-data";
import axios from "axios";
import ModalGlobal from "../../Modals/modal-global";
import ButtonPrimary from "../../ui/button-primary";
import ContainerModal from "../../Modals/container-modal";
import useCurrentLanguaje from "../../../services/currentlenguaje";
import globalJson from "../../../utilities/traduccGlobal.json"

const PaymentForm = ({ totalPrice, bodyParams }) => {
  const { handleFilterLang } = useCurrentLanguaje();

  const router = useHistory();
  const [realTotalPrice, setRealTotalPrice] = useState(totalPrice);
  const clickMobex = useRef(null);
  const [loading, setLoading] = useState(false);
  const [validDataUser, setValidDataUser] = useState(false);
  const [validPricesModal, setValidPricesModal] = useState(false);
  // Función para obtener los parámetros de la URL
  const getParamsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      token: urlParams.get("token"),
      refreshToken: urlParams.get("refreshToken"),
      app: urlParams.get("app"),
    };
  };

  const { token, refreshToken } = getParamsFromUrl();

  useEffect(() => {
    if (token) {
      LocalStorageService.setToken(token);
    }
    if (refreshToken) {
      LocalStorageService.setRefreshToken(refreshToken);
    }
  }, []);
  const aprobePricesReservation = () => {
    const urlCost =
      BASE_URL + `/housing/${bodyParams.housingId}/reservations/cost`;
    const bodyParameters = {
      id: bodyParams.housingId,
      checkin: moment(bodyParams.checkin, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(bodyParams.checkout, "D/M/YY").format("YYYY-MM-DD"),
      guests: bodyParams.guests,
      coupon: bodyParams.couponId,
    };
    axios
      .post(urlCost, bodyParameters)
      .then((response) => {
        setRealTotalPrice(response.data.totalPrice);
        if (response.data.totalPrice !== parseInt(totalPrice)) {
          setValidPricesModal(true);
        } else {
          generateCheckout(false, response.data.totalPrice);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeMobbex = () => {
    if (clickMobex.current) {
      clickMobex.current.innerHTML = "";
    }

    const script = document.createElement("script");
    script.src = `https://res.mobbex.com/js/embed/mobbex.embed@1.0.23.js?t=${Date.now()}`;
    script.addEventListener("load", () => {
      // Configurar las opciones de Mobbex
      const options = {
        embed: true,
        // Configuración de Mobbex...
      };
      window.MobbexEmbed.render(options, "#mbbx-container");
    });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    initializeMobbex();
  }, []);

  const createReservation = (
    mobbexCheckoutId,
    mobbexOperationId,
    totalPriceWithInteres
  ) => {
    const body = {
      checkin: moment(bodyParams.checkin, "DD/MM/YY").format("YYYY-MM-DD"),
      checkout: moment(bodyParams.checkout, "DD/MM/YY").format("YYYY-MM-DD"),
      guests: bodyParams.guests,
      total: totalPriceWithInteres.toString(),
      mobbexCheckoutId: mobbexCheckoutId,
      mobbexOperationId: mobbexOperationId,
    };
    AxiosAuth.post(
      `${BASE_URL}/v2/housing/${bodyParams.housingId}/reservations`,
      body
    )
      .then((response) => {
        router.push(`/confirmed/${response.data.id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const generateCheckout = async (validPrice, totalPrice) => {
    setLoading(true);
    if (validPrice) {
      return aprobePricesReservation();
    }

    const responseUser = await AxiosAuth.get(`${BASE_URL}/user/myProfile`).then(
      (response) => {
        return response.data;
      }
    );
    if (!responseUser.cuit || !responseUser.phoneNumber) {
      setLoading(false);
      return setValidDataUser(true);
    }

    setLoading(true);
    try {
      await AxiosAuth.post(`${BASE_URL}/payment/checkout`, {
        total: totalPrice ? totalPrice : parseInt(realTotalPrice),
        description: `${
          bodyParams.typeCard === "debit"
            ? "reserva con tarjeta de  debito"
            : "reserva con tarjeta de credito"
        }`,
        currency: "ARS",
        returnUrl: "https://alohar.me/alojamiento-temporal/",
        executeCapture:
          bodyParams.reservationInmediate === "immediate" ? true : false,
        sourceType:
          bodyParams.typeCard === "debit" ? "debit_card" : "credit_card",
        customer: {
          email: responseUser.email,
          name: responseUser.firstName,
          identification: responseUser.cuit.replace(/\./g, ""),
          phone: responseUser.phoneNumber,
        },
      })
        .then((response) => {
          const parts = response.data.checkoutUrl.split(
            "https://mobbex.com/p/checkout/v2/"
          );
          const code = parts[1];
          if (clickMobex.current) {
            clickMobex.current.innerHTML = "";
          }

          const script = document.createElement("script");
          script.src = `https://res.mobbex.com/js/embed/mobbex.embed@1.0.23.js?t=${Date.now()}`;
          script.addEventListener("load", () => {
            // Configurar las opciones de Mobbex

            const options = {
              embed: true,
              id: code,
              type: "checkout",
              text: "asdsd",

              onResult: (data) => {
                window.MobbexEmbed.close();
              },

              onPayment: (response) => {
                if (
                  response.data.status.code === "200" ||
                  response.data.status.code === "3"
                ) {
                  createReservation(
                    code,
                    response.data.id,
                    response.data.total
                  );
                }
              },
              onOpen: (pago) => {
                console.info("Pago iniciado.");
              },
              onClose: (cancelled) => {
                console.log(cancelled);
                const errorMessage = { cancelled: cancelled };
                AxiosAuth.post(
                  `${BASE_URL}/reservation-log-error`,
                  errorMessage
                )
                  .then((response) => {})
                  .catch((error) => {});
              },
              onError: (error) => {
                const errorMessage = { error: error };
                AxiosAuth.post(
                  `${BASE_URL}/reservation-log-error`,
                  errorMessage
                )
                  .then((response) => {})
                  .catch((error) => {});
              },
            };
            window.MobbexEmbed.render(options, "#mbbx-container");
            var mbbxButton = window.MobbexEmbed.init(options);
            mbbxButton.open();
            setLoading(false);
          });

          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error generating checkout:", error);
      throw error;
    }
  };

  return (
    <>
      <RequiredUserData
        onSuccess={generateCheckout}
        openModal={validDataUser}
        setOpenModal={setValidDataUser}
      />
      <ModalGlobal close={validPricesModal}>
        <ContainerModal
          setOpenModal={setValidPricesModal}
          openModal={validPricesModal}
        >
          <h2 className="w-3/5 mx-auto text-center">
          {handleFilterLang(globalJson).errorPrecioCalculado}
            
          </h2>
          <ButtonPrimary
            title={"Continuar"}
            onClick={() => {
              setValidPricesModal(false);
              generateCheckout(false);
            }}
          />
        </ContainerModal>
      </ModalGlobal>
      <button
        onClick={() => generateCheckout(true)}
        className="mt-4 cursor-pointer Checkout__detail__btn"
      >
        {loading ? <LoadingGift size={"30px"} /> : 
        
                  handleFilterLang(globalJson).reservar
                  }
      </button>

      <div className="mobbexContainer">
        <div
          className="mobbexButton"
          id="mbbx-container"
          ref={clickMobex}
        ></div>
      </div>
    </>
  );
};

export default PaymentForm;
