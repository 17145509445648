import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Reservation from "./Reservation";
import "../../assets/styles/ConfirmReservations/_confirmReservations.sass";
import {
  openContactAlohadorModal,
  openCancelReservationModal,
} from "../../store/modal/actions";
import ModalWrapper from "../Modals/ModalWrapper";
import MessengerModal from "../Modals/MessengerModal";
import CancelReservationModal from "../Modals/CancelReservationModal";
import moment from "moment";
import GlobalModal from "../Modals/global-modal";
import ReservationsErrorModal from "./modal-error";
import backgroundModal from "../../assets/static/backgrounds/background-feet.png";
import reservationListJson from "./tradConfirmReservations.json";
import useCurrentLanguage from "../../services/currentlenguaje";

moment.locale("es");

const ReservationList = ({ reservations, getAllreservation }) => {
  const [type, setType] = useState("pending");
  const [housingId, setHousingId] = useState();
  const [firstName, setFirstName] = useState();
  const [userId, setUserId] = useState();
  const [reservationId, setReservationId] = useState();
  const [modalClose, setModalClose] = useState(false);
  const [typeReservation, setTypeReservation] = useState(null);
  const hoy = moment().utc(true);
    const { handleFilterLang } = useCurrentLanguage();

  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const dispatch = useDispatch();

  const selectModal = (modalType) => {
    switch (modalType) {
      case "CONTACT_ALOHADOR":
        return (
          <ModalWrapper key="contacto_modal">
            <MessengerModal
              firstName={firstName}
              housingId={housingId}
              userId={userId}
            />
          </ModalWrapper>
        );
      case "CANCELATION_RESERVATION":
        return (
          <ModalWrapper key="cancel_modal">
            <CancelReservationModal
              reservations={reservations}
              reservationId={reservationId}
              typeReservation={typeReservation}
              getAllreservation={getAllreservation}
            />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };

  const handleCancelationModal = (id, reservation) => {
    setReservationId(id);
    setTypeReservation(reservation.transferConfirmation);

    dispatch(openCancelReservationModal());
  };

  const handleOpenContactAlohadorModal = (firstName, housingId, userId) => {
    setHousingId(housingId);
    setUserId(userId);
    setFirstName(firstName);
    dispatch(openContactAlohadorModal());
  };
  const linkWpp = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5492233018749&text=¡Hola Alohar! Quisiera que me contacten.",
      "_blank"
    );
  };
  return (
    <div className="wrapper_reservations">
      <GlobalModal
        close={modalClose}
        onClick={() => linkWpp()}
        onClose={() => setModalClose(false)}
        backgroundImage={backgroundModal}
        titleButton={handleFilterLang(reservationListJson).contactanos}

      >
        <ReservationsErrorModal />
      </GlobalModal>
      <div className="wrap_type_travels">
        <div className="from_reservations">
          <div
            className={`from_reservations__select ${
              type === "pending" ? "selected" : ""
            }`}
            onClick={() => setType("pending")}
          >
            {handleFilterLang(reservationListJson).pendientes}
            
          </div>
          <div
            className={`from_reservations__select ${
              type === "next" ? "selected" : ""
            }`}
            onClick={() => setType("next")}
          >
            {handleFilterLang(reservationListJson).proximas}
            
          </div>
          <div
            className={`from_reservations__select ${
              type === "past" ? "selected" : ""
            }`}
            onClick={() => setType("past")}
          >
            {handleFilterLang(reservationListJson).pasadas}
            
          </div>
          <div
            className={`from_reservations__select ${
              type === "canceled" ? "selected" : ""
            }`}
            onClick={() => setType("canceled")}
          >
            {handleFilterLang(reservationListJson).canceladas}
            
          </div>
        </div>
        <div className="list_reservations">
          {
            // eslint-disable-next-line array-callback-return
            reservations
              .filter((r) => {
                const checkin = moment(r.checkin).utc(true);
                const checkout = moment(r.checkout).utc(true);
                if (type === "next")
                  return (
                    (checkin >= hoy || checkout >= hoy) &&
                    r.status === "confirmed"
                  );
                if (type === "past")
                  return checkout < hoy && r.status === "confirmed";
                if (type === "canceled") return r.status === "cancelled";
                if (type === "confirmed")
                  return (
                    r.transferConfirmation === 2 || r.status === "confirmed"
                  );
                if (type === "pending") {
                  if (r.transferConfirmation !== 2 && r.status === "pending") {
                    return r.status === "pending";
                  }
                }
              })
              .sort((a, b) => {
                const a_checkin = moment(a.checkin).utc(true);
                const b_checkin = moment(b.checkin).utc(true);
                return a_checkin >= b_checkin ? 1 : -1;
              })
              .map((r) => (
                <Reservation
                  key={r.id}
                  states={type}
                  reservation={r}
                  handleOpenContactAlohadorModal={
                    handleOpenContactAlohadorModal
                  }
                  getAllreservation={getAllreservation}
                  setModalClose={setModalClose}
                  handleCancelationModal={handleCancelationModal}
                />
              ))
          }
        </div>
      </div>

      {modalState.isOpen ? selectModal(modalState.type) : ""}
    </div>
  );
};

export default ReservationList;
