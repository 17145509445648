// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Componentes
import Buttons from "../Utils/Buttons";
import ErrorMessage from "../../Alerts/ErrorAlert";
import ProgressBar from "../Utils/ProgressBar";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_prices.sass";
import ButtonEditAApp from "../Utils/ButtonEditAApp";
import ButtonEdit from "../Utils/ButtonEdit";

/**
 * Formulario:
 * Precio: price_amount
 * Moneda: currency
 * Descuento semanal: weekly_discount
 * Descuento mensual: monthly_discount
 */

class Prices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coin: this.props.bodyParameters.coin || "ar",
      basePrice: this.props.bodyParameters.basePrice || 0,
      cleaningPrice: this.props.bodyParameters.cleaningPrice || 0,
      weeklyDiscount: this.props.bodyParameters.weeklyDiscount || 0,
      monthlyDiscount: this.props.bodyParameters.monthlyDiscount || 0,
      errors: {},
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate,
      housingId: this.props.housingId,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.handleValidate({ showError: false });
    this.props.priceStep(this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.count_validate !== prevProps.count_validate) {
      this.handleValidate({ showError: true });
    }
  }

  handleValidate = ({ inputName, showError }) => {
    let errors = {};
    const messengers = {
      basePrice: "Debe definir un valor por noche",
    };

    const validate = this.state.basePrice > 0 && this.state.basePrice !== "";

    if (inputName) {
      if (inputName === "basePrice" && this.state.basePrice === 0)
        errors["basePrice"] = messengers["basePrice"];
    } else {
      if (this.state.basePrice === 0)
        errors["basePrice"] = messengers["basePrice"];
    }

    if (showError) this.setState({ errors: errors });
    this.props.validateStep(validate);
  };

  handleBlur = (event) => {
    const nam = event.target.name;
    const val = !isNaN(event.target.value)
      ? parseInt(event.target.value)
      : event.target.value;
    this.setState({ [nam]: val }, () => {
      this.props.priceStep(this.state);
      this.handleValidate({ inputName: nam, showError: true });
    });
  };

  handleChange = (event) => {
    const nam = event.target.name;
    let val = !isNaN(event.target.value)
      ? parseInt(event.target.value)
      : event.target.value;
    if (nam === "weeklyDiscount" || nam === "monthlyDiscount") {
      if (val < 0) val = 0;
      if (val > 99) val = 99;
    }

    this.setState({ [nam]: val }, () => {
      this.props.priceStep(this.state);
      this.handleValidate({ inputName: nam, showError: true });
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="pricesResponsive">
          <div className="Prices">
            {this.state.modeEdit ? (
              ""
            ) : (
              <ProgressBar
                title={"PRECIOS Y DESCUENTOS"}
                stepAt={"Paso 3: Toques finales"}
              />
            )}
            <div className="Prices__container">
              <div className="Prices__container__left">
                <h2 className="Prices__container__title">
                  Ponele un precio a tu espacio
                </h2>
                <p className="Prices__container__descriptionLeft">
                  Establece un valor competitivo según la zona y servicios que
                  tenga tu propiedad.
                </p>
                <div className="Prices__container__inputContainer">
                  <div className="Prices__container__inputContainer__price">
                    <label
                      className="Prices__container__inputContainer__label"
                      htmlFor="price_amount"
                    >
                      Precio por Noche
                    </label>
                    <span className="Prices__container__inputContainer__prefix">
                      $
                    </span>
                    <input
                      id="price_amount"
                      value={this.state.basePrice}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      className="Prices__container__inputContainer__input"
                      name="basePrice"
                      type="number"
                      min="1"
                    />
                  </div>
                  <div className="Prices__container__inputContainer__price">
                    <label
                      htmlFor="currency"
                      className="Prices__container__inputContainer__label"
                    >
                      Moneda
                    </label>
                    <input
                      id="currency"
                      value={"Peso Argentino"}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      className="Prices__container__inputContainer__input"
                      name="coin"
                      type="text"
                      disabled
                    />
                  </div>
                </div>

                <h2 className="Prices__container__title">Precio de Limpieza</h2>
                <p className="Prices__container__description">
                  Aviso: Es un precio fijo y se suma al precio final de la
                  reserva del alohamiento por parte del huésped.
                </p>
                <div className="Prices__container__inputContainer">
                  <div className="Prices__container__inputContainer__price">
                    <label
                      className="Prices__container__inputContainer__label"
                      htmlFor="price_amount_cleaning"
                    >
                      Precio por Limpieza
                    </label>
                    <span className="Prices__container__inputContainer__prefix">
                      $
                    </span>
                    <input
                      id="price_amount_cleaning"
                      value={this.state.cleaningPrice}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      className="Prices__container__inputContainer__input"
                      name="cleaningPrice"
                      type="number"
                      min="1"
                    />
                  </div>
                </div>
                <p className="Prices__container__description__tipRigth">
                  NOTA: Existen Alohadores que prefieren bonificar este servicio
                  como valor agregado a sus huéspedes
                </p>
                {this.state.errors["basePrice"] ? (
                  <ErrorMessage message={this.state.errors["basePrice"]} />
                ) : (
                  ""
                )}
              </div>

              <div className="Prices__container__right">
                <h2 className="Prices__container__title">
                  Precios según la duración de la estadía
                </h2>
                <p className="Prices__container__descriptionRigth">
                  Ofrece un descuento semanal y mensual para motivar a los
                  huéspedes a quedarse mas tiempo.
                </p>

                <div className="Prices__container__inputContainer">
                  <div className="Prices__container__inputContainer__priceRigth">
                    <label
                      className="Prices__container__inputContainer__label"
                      htmlFor="weekly_discount"
                    >
                      Descuento semanal
                    </label>
                    <span className="Prices__container__inputContainer__prefix">
                      %
                    </span>
                    <input
                      value={this.state.weeklyDiscount}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      className="Prices__container__inputContainer__input"
                      mode="discount"
                      id="weekly_discount"
                      name="weeklyDiscount"
                      type="number"
                      min="1"
                      max="99"
                      maxLength="2"
                    />

                    <p className="Prices__container__description__blue">
                      CONSEJO 10%.
                    </p>
                    <p className="Prices__container__description__tip">
                      Los viajeros a menudo buscan por precio. Para aumentar tus
                      posibilidades de recibir reservas para estadías semanales,
                      probá agregar un descuento.
                    </p>
                  </div>
                  <div className="Prices__container__inputContainer__priceRigth">
                    <label
                      className="Prices__container__inputContainer__label"
                      htmlFor="monthly_discount"
                    >
                      Descuento mensual
                    </label>
                    <span className="Prices__container__inputContainer__prefix">
                      %
                    </span>
                    <input
                      value={this.state.monthlyDiscount}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      className="Prices__container__inputContainer__input"
                      mode="discount"
                      id="monthly_discount"
                      name="monthlyDiscount"
                      type="number"
                      min="1"
                      max="99"
                      maxLength="2"
                    />
                    <p className="Prices__container__description__blue">
                      CONSEJO 20%.
                    </p>
                    <p className="Prices__container__description__tip">
                      La mayoría de los viajeros que se quedan más de un mes
                      reservan los alohamientos que ofrecen descuentos
                      superiores al 25%
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start w-full gap-5 ml-auto md:w-max">
              {/* <ButtonPrimary
                onClick={() => {
                  window.location.href = `/pricing/${this.state.housingId}`;
                }}
                width={300}
                title={"Actualizacion de precios automaticos"}
              /> */}
              {this.state.modeEdit ? <ButtonEdit /> : <Buttons />}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(Prices);
