import React from "react";
import useCurrentLanguaje from "../../services/currentlenguaje";
import transferJson from "./tradCheckout.json"

export const TransferInfo = ({
  percentageTransfer,
  userData,
  price,
  locationIp,
}) => {
  const { handleFilterLang } = useCurrentLanguaje();
  
  return (
    <>
      <div className="Checkout__form__inputContainer">
        <h2 className="Checkout__form__inputContainer__title">
          {`¡${userData.firstName
            .charAt(0)
            .toUpperCase()}${userData.firstName.slice(
            1
          )} ${handleFilterLang(transferJson).transInformacion1} 
          $ ${percentageTransfer}`}
        </h2>
        <h3 className="Checkout__form__inputContainer__description">
          <strong>{handleFilterLang(transferJson).importante} </strong> {handleFilterLang(transferJson).transInformacion2} 
        </h3>
        <h3 className="Checkout__form__inputContainer__description">
        {handleFilterLang(transferJson).transInformacion3} 
          
        </h3>

        <p className="Checkout__detail__list__item__desc">
          <strong>
          {handleFilterLang(transferJson).transInformacion4} 
           
          </strong>
        </p>
        <br />
        <br />
        <p className="Checkout__detail__list__item__desc">
        {handleFilterLang(transferJson).importeATransferir} 
         
          {` ${price}`}
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          CBU: 0720212620000000342340
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          Alias: alohar.agencia.ar
        </p>
        <br />
        <p className="Checkout__detail__list__item__desc">
          CUIT: 30-71686126-7
        </p>

        <br />
        <br />
        <p className="Checkout__detail__list__item__desc">
          <strong>
        {handleFilterLang(transferJson).transInformacion5} 

          </strong>
          <br /> <br />
        {handleFilterLang(transferJson).transInformacion6} 
         <br />
          <br />
        {handleFilterLang(transferJson).transInformacion7} 

          <br />
          <br /> 
        {handleFilterLang(transferJson).transInformacion8} 
        
       
          <br />
          <br /> 
        {handleFilterLang(transferJson).transInformacion9} 
        
        
          <br />
          <br /> 
        {handleFilterLang(transferJson).transInformacion10} 
        
        </p>
      </div>
    </>
  );
};
