import React, { useEffect, useState } from "react";
import  { cancelationsEs, cancelationsEn, cancelationsPo  }  from "../Utils/CancelationPolicy";
import queryString from "query-string";
import { loadAbort } from "../../utilities/load-abort-axios";
import useFetchAndLoad from "../../hooks/useFetchAndLoad";
import AxiosAuth from "../../services/requestApi";
import ConfirmedMap from "../Confirmed/Utils/ConfirmedMap";
import { parseMoney } from "../../Helpers";
import moment from "moment";
import { useLanguage } from "../../store/lenguaje/useLenguaje"
moment.locale("es");

const MPConfirmed = () => {

  const { currentLang } = useLanguage();

  const cancelations = currentLang === "es" ? cancelationsEs 
  : currentLang === "en" ? cancelationsEn
  : currentLang === "po" ? cancelationsPo
  : cancelationsEs

  const query = queryString.parse(window.location.search);
  const { external_reference, ourStatus } = query;
  const [reservation, setReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const { callEndpoint } = useFetchAndLoad();
  const controller = loadAbort();

  const getReservation = () => {
    return {
      call: AxiosAuth.get(`/mercadopago/${external_reference}`),
      controller,
    };
  };

  const fetchData = async () => {
    try {
      const response = await callEndpoint(getReservation());
      setReservation(response.data);
      setLoading((prev) => !prev);
    } catch (error) {
      console.error("Fetching data error", error);
    }
  };

  const detailStyle = () => {
    if (reservation && reservation.status === "pending") {
      return {
        height: `70%`,
      };
    } else {
      return {
        height: `auto`,
      };
    }
  };

  const feedbackText = () => {
    if (ourStatus === "success") {
      return (
        <>
          Te vamos a enviar un correo electrónico desde administración para
          confirmar la reserva en las próximas 48hs a{" "}
          <b>{reservation ? reservation.user.email : ""}</b>.
        </>
      );
    }
    if (ourStatus === "pending") {
      return (
        <>
          El pago está <b>pendiente</b>, vamos a estar monitoreando el pago y
          enviar un correo electrónico desde administración para confirmar o no
          la reserva en las próximas 48hs a{" "}
          <b>{reservation ? reservation.user.email : ""}</b>.
        </>
      );
    }
    if (ourStatus === "failure") {
      return (
        <>
          El pago <b>falló</b>, puede ser por varias razones ajenas a nosotros.
          Te recomendamos que intentes una vez más en 30 minutos - 2 horas.
          Muchas gracias!
        </>
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <p>Cargando datos de Mercado Pago...</p>;
  }

  return (
    <div className="Confirmed">
      <div className="Confirmed__container">
        <h2 className="Confirmed__container__title">
          Destino: {reservation ? reservation.housing.location.location : ""}!
        </h2>
        <p className="Confirmed__container__text"></p>
        <p className="Confirmed__container__text">{feedbackText()}</p>
        <div className="Confirmed__container__separator"></div>
        <div className="Confirmed__container__details">
          <div className="Confirmed__container__details__rules">
            <h3 className="Confirmed__container__details__rules__title">
              Detalles
            </h3>
            <ul className="Confirmed__container__details__rules__list">
              <li className="Confirmed__container__details__rules__list__item">
                Check-in: De{" "}
                {reservation
                  ? reservation.housing.startCheckInTime.slice(0, -3)
                  : ""}{" "}
                a{" "}
                {reservation
                  ? reservation.housing.endCheckInTime.slice(0, -3)
                  : ""}
              </li>
              <li className="Confirmed__container__details__rules__list__item">
                Check-out:{" "}
                {reservation
                  ? reservation.housing.checkOutTime.slice(0, -3)
                  : ""}
              </li>
            </ul>
          </div>
          <div className="Confirmed__container__details__policy">
            <h3 className="Confirmed__container__details__policy__title">
              Política de Cancelación
            </h3>
            <p className="Confirmed__container__details__rules__text">
              {reservation ? cancelations[reservation.housing.cancelation] : ""}
              <br />
              <br />
              <i style={{ fontSize: "10px" }}>
                *Check in: Se contemplan horas y días completas antes de la hora
                local de llegada del anuncio (indicada en el correo electrónico
                de confirmación).
              </i>
            </p>
          </div>
        </div>
      </div>
      {ourStatus === "success" && (
        <div className="Confirmed__detail" style={detailStyle()}>
          <h2 className="Confirmed__detail__title">
            {reservation ? reservation.housing.title : ""}
          </h2>
          {reservation.status === "confirmed" ? (
            <>
              <p className="Confirmed__detail__location">
                {reservation ? reservation.housing.adress : ""}{" "}
                {reservation ? reservation.housing.adressExtra : ""},{" "}
                {reservation ? reservation.housing.location.location : ""}
              </p>
              <div className="Confirmed__detail__map">
                <ConfirmedMap
                  lat={reservation ? reservation.housing.lat : ""}
                  lng={reservation ? reservation.housing.lng : ""}
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div className="Confirmed__detail__container">
            <p className="Confirmed__detail__container__dates">
              Checkin:{" "}
              {moment(reservation.checkin).utc(true).format("dddd D MMMM")}
            </p>
            <p className="Confirmed__detail__container__dates">
              Checkout:{" "}
              {moment(reservation.checkout).utc(true).format("dddd D MMMM")}
            </p>
            <p className="Confirmed__detail__container__people">
              Huéspedes: {reservation.guests}
            </p>
          </div>
          <ul className="Confirmed__detail__list">
            <li className="Confirmed__detail__list__item">
              <p className="Confirmed__detail__list__item__desc">
                Tarifa por alojamiento:
              </p>
              <p className="Confirmed__detail__list__item__amount">
                $ {parseMoney(reservation.price)}
              </p>
            </li>
            <li className="Confirmed__detail__list__item">
              <p className="Confirmed__detail__list__item__desc">
                Tarifa de limpieza:
              </p>
              <p className="Confirmed__detail__list__item__amount">
                $ {parseMoney(reservation.cleanPrice)}
              </p>
            </li>
            <li className="Confirmed__detail__list__item">
              <p className="Confirmed__detail__list__item__desc">
                Tarifa por servicio:
              </p>
              <p className="Confirmed__detail__list__item__amount">
                $ {parseMoney(reservation.servicePrice)}
              </p>
            </li>
            <li className="Confirmed__detail__list__item">
              <p className="Confirmed__detail__list__item__desc">Total:</p>
              <p className="Confirmed__detail__list__item__amount">
                $ {parseMoney(reservation.totalPrice)}
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MPConfirmed;
