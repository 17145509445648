export const available_spaceEs = {
  option_1: {
    id: "available_space_1",
    value: 'entire',
    name: "typeHousingSpace",
    title: "Alohamiento Entero",
    description: ["Los huéspedes tienen toda la propiedad para ellos. Normalmente incluye habitación, baño y cocina."],
  },
  option_2: {
    id: "available_space_2",
    value: 'private',
    name: "typeHousingSpace",
    title: "Habitación Privada",
    description: ["Los huéspedes tienen una habitación privada para dormir. Otras áreas se comparten."],
  },
}
export const available_spaceEn = {
  option_1: {
    id: "available_space_1",
    value: "entire",
    name: "typeHousingSpace",
    title: "Entire Accommodation",
    description: ["Guests have the entire property to themselves. Typically includes bedroom, bathroom, and kitchen."]
  },
  option_2: {
    id: "available_space_2",
    value: "private",
    name: "typeHousingSpace",
    title: "Private Room",
    description: ["Guests have a private room for sleeping. Other areas are shared."]
  },
}
export const available_spacePo = {
  option_1: {
    id: "available_space_1",
    value: "entire",
    name: "typeHousingSpace",
    title: "Alojamento Completo",
    description: ["Os hóspedes têm a propriedade inteira para si. Normalmente inclui quarto, banheiro e cozinha."]
  },
  option_2: {
    id: "available_space_2",
    value: "private",
    name: "typeHousingSpace",
    title: "Quarto Privado",
    description: ["Os hóspedes têm um quarto privado para dormir. Outras áreas são compartilhadas."]
  }
}

export const typeHousingPropertyEs = {
  apartment: 'Departamento',
  house: 'Casa',
  cabin: 'Cabaña',
  country_house: 'Casa Quinta',
  anex_house: 'Vivienda Anexa',
  apart_hotel: 'Apart Hotel',
  boutique_hotel: 'Hotel Boutique',
  glamping: 'Glamping'
}
export const typeHousingPropertyEn = {
  apartment: "Apartment",
  house: "House",
  cabin: "Cabin",
  country_house: "Country House",
  anex_house: "Annex House",
  apart_hotel: "Apart Hotel",
  boutique_hotel: "Boutique Hotel",
  glamping: "Glamping"
}
export const typeHousingPropertyPo = {
  apartment: "Apartamento",
  house: "Casa",
  cabin: "Cabana",
  country_house: "Casa de Campo",
  anex_house: "Casa Anexa",
  apart_hotel: "Apart Hotel",
  boutique_hotel: "Hotel Boutique",
  glamping: "Glamping"
}

export const typeHousingServiceEs = [
  {
    value: "basic",
    title: "Elementos Básicos ( Toallas, Sábanas, Jabón, Papel higiénico, etc. )"
  },
  {
    value: "wifi",
    title: "Wifi"
  },
  {
    value: "tv",
    title: "TV"
  },
  {
    value: "heating",
    title: "Calefacción"
  },
  {
    value: "air_conditioning",
    title: "Aire Acondicionado"
  },
  {
    value: "griddle",
    title: "Plancha"
  },
  {
    value: "hair_dryer",
    title: "Secador de Pelo"
  },
  {
    value: "electric_oven",
    title: "Horno Eléctrico"
  },
  {
    value: "washing_machine",
    title: "Lavarropas"
  }

];

export const typeHousingServiceEn = [
  {
    value: "basic",
    title: "Basic Amenities (Towels, Sheets, Soap, Toilet Paper, etc.)"
  },
  {
    value: "wifi",
    title: "Wi-Fi"
  },
  {
    value: "tv",
    title: "TV"
  },
  {
    value: "heating",
    title: "Heating"
  },
  {
    value: "air_conditioning",
    title: "Air Conditioning"
  },
  {
    value: "griddle",
    title: "Griddle"
  },
  {
    value: "hair_dryer",
    title: "Hair Dryer"
  },
  {
    value: "electric_oven",
    title: "Electric Oven"
  },
  {
    value: "washing_machine",
    title: "Washing Machine"
  }
];


export const typeHousingServicePo = [
  {
    value: "basic",
    title: "Comodidades Básicas (Toalhas, Lençóis, Sabonete, Papel Higiênico, etc.)"
  },
  {
    value: "wifi",
    title: "Wi-Fi"
  },
  {
    value: "tv",
    title: "TV"
  },
  {
    value: "heating",
    title: "Aquecimento"
  },
  {
    value: "air_conditioning",
    title: "Ar Condicionado"
  },
  {
    value: "griddle",
    title: "Chapa"
  },
  {
    value: "hair_dryer",
    title: "Secador de Cabelo"
  },
  {
    value: "electric_oven",
    title: "Forno Elétrico"
  },
  {
    value: "washing_machine",
    title: "Máquina de Lavar Roupa"
  }
];


export const typeHousingRoomEs = [
  {
    value:"quincho",
    title:"Quincho"
  },
  {
    value:"laundry",
    title:"Lavadora"
  },
  {
    value:"dryer",
    title:"Secadora"
  },
  {
    value:"cochera",
    title:"Cochera"
  },
  {
    value:"gym",
    title:"Gimnasio"
  },
  {
    value:"pool",
    title:"Pileta"
  },
  {
    value:"jacuzzi",
    title:"Jacuzzi"
  },
];

export const typeHousingRoomEn = [
  {
    value: "quincho",
    title: "Barbecue Area"
  },
  {
    value: "laundry",
    title: "Laundry Room"
  },
  {
    value: "dryer",
    title: "Dryer"
  },
  {
    value: "cochera",
    title: "Garage"
  },
  {
    value: "gym",
    title: "Gym"
  },
  {
    value: "pool",
    title: "Swimming Pool"
  },
  {
    value: "jacuzzi",
    title: "Jacuzzi"
  }
];


export const typeHousingRoomPo = [
  {
    value: "quincho",
    title: "Área de Churrasco"
  },
  {
    value: "laundry",
    title: "Área de Lavanderia"
  },
  {
    value: "dryer",
    title: "Secadora"
  },
  {
    value: "cochera",
    title: "Garagem"
  },
  {
    value: "gym",
    title: "Academia"
  },
  {
    value: "pool",
    title: "Piscina"
  },
  {
    value: "jacuzzi",
    title: "Jacuzzi"
  }
];


export const typeHousingSanitaryEs = [
  {
    value: "thermometer",
    title: "Termómetro"
  },
  {
    value: "alcohol",
    title: "Alcohol en Gel"
  },
  {
    value: "soap",
    title: "Jabon antibacterial"
  },
  {
    value: "deep_cleaning",
    title: "Unidad entregada con limpieza profunda"
  },
  {
    value: "mosquito_repellent",
    title: "Repelentes de mosquitos"
  },
  {
    value: "diffuser_antimosquito",
    title: "Difusor y pastillas anti mosquitos"
  },
];

export const typeHousingSanitaryEn = [
  {
    value: "thermometer",
    title: "Thermometer"
  },
  {
    value: "alcohol",
    title: "Hand Sanitizer"
  },
  {
    value: "soap",
    title: "Antibacterial Soap"
  },
  {
    value: "deep_cleaning",
    title: "Unit delivered with Deep Cleaning"
  },
  {
    value: "mosquito_repellent",
    title: "Mosquito Repellents"
  },
  {
    value: "diffuser_antimosquito",
    title: "Mosquito Diffuser and Tablets"
  }
];


export const typeHousingSanitaryPo = [
  {
    value: "thermometer",
    title: "Termômetro"
  },
  {
    value: "alcohol",
    title: "Álcool em Gel"
  },
  {
    value: "soap",
    title: "Sabão Antibacteriano"
  },
  {
    value: "deep_cleaning",
    title: "Unidade entregue com Limpeza Profunda"
  },
  {
    value: "mosquito_repellent",
    title: "Repelentes de Mosquitos"
  },
  {
    value: "diffuser_antimosquito",
    title: "Difusor e Pastilhas Anti-Mosquitos"
  }
];


export const typeHousingSecurityEs = [
  {
    value: "smoke_detector",
    title: "Detector de humo"
  },
  {
    value: "co_detector",
    title: "Detector de mónoxido de carbono"
  },
  {
    value: "first_aid_kit",
    title: "Botiquín de primeros auxilios"
  },
  {
    value: "fire_extinguisher",
    title: "Extintor de fuego"
  },
  {
    value: "lock_bedroom",
    title: "Cerradura con llave en la puerta del dormitorio"
  }
];

export const typeHousingSecurityEn = [
  {
    value: "smoke_detector",
    title: "Smoke Detector"
  },
  {
    value: "co_detector",
    title: "Carbon Monoxide Detector"
  },
  {
    value: "first_aid_kit",
    title: "First Aid Kit"
  },
  {
    value: "fire_extinguisher",
    title: "Fire Extinguisher"
  },
  {
    value: "lock_bedroom",
    title: "Keyed Bedroom Door Lock"
  }
];


export const typeHousingSecurityPo = [
  {
    value: "smoke_detector",
    title: "Detector de Fumaça"
  },
  {
    value: "co_detector",
    title: "Detector de Monóxido de Carbono"
  },
  {
    value: "first_aid_kit",
    title: "Kit de Primeiros Socorros"
  },
  {
    value: "fire_extinguisher",
    title: "Extintor de Incêndio"
  },
  {
    value: "lock_bedroom",
    title: "Trava com Chave na Porta do Quarto"
  }
];


export const cancellation_policyEs= [
  {
    id: "cancellation_policy_1",
    value: "flex",
    name: "cancellation_policy",
    title: "Flexible",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si comunican su decisión de cancelar con una anticipación no menor a 24 horas antes del Check in*. Caso contrario no habra derecho alguno a reembolso. Una vez operado el ingreso  por parte del huesped y hallándose en uso de los servicios, si este efectuare por su decision abandono de los mismos, carecera de derecho alguno a reembolso por las noches no utilizadas.",
    ],
    checked: true,
  },
  {
    id: "cancellation_policy_2",
    value: "moderate",
    name: "cancellation_policy",
    title: "Moderada",
    description: [
      "Los huéspedes recibirán un reembolso íntegro de la reserva (menos la tarifa de servicio del huésped) si cancelan al menos  7 días antes del Check in*."
    ],
  },
  {
    id: "cancellation_policy_3",
    value: "stric",
    name: "cancellation_policy",
    title: "Estricta",
    description: [
      "Los huéspedes recibirán un reembolso del 50% (menos la tarifa de servicio del huésped) si cancelan al menos  30 días antes del Check in*"
    ],
  },
  {
    id: "cancellation_policy_4",
    value: "heavy",
    name: "cancellation_policy",
    title: "Muy Estricta",
    description: ["Si el huésped cancela la reserva, no se le reembolsará ningún importe abonado."],
  },
]

export const cancellation_policyEn= [
  {
    id: "cancellation_policy_1",
    value: "flex",
    name: "cancellation_policy",
    title: "Flex",
    description: [
      "Guests will receive a full refund of the reservation (minus the guest service fee) if they communicate their decision to cancel at least 24 hours before Check-in*. Otherwise, there will be no right to a refund. Once the guest has checked in and is using the services, if they decide to abandon them, they will have no right to a refund for the nights not used."
    ],
    checked: true
  },
  {
    id: "cancellation_policy_2",
    value: "moderate",
    name: "cancellation_policy",
    title: "Moderate",
    description: [
      "Guests will receive a full refund of the reservation (minus the guest service fee) if they cancel at least 7 days before Check-in*."
    ]
  },
  {
    id: "cancellation_policy_3",
    value: "stric",
    name: "cancellation_policy",
    title: "Strict",
    description: [
      "Guests will receive a 50% refund (minus the guest service fee) if they cancel at least 30 days before Check-in*."
    ]
  },
  {
    id: "cancellation_policy_4",
    value: "heavy",
    name: "cancellation_policy",
    title: "Very Strict",
    description: [
      "If the guest cancels the reservation, no amount paid will be refunded."
    ]
  }
]

export const cancellation_policyPo= [
  {
    id: "cancellation_policy_1",
    value: "flex",
    name: "cancellation_policy",
    title: "Flexível",
    description: [
      "Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se comunicarem sua decisão de cancelamento com pelo menos 24 horas de antecedência ao Check-in*. Caso contrário, não haverá direito a reembolso. Uma vez que o hóspede tenha feito o check-in e esteja utilizando os serviços, se decidir abandoná-los, não terá direito a reembolso pelas noites não utilizadas."
    ],
    checked: true
  },
  {
    id: "cancellation_policy_2",
    value: "moderate",
    name: "cancellation_policy",
    title: "Moderada",
    description: [
      "Os hóspedes receberão um reembolso total da reserva (menos a taxa de serviço do hóspede) se cancelarem pelo menos 7 dias antes do Check-in*."
    ]
  },
  {
    id: "cancellation_policy_3",
    value: "stric",
    name: "cancellation_policy",
    title: "Estrita",
    description: [
      "Os hóspedes receberão um reembolso de 50% (menos a taxa de serviço do hóspede) se cancelarem pelo menos 30 dias antes do Check-in*."
    ]
  },
  {
    id: "cancellation_policy_4",
    value: "heavy",
    name: "cancellation_policy",
    title: "Muito Estrita",
    description: [
      "Se o hóspede cancelar a reserva, nenhum valor pago será reembolsado."
    ]
  }
]


export const rulesEs = [
  {
    title:"Se permite fumar",
    value:"smooke"
  },
  {
    title:"Se admiten mascotas",
    value:"pets"
  },
  {
    title:"Se permiten fiestas o eventos",
    value:"party"
  }
]


export const rulesEn = [
  {
    title: "Smoking allowed",
    value: "smooke"
  },
  {
    title: "Pets allowed",
    value: "pets"
  },
  {
    title: "Parties or events allowed",
    value: "party"
  }
]

 export const rulesPo = [
  {
    title: "Permitido fumar",
    value: "smooke"
  },
  {
    title: "Animais de estimação permitidos",
    value: "pets"
  },
  {
    title: "Festas ou eventos permitidos",
    value: "party"
  }
]

export const typeHousingReservationOptionsEs = [
  {
    value:'to_confirm',
    description: ['Cuando un huésped desea reservar tu espacio te llegará un email de aviso para que confirmes o rechaces la estadía, tu hogar quedara bloqueado esas fechas temporalmente, por eso es importante que rápidamente le des una respuesta al viajero.'],
    title:'No Inmediata (recomendada)'
  },
  {
    value:'immediate',
    description: ['El huésped puede reservar tu espacio directamente (aceptando las normas de tu hogar) sin consultas previas. Esta opción es mas efectiva y atrae mas viajeros por la facilidad de confirmación.'],
    title:'Inmediata'
  }]


  export const typeHousingReservationOptionsEn = [
    {
      value:'to_confirm',
      description: ['When a guest wants to reserve your space, you will receive a notification email to confirm or reject the stay. Your home will be temporarily blocked for those dates, so it is important that you respond quickly to the traveler.'],
      title:'Not Immediate (recommended)'
    },
    {
      value:'immediate',
      description: ['The guest can reserve your space directly (accepting the rules of your home) without prior inquiries. This option is more effective and attracts more travelers due to the ease of confirmation.'],
      title:'Immediate'
    }]
  
export const typeHousingReservationOptionsPo = [
  {
    value:'to_confirm',
    description: ['Quando um hóspede deseja reservar seu espaço, você receberá um e-mail de notificação para confirmar ou rejeitar a estadia. Sua casa será temporariamente bloqueada para essas datas, portanto, é importante que você responda rapidamente ao viajante.'],
    title:'Não Imediato (recomendado)'
  },
  {
    value:'immediate',
    description: ['O hóspede pode reservar seu espaço diretamente (aceitando as regras de sua casa) sem consultas prévias. Esta opção é mais eficaz e atrai mais viajantes devido à facilidade de confirmação.'],
    title:'Imediato'
  }]

