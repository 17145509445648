import React, { useState } from "react";
import ArrowLeft from "../../../assets/components-icons/ArrowLeft";

const LanguageDropdown = ({ languages, currentLang, setCurrentLang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleLanguageChange = (code) => {
    setCurrentLang(code);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center text-gray-700 border rounded-md bg-whiteCream w-14 "
      >
        {languages.map(
          (lang) =>
            lang.code === currentLang && (
              <div
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
                className="flex items-center w-full px-1 py-1 text-sm text-gray-700 cursor-pointer "
              >
                <img
                  src={lang.flag}
                  className="w-4 h-4 mr-2 rounded-full"
                  alt={lang.name}
                />
                 {lang.code === "po" ? "BR" : lang.code.toUpperCase()}
              </div>
            )
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 z-50 mt-2 origin-top-right rounded-md shadow-lg bg-whiteCream w-14 ring-1 ring-black ring-opacity-5">
          <div className="w-full py-1">
            {languages.map((lang) => (
              <div
                key={lang.code}
                onClick={() => {
                  handleLanguageChange(lang.code);
                }}
                className="flex items-center px-1 py-1 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
              >
                <img src={lang.flag} className="w-4 h-3 mr-2" alt={lang.name} />
                {lang.code === "po" ? "BR" : lang.code.toUpperCase()}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
