// Librerías
import React from "react";
// Recursos
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const ConfirmedMap = ({ lat, lng }) => {
  return (
    <MapContainer
      center={[parseFloat(lat), parseFloat(lng)]}
      zoom={10}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>
  );
};

export default ConfirmedMap;
