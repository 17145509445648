// Liberías
import React from 'react'
// Recursos
import '../../assets/styles/LandingPage/_sued.sass';
import WhatsAppButton from '../Buttons/WhatsAppButton';
import '../../assets/styles/Buttons/btn.sass';
import useCurrentLanguaje from "../../services/currentlenguaje";
import tradLandingPage from "./tradLandingPage.json"
const WeDontWannaGetSued = () => {
  const { handleFilterLang } = useCurrentLanguaje();
    return (
      <>
        <div className="WeDontWannaGetSued">
          <div className="WeDontWannaGetSued__container">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdtsLaludr5OKqop018bHWKapy7eZuM2n4aZJFZNFpIspOEsA/viewform" target="_blank" rel="noopener noreferrer" className="WeDontWannaGetSued__container__textContainer__title">
                {handleFilterLang(tradLandingPage).weDontWannaGetSued}
            </a>
            <div className="WppBtnFooter">
              <WhatsAppButton />
            </div>
          </div>
        </div>
      </>
    )
}

export default WeDontWannaGetSued
