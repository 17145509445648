// Librerías
import React from "react";
import { openNotificationModal } from "../../store/modal/actions";
import MaskedInput from "react-maskedinput"; // Mask for Inputs without CSS, great plugin
import Card from "react-credit-cards";
import "react-credit-cards/lib/styles.scss";
import queryString from "query-string";
import AxiosAuth from "../../services/requestApi";
import { BASE_URL, parseMoney } from "../../Helpers";
import ReactNPS from "nps-sdk-react";
import * as moment from "moment";
// Componentes
import Radio from "../AloharForm/Utils/Radio";
import ModalWrapper from "../Modals/ModalWrapper";
import NotificationModal from "../Modals/NotificationModal";
import BankPromotionModal from "../Modals/BankPromotionModal";
import PhoneModal from "../Modals/PhoneModal";
// Recursos
import cards from "../../assets/static/logo_cards_primary.png";
import "../../assets/styles/Checkout/__checkoutmobile.sass";
import ErrorMessage from "../Alerts/ErrorAlert";
import { connect } from "react-redux";
import { openBankPromotionModal } from "../../store/modal/actions";
import loadingGif from "../../assets/static/loading.gif";
import {
  faExclamationCircle,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import previajeLegal from "../../assets/static/previaje.pdf";
import axios from "axios";
import MPButton from "./MPButton/MPButton";
import { TarjetInfo } from "./tarjet-info";
import { TransferInfo } from "./transfer-info";
import ButtonTransfer from "./ButtonTransfer";
import ButtonPayment from "./MPButton/ButtonPayment";
import ButtonGTM from "./button-GTM";
import FirstPhotoRadio from "../AloharForm/Utils/FirstPhotoRadio";
import BannerTransferMobile from "../Utils/BannerTransferMobile";
import { TarjetInfoMobile } from "./tarjet-infoMobile";
import RadioMobile from "../AloharForm/Utils/RadioMobile";
import PaymentForm from "./mobex-payment";
import MobexPayment from "./mobex-payment";
import InputGlobal from "../ui/input-global";
import { TarjetInfoV2 } from "./TarjetInfoV2";

const paymentErrors = {
  INVALID_CARD_HOLDER: {
    input: " Invalid card holder name.",
    output:
      "No se pudo procesar el pago por que el nombre del titular es inválido. Por favor vuelva a ingresarlo. Si el problema persiste póngase en contacto con el equipo de Alohar.",
  },
  INVALID_CARD_NUMBER: {
    input: " Invalid card number.",
    output:
      "No se pudo procesar el pago por que el número de tarjeta es inválido. Por favor vuelva a ingresarlo. Si el problema persiste póngase en contacto con el equipo de Alohar.",
  },
  INVALID_EXPIRATION_DATE: {
    input: " Invalid expiration date.",
    output:
      "No se pudo procesar el pago por que la fecha de expiración es inválido. Por favor vuelva a ingresarlo. Si el problema persiste póngase en contacto con el equipo de Alohar.",
  },
  INVALID_INTENT: {
    input: "The card could not be processed, please try again later",
    output:
      "No se pudo procesar el pago porque nuestro proveedor de pagos está saturado. Por favor inténtelo una vez más tarde. Si el problema persiste póngase en contacto con el equipo de Alohar.",
  },
};

// function mapDispatchToProps(dispatch){
//   return bindActionCreators({ openNotificationModal: openNotificationModal },dispatch)
// }

class CheckoutComponent extends React.Component {
  constructor(props) {
    super(props);

    // cardCVC: '767',
    // cardExpiry: '  ',
    // cardName: 'PABLO FINO',
    // cardNumber: '4111111111111111',
    const query = queryString.parse(window.location.search);
    const {
      token,
      refreshToken,
      lastName = "",
      checkin,
      checkout,
      cleanPrice,
      persons,
      price,
      realPrice,
      servicePrice,
      totalPrice,
      id,
      housingTitle,
      weeklyDiscount,
      monthlyDiscount,
      previaje,
      taxes,
      typeHousingReservation,
      idReceptor,
    } = query;
    console.log("🚀 ~ file: CheckoutComponentMobile.js:99 ~ query:", query);

    // localStorage.setItem("refresh_token", refreshToken);
    // localStorage.setItem("token", token);
    // localStorage.setItem(
    //   "userData",
    //   JSON.stringify({
    //     // token:token,
    //     // refresh_token:refresh_token,
    //     firstName: housingTitle,
    //     lastName: lastName,
    //     login: true,
    //   })
    // );

    this.state = {
      cardCVC: "",
      cardExpiry: "",
      cardFocus: "",
      cardName: "",
      cardNumber: "",
      cardNumberMask: "1111-1111-1111-1111",
      cardCVCMask: "111",
      cardNumberSize: "20",
      installments: "",
      token: "",
      cuitMask: "11-11111111-1",
      cuit: "",
      billingAddress: "",
      /* Datos de la reserva */
      housingTitle: housingTitle,
      currentDate: new Date().toLocaleDateString(),
      checkin: checkin,
      checkout: checkout,
      CleanService: cleanPrice,
      persons: persons,
      idReceptor: idReceptor,
      // Precios
      price: price,
      realPrice: realPrice,
      servicePrice: servicePrice,
      totalPrice: totalPrice,
      totalPriceShow: totalPrice,
      monthlyDiscount: monthlyDiscount,
      weeklyDiscount: weeklyDiscount,
      couponDiscount: 0,
      previaje: previaje,
      taxes: taxes,
      // Housing ID - by the way
      id: id,
      disabled: true,
      errors: {},
      error_request: "",
      typeHousingReservation: typeHousingReservation,
      /* Datos de los planes de pago */
      planPago: 0, // cuotas
      plans: [],
      cardPlan: [],
      defaultPlan: [],
      bin: false,
      servidor: "testing",
      /* Error modal*/
      modalErrorBody: "",
      modalErrorTitle: "",
      loading: false,
      loadingBins: false,
      icon: faExclamationCircle,
      phoneNumber: false,
      openPhoneModal: false,
      cardNumberMaskType: "number",
      location: window.location.href,
      placeholderCVC: "Ej: 123",
      // Coupons
      couponCode: "",
      couponId: 0,
      couponDisplay: "",
      couponMessage: "",
      // Planes Ahora
      planID: null,
      pushPath: false,
      radioValue: "credit",
      radioForeign: false,
      dateNow: null,
      limitPrice: null,
      debitReservation: null,
    };
  }

  sendAndSaveMarketingLog = async (partner, reservation) => {
    const url = BASE_URL + "/housing/marketing/";
    const bodyParameters = {
      action: "convertion",
      partner: partner,
      reservation: reservation,
    };
    AxiosAuth.post(url, bodyParameters).catch(function (error) {});
    localStorage.removeItem("partner");
  };

  sendLog = (request, response, type, status, endpoint) => {
    const url = "payment/log/request";
    const bodyParameters = {
      request: request,
      type: type,
      response: response,
      status: status,
      endpoint: endpoint,
      location: this.state.location,
      parameters: request,
      radioValue: this.state.radioValue,
    };
    AxiosAuth.post(url, bodyParameters).then((data) => {});
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.debitReservation !== this.state.debitReservation) {
      if (
        this.state.checkin === moment(this.state.dateNow).format("DD/MM/YY")
      ) {
        this.setState({
          debitReservation: true,
        });
      }
    }
  }

  componentDidMount = async () => {
    this.getNewPricesWithCoupon();

    AxiosAuth.get(`${BASE_URL}/backoffice/limit-reservation`)
      .then((response) => {
        this.setState({
          dateNow: response.data.dateNow,
          limitPrice: parseInt(response.data.limitPrice),
        });
        const checkinDate = this.state.checkin;
        const dateNow = moment(response.data.dateNow).format("DD/MM/YY");
        if (checkinDate === dateNow) {
          this.setState({
            debitReservation: true,
            typeHousingReservation: false,
            radioValue: "debit",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const urlPhone = BASE_URL + "/user/phone";

    AxiosAuth.get(urlPhone)
      .then((response) => {
        if (response.data.phoneNumber) {
          this.setState({
            phoneNumber: response.data.phoneNumber,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setInterval(() => {
      if (document.getElementById("cardNumber")) {
        if (
          this.state.cardNumber !== document.getElementById("cardNumber").value
        ) {
          const event = {};
          event["target"] = {};
          event["target"]["value"] =
            document.getElementById("cardNumber").value;
          this.handleCardNumberChange(event);
        }
      }
    }, 1000);

    const url = `payment/plans/current`;
    AxiosAuth.get(url)
      .then((response) => {
        this.setState({ plans: response.data });
      })
      .catch((e) => {
        console.log(e);
      });

    const partner = JSON.parse(localStorage.getItem("partner"));
    if (partner) {
      this.setState({ partner: partner });
    }
  };

  preAuthorize = () => {
    this.setState({ loading: true });
    const valid = this.validateInputs("all");

    // this.props.openNotificationModal()
    // valid && this.authorize();
    true && this.authorize();
  };

  enableButton = () => {
    const valid = this.validateInputs("all");
    this.setState({ disabled: !valid });
  };

  createNPSData(merchantId) {
    return new ReactNPS({
      env: "production",
      merchant: merchantId,
      fingerprint: true,
      expDate: "YYMM",
    });
  }

  authorizeTransfer = async () => {
    this.setState({ loading: true });
    const urlCBU = BASE_URL + "/payment/bankAccount";
    await AxiosAuth.get(urlCBU)
      .then((response) => {
        if (response.data) {
          const { cbu, alias, bank, headline, cuit } = response.data;
          this.setState({
            cbu: cbu,
            alias: alias,
            bank: bank,
            headline: headline,
            cuit: cuit,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const urlGuest = BASE_URL + `/user/myProfile`;
    await AxiosAuth.get(urlGuest)
      .then((response) => {
        const {
          phoneNumber,
          birthDay,
          locationStr,
          languageStr,
          about,
          firstName,
          lastName,
          email,
        } = response.data;

        this.setState({
          firstName: firstName,
          lastName: lastName,
          birthDay: birthDay,
          email: email,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });

    const url = BASE_URL + "/reservation/transfer";
    const userData = JSON.parse(localStorage.getItem("userData"));
    const percentageTransfer = Math.round(
      (this.state.totalPriceShow * 2.5) / 100
    );
    const discountTransfer = this.state.totalPriceShow - percentageTransfer;
    const bodyUrl = {
      housingId: this.state.id,
      housingTitle: this.state.housingTitle,
      nameClient: userData.firstName,
      persons: this.state.persons,
      checkin: this.state.checkin,
      checkout: this.state.checkout,
      monthlyDiscount: `- ${this.state.monthlyDiscount}`,
      weeklyDiscount: `- ${this.state.weeklyDiscount}`,
      price: `$ ${Intl.NumberFormat("de-AR").format(this.state.price)}`,
      discount: `$ ${Intl.NumberFormat("de-AR").format(percentageTransfer)}`,
      servicePrice: `$ ${Intl.NumberFormat("de-AR").format(
        this.state.servicePrice
      )}`,
      serviceClean: `$ ${Intl.NumberFormat("de-AR").format(
        this.state.CleanService
      )}`,
      couponDiscount: this.state.couponDiscount,
      total: `$ ${Intl.NumberFormat("de-AR").format(discountTransfer)}`,
      cbu: this.state.cbu,
      cuit: this.state.cuit,
      alias: this.state.alias,
      bank: this.state.bank,
      // detalles del huesped
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      birthDay: this.state.birthDay,
      // el email se usa para saber el id
      email: this.state.email,
    };
    const infoReservation = {
      housingId: this.state.id,
      email: this.state.email,
      housingTitle: this.state.housingTitle,
      nameClient: userData.firstName,
      persons: this.state.persons,
      checkin: this.state.checkin,
      checkout: this.state.checkout,
      monthlyDiscount: `- ${this.state.monthlyDiscount}`,
      weeklyDiscount: `- ${this.state.weeklyDiscount}`,
      price: `$ ${Intl.NumberFormat("de-AR").format(this.state.price)}`,
      discount: `$ ${Intl.NumberFormat("de-AR").format(percentageTransfer)}`,
      servicePrice: `$ ${Intl.NumberFormat("de-AR").format(
        this.state.servicePrice
      )}`,
      serviceClean: `$ ${Intl.NumberFormat("de-AR").format(
        this.state.CleanService
      )}`,
      couponDiscount: this.state.couponDiscount,
      total: `$ ${Intl.NumberFormat("de-AR").format(discountTransfer)}`,
    };
    infoReservation !== undefined &&
      localStorage.setItem("infoReservation", JSON.stringify(infoReservation));
    await axios
      .post(url, bodyUrl)
      .then((resonse) => {
        this.setState({ pushPath: true });
      })
      .catch((err) => console.log(err));
    this.setState({ pushPath: true });
    this.setState({ loading: false });
    localStorage.setItem("typeReservation", "transfer");
  };
  // finaliza la transferencia
  authorize = async (e) => {
    /* Autorizar la tarjeta*/
    const url = BASE_URL + `/payment/session?mid=` + this.state.merchantId;
    fetch(url, {
      method: "GET",
    })
      .then((data) => data.json())
      .then(async (data) => {
        //Session Created, let's create a payment method token
        const sessionId = data.sessionId;
        let params = {
          card: {
            holder_name: this.state.cardName,
            number: this.state.cardNumber,
            exp_month: this.state.cardExpiry.substr(0, 2),
            exp_year: this.state.cardExpiry.substr(3, 3),
            security_code: this.state.cardCVC,
          },
        };

        const axu_params = {
          card: {
            holder_name: this.state.cardName,
            number: this.state.cardNumber.substring(0, 6),
            radioValue: this.state.radioValue,
          },
        };

        const endpoint = url;
        const request = JSON.stringify(axu_params);
        const type = "GET sessionId";
        const status = "sessionId Success";
        const response = JSON.stringify(data);
        this.sendLog(request, response, type, status, endpoint);

        try {
          this.state.ReactNPS.setClientSession(sessionId);
        } catch (e) {
          this.setState({ loading: false });
          this.setState({
            modalErrorBody:
              "El autocompletado de datos de su navegador no cargo correctamente los datos. Por favor ingreselos manualmente.",
            modalErrorTitle: "Error en el proceso de pago",
            icon: faExclamationCircle,
          });
          this.props.openNotificationModal();
        }

        this.state.ReactNPS.createPaymentMethodToken(params)
          .then((e) => {
            this.setState(
              { token: e.result.id, productId: e.result.product },
              () => {
                this.booking();
              }
            );
          })
          .catch(async (error) => {
            const endpoint = url;
            const request = JSON.stringify({
              merchantId: this.state.merchantId,
              radioValue: this.state.radioValue,
            });
            const type = "Error GET createPaymentMethodToken authorize";
            const status = "error";
            const response = JSON.stringify(error);
            this.sendLog(request, response, type, status, endpoint);
            this.setState({ loading: false });

            this.setState({
              modalErrorBody: error.error.message,
              modalErrorTitle: "Error en el proceso de pago",
              icon: faExclamationCircle,
            });
            this.props.openNotificationModal();
          });
      })
      .catch(async (e) => {
        const endpoint = url;
        const request = JSON.stringify({
          merchantId: this.state.merchantId,
          radioValue: this.state.radioValue,
        });
        const type = "Error fetch authorize";
        const status = "error";
        const response = JSON.stringify(e);
        this.sendLog(request, response, type, status, endpoint);

        this.setState({ loading: false });
        this.setState({
          modalErrorBody: JSON.stringify(e),
          modalErrorTitle: "Error en el proceso de pago",
          icon: faExclamationCircle,
        });
        this.props.openNotificationModal();
      });
    if (this.state.radioValue === "debit") {
      localStorage.setItem("typeReservation", "debit");
    }
    if (this.state.radioValue === "credit") {
      localStorage.setItem("typeReservation", "credit");
    }
  };
  // aca termina
  booking = async () => {
    /* Cuando ya se autorizó el token de la tarjeta le paso al servidor el pago*/
    const {
      id,
      checkin,
      checkout,
      persons,
      realPrice,
      token,
      cuit,
      billingAddress,
    } = this.state;
    const url = BASE_URL + `/housing/${id}/reservations`;
    const urlCuit = BASE_URL + `/user/cuit`;
    const _checkin = moment(checkin, "D/M/YY").format("YYYY-MM-DD");
    const _checkout = moment(checkout, "D/M/YY").format("YYYY-MM-DD");
    const planID = this.state.planID;
    const planPago = this.state.planPago;
    const productId = this.state.productId;
    const merchantId = this.state.merchantId;
    const pspPlan = this.state.pspPlan;
    const pspPayments = this.state.pspPayments;
    const couponId = this.state.couponId;
    let bodyParameters = {
      id: id,
      checkin: _checkin,
      checkout: _checkout,
      guests: persons,
      totalPrice: realPrice,
      installments: planPago,
      token: token,
      paymentProductId: productId,
      merchantId: merchantId,
      plan: pspPlan,
      planID: parseInt(planID),
      payments: pspPayments,
      coupon: couponId,
    };
    let bodyCuit = {
      cuit: cuit,
      billingAddress: billingAddress,
    };

    AxiosAuth.post(urlCuit, bodyCuit);

    AxiosAuth.post(url, bodyParameters)
      .then(async (data) => {
        const endpoint = url;
        bodyParameters["radioValue"] = this.state.radioValue;
        const request = JSON.stringify(bodyParameters);
        const type = "Reserva procesada";
        const status = data.data.statusCode;
        const response = JSON.stringify(data.data);
        this.sendLog(request, response, type, status, endpoint);

        //envioOneSignal
        const message = {
          app_id: `${process.env.REACT_APP_ID_APP}`, // colocar en .env
          contents: {
            en: "Notificación", // Agrega contenido en el idioma por defecto
            es: "Tienes una solicitud de reserva", // Agrega contenido en el idioma por defecto
          },
          headings: { es: "Felicitaciones" }, // Agrega aquí el título que deseas mostrar
          //subtitle: { "es": "Tienes una solicitud de reserva"}, // Agrega aquí el subtítulo que deseas mostrar
          channel_for_external_user_ids: "push",
          include_external_user_ids: [this.state.idReceptor],
        };

        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `${process.env.REACT_APP_AUTHORIZATION_APP}`, // colcoar en un .env
        };
        const options = {
          method: "POST",
          url: "https://onesignal.com/api/v1/notifications",
          headers: headers,
          data: message,
        };
        axios(options)
          .then((response) => {})
          .catch((error) => {
            console.log("ERROR:");
            console.log(error);
          });
        //fin oneSignal

        const r = data.data;
        this.setState(
          {
            loading: false,
            modalErrorTitle: "Reserva procesada",
            modalErrorBody:
              "El pago y la reserva fueron procesados éxitosamente, aguarde un instante.",
            icon: faThumbsUp,
          },
          () => {
            this.props.openNotificationModal();

            // Marketing
            if (JSON.parse(localStorage.getItem("partner"))) {
              this.sendAndSaveMarketingLog(
                JSON.parse(localStorage.getItem("partner")),
                r.id
              );
            }

            if (r.status === "pending") {
              /* Reserva pendiente de confirmación*/
              const url = `confirmedMobile/${r.id}?totalPrice=${r.totalPrice}&title=${r.housing.title}&startCheckInTime=${r.startCheckInTime}&endCheckinTime=${r.endCheckinTime}&checkoutTime=${r.checkoutTime}&checkout=${checkout}&checkin=${checkin}&guests=${persons}`;
              setTimeout(function () {
                window.location = url;
              }, 1000);
            }

            if (r.status === "confirmed") {
              /* Reserva pendiente de confirmación*/
              const url = `confirmedMobile/${r.id}?totalPrice=${r.totalPrice}&title=${r.housing.title}&startCheckInTime=${r.startCheckInTime}&endCheckinTime=${r.endCheckinTime}&checkoutTime=${r.checkoutTime}&checkout=${checkout}&checkin=${checkin}&guests=${persons}`;
              //const url = `confirmedMobile/${r.id}?from=checkout`;
              setTimeout(function () {
                window.location = url;
              }, 1000);
            }
          }
        );
      })
      .catch(async (e) => {
        console.log("error proceso", e.response);
        const err = e.response.data;
        const endpoint = url;
        const request = JSON.stringify(bodyParameters);
        const type = "Error reservando";
        const status = err.statusCode;
        const response = JSON.stringify({ e: e, response: e.response.data });
        await this.sendLog(request, response, type, status, endpoint);

        let error = "";

        if (err.statusCode === 400) {
          error =
            "Error: " +
            e.response.data.error.psp_ClResponseMsg +
            ". Error extendido: " +
            e.response.data.error.psp_ResponseExtended +
            " .Codigo de respuesta: " +
            e.response.data.error.psp_ResponseCod;
          if (
            err.message === "Housing is not available for the current dates"
          ) {
            error =
              "El alojamiento no esta diponible para las fechas seleccionadas";
          }
          if (err.message === "checkin or checkout are in the past") {
            error = "Fechas de alojamiento inválidas";
          }
          if (
            err.message ===
            "Price of the reservation differs to previous calculations"
          ) {
            error =
              "El precio de la reserva difere del calculado por el sistema";
          }
          if (err.type === "invalid_request_error") {
            if (err.message_to_purchaser === paymentErrors.INVALID_INTENT.input)
              error = paymentErrors.INVALID_INTENT.output;
            if (
              err.message_to_purchaser ===
              paymentErrors.INVALID_CARD_NUMBER.input
            )
              error = paymentErrors.INVALID_CARD_NUMBER.output;
            if (
              err.message_to_purchaser ===
              paymentErrors.INVALID_EXPIRATION_DATE.input
            )
              error = paymentErrors.INVALID_EXPIRATION_DATE.output;
            if (
              err.message_to_purchaser ===
              paymentErrors.INVALID_CARD_HOLDER.input
            )
              error = paymentErrors.INVALID_CARD_HOLDER.output;
          }
          if (err.message === "There's an error on payment ") {
            // INGENICO
            const psp_ResponseExtended = err.error.psp_ResponseExtended;
            let psp_ResponseMsg = err.error.psp_ResponseMsg;
            const psp_ResponseCod = err.error.psp_ResponseCod;
            switch (psp_ResponseMsg) {
              case "DECLINADA - No Autorizada. Código de seguridad inválido":
                psp_ResponseMsg =
                  "Código de seguridad inválido. Por favor vuelva a ingresarlo";
                error = "Error: " + psp_ResponseMsg;
                break;
              case "RECHAZADA - Solicitud del Comercio Invalida":
                if (
                  err.error.psp_ResponseExtended ===
                  "Error Interno 1011 - Referencia de Transacción duplicada"
                ) {
                  psp_ResponseMsg =
                    "Codigo de Transacción duplicada. Vuelva a intentar realizar el pago. Si persiste el error, por favor pongase en contacto con el equipo de Alohar. Codigo de respuesta: 1011";
                } else {
                  psp_ResponseMsg =
                    "Tarjeta inválida para la operación. Pruebe con una tarjeta de crédito. Si persiste el error, por favor pongase en contacto con el equipo de Alohar. Codigo de respuesta: " +
                    psp_ResponseCod;
                }
                error = "Error: " + psp_ResponseMsg;
                break;
              default:
                error =
                  psp_ResponseMsg +
                  ". " +
                  psp_ResponseExtended +
                  ". Codigo de respuesta: " +
                  psp_ResponseCod;
                break;
            }
          }
        }

        if (err.statusCode === 500) {
          if (e.response.data.message.includes("ER_DUP_ENTRY")) {
            error =
              "Error internos de Alohar, registro duplicado. Por favor pongase en contacto con el equipo de Alohar para solucionarlo.";
          }
        }

        if (
          JSON.stringify(err).includes(
            "Error Interno 1201 - ClientSession invalido"
          )
        ) {
          error =
            "Discúlpanos! No pudimos procesar el pago por un error ajeno a alohar, es posible que haya mucho tráfico. Por favor inténtalo de nuevo más tarde!";
        }

        this.setState(
          {
            modalErrorBody: error,
            modalErrorTitle: "Error en el proceso de reserva",
            icon: faExclamationCircle,
          },
          () => {
            this.props.openNotificationModal();
            this.setState({ loading: false });
          }
        );
      });
  };

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  handleOnBlur = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateInputs(name);
    });
    if (name === "couponCode" && value !== "") {
      this.setState({ couponMessage: "", couponDisplay: "", couponId: 0 });
      this.getCoupon(value);
    } else if (name === "couponCode" && value === "") {
      this.setState({ couponMessage: "", couponDisplay: "", couponId: 0 });
      this.getNewPricesWithCoupon(null);
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.validateInputs(name);
    });
  };

  handleOnChangeForeign = () => {
    this.setState({
      radioForeign: !this.state.radioForeign,
      merchantId: this.state.radioForeign ? "buchu_stdr1" : "buchu_stdr2",
    });
  };

  handleOnChangeCardType = (e) => {
    const { name, value } = e.target;
    this.setState({ radioValue: e.target.name });
    this.setState({ [name]: value });
    this.setState({ planPago: value === "debit" ? 1 : 0 }, () => {
      this.validateInputs(name);
    });
  };

  truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  getCoupon = (code) => {
    const urlCoupons = BASE_URL + `/coupons/${code}`;

    AxiosAuth.get(urlCoupons)
      .then(async (response) => {
        const coupon = response.data;

        const checkin = moment(this.state.checkin, "DD/MM/YY");
        const checkout = moment(this.state.checkout, "DD/MM/YY");

        const nights = checkout.diff(checkin, "days");
        if (coupon.min_nights && coupon.min_nights > nights) {
          return this.setState({
            couponMessage: `Cupón válido para reservas superiores a ${coupon.min_nights} noches`,
            couponDisplay: coupon.display_text,
            couponId: null,
          });
        }
        if (coupon.message) {
          this.setState({
            couponMessage: coupon.message,
          });
        } else {
          this.setState({
            couponMessage: false,
          });
        }
        if (coupon.id) {
          this.setState({
            couponCode: coupon.code,
            couponId: coupon.id,
            couponDisplay: coupon.display_text,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(async (response) => {
        await this.getNewPricesWithCoupon(this.state.couponId);
      });
  };
  getNewPricesWithCoupon = async (id = null) => {
    const urlCost = BASE_URL + `/housing/${this.state.id}/reservations/cost`;
    const bodyParameters = {
      id: id,
      checkin: moment(this.state.checkin, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(this.state.checkout, "D/M/YY").format("YYYY-MM-DD"),
      guests: this.state.persons,
      coupon: this.state.couponId,
    };

    await axios
      .post(urlCost, bodyParameters)
      .then((data) => {
        const prices = data.data.prices;
        prices.forEach((p) => {
          if (p.priceType === "SERVICE_FEE")
            this.setState({ servicePrice: this.truncar(p.total, 2) });
          if (p.priceType === "BASE_PRICE")
            this.setState({ price: this.truncar(p.total, 2) });
          if (p.priceType === "WEEKLY_DISCOUNT")
            this.setState({ weeklyDiscount: this.truncar(p.total, 2) });
          if (p.priceType === "MONTHLY_DISCOUNT")
            this.setState({ monthlyDiscount: this.truncar(p.total, 2) });
          if (p.priceType === "CLEANING_PRICE")
            this.setState({ CleanService: this.truncar(p.total, 2) });
        });
        this.setState({
          previaje: this.truncar(data.data.previajeAmount, 2),
          taxes: this.truncar(data.data.taxes, 2),
          realPrice: data.data.totalPrice,
          totalPrice: this.truncar(data.data.totalPrice, 2),
          totalPriceShow: Math.round(data.data.totalPrice),
          couponDiscount: data.data.couponDiscount,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  validateInputs = (name) => {
    const {
      cardNumber,
      cardName,
      cardExpiry,
      cardCVC,
      radioValue,
      planPago,
      cuit,
      billingAddress,
    } = this.state;
    const _errors = {};
    const _cardNumber = cardNumber.replace(/ /g, "").replace(/_/g, "");
    let validate = true;
    const loadingFalse = () => {
      this.setState({ loading: false });
    };
    if (
      !/^\d+$/.test(_cardNumber) ||
      (_cardNumber.length !== 15 &&
        _cardNumber.length !== 16 &&
        _cardNumber.length !== 17)
    ) {
      validate = false;
      if (name === "cardNumber" || name === "all")
        _errors["cardNumber"] = "Número de tarjeta inválida";
      loadingFalse();
    }

    if (cardName.length < 5) {
      validate = false;
      if (name === "cardName" || name === "all")
        _errors["cardName"] = "Nombre del titular de la tarjeta es inválido";
      loadingFalse();
    }

    if (cardExpiry.length !== 5) {
      validate = false;
      if (name === "cardExpiry" || name === "all")
        _errors["cardExpiry"] = "Fecha de expiración de la tarjeta inválida";
      loadingFalse();
    }

    if (
      this.state.cardCVCMask.length !==
      cardCVC.replace(/ /g, "").replace(/_/g, "").length
    ) {
      validate = false;
      if (name === "cardCVC" || name === "all")
        _errors["cardCVC"] =
          "Error en la cantidad de dígitos del código de verificación.";
      loadingFalse();
    }

    if (planPago < 1) {
      validate = false;
      if (name === "installments" || name === "all")
        _errors["installments"] = "Debe seleccionar un plan de cuotas";
      loadingFalse();
    }

    if (_errors.installments === "Debe seleccionar un plan de cuotas") {
      loadingFalse();
    }
    if (
      radioValue !== "debit" &&
      radioValue !== "credit" &&
      radioValue !== "transfer" &&
      radioValue !== "marketPayment"
    ) {
      validate = false;
      if (name === "radioValue" || name === "all")
        _errors["radioValue"] = "Debe seleccionar un tipo de tarjeta";
      loadingFalse();
    }

    if (cuit === "") {
      validate = false;
      _errors["cuit"] = this.state.radioForeign
        ? "El Documento es obligatorio"
        : "El CUIT es obligatorio";
      loadingFalse();
    }

    if (billingAddress === "") {
      validate = false;
      _errors["billingAddress"] = "La dirección de facturación es obligatoria";
      loadingFalse();
    }

    if (cardName.length < 5) {
      validate = false;
      if (name === "cardName" || name === "all")
        _errors["cardName"] = "Nombre del titular de la tarjeta es inválido";
      loadingFalse();
    }
    // const valid = Object.keys(_errors).length === 0
    this.setState({ errors: _errors });
    this.setState({ disabled: !validate });
    return validate;
  };

  calculoFinanciero = (factor, cuotas) => {
    const { totalPrice } = this.state;
    const financiado = (totalPrice * factor) / cuotas;
    const calc = Math.round(financiado * 100) / 100;
    return calc;
  };

  handleSelectCuotas = (e) => {
    const data = JSON.parse(e.target.value);
    const factor = data.coefficient;
    const totalPrice = factor * this.state.totalPrice;
    this.setState({ totalPriceShow: Math.round(totalPrice * 100) / 100 });
    this.setState({ planPago: data.installments });
    //Para plan Z naranja hay que hacer esta chanchurria
    this.setState({
      pspPlan: data.pspPlan,
      pspPayments: data.pspPayments,
      planID: data.id,
    });
  };

  plansList = () => {
    return this.state.plans.map((plan) => {
      const title =
        plan.supplier === "default" ? "Con todas las tarjetas" : plan.supplier;

      const banks = plan.banks.map((b) => {
        const bankTitle = b.bank === "default" ? "" : b.bank;
        const plans = b.plans.map((p) => {
          const name = bankTitle === "" ? "default" : bankTitle;
          const f = this.calculoFinanciero(p.coefficient, p.installments);
          const descripcion = [
            `${p.installments} cuotas de $ ${f} pesos TNA: ${p.tna}%, CFT: ${p.cft}%`,
          ];
          return (
            <Radio
              key={name + "_" + p.installments}
              id={name + "_" + p.installments}
              value={JSON.stringify(p)}
              name={"cuotas"}
              title={p.planDescription}
              descriptions={descripcion}
              customClickEvent={this.handleSelectCuotas}
              blackText={true}
            />
          );
        });
        return (
          <div className="Checkout__form__title__planConainer__planCuotas__bankTitle">
            {bankTitle}
            {plans}
          </div>
        );
      });

      return (
        <div className="Checkout__form__title__planConainer">
          <div className="Checkout__form__title__planConainer__planCuotas">
            {title}
          </div>
          {banks}
        </div>
      );
    });
  };

  plansListWithBIN = () => {
    const radios = this.state.cardPlan.map((p) => {
      const f = this.calculoFinanciero(p.coefficient, p.installments);
      const descripcion = [
        `${p.installments} cuotas de $ ${f} pesos TNA: ${p.tna}%, CFT: ${p.cft}%`,
      ];
      if (this.state.cardNumber.length === 17 && p.coefficient !== 1)
        return null;
      return (
        <RadioMobile
          id={p.installments}
          value={JSON.stringify(p)}
          name={"cuotas"}
          title={p.planDescription}
          descriptions={[descripcion]}
          customClickEvent={this.handleSelectCuotas}
          blackText={true}
        />
      );
    });
    return (
      <div className="Checkout__form__title__planConainer">
        <div className="Checkout__form__title__planConainer__planCuotas">
          Planes para su tarjeta
        </div>
        <div className="Checkout__form__title__planConainer__planCuotas__bankTitle">
          {radios}
        </div>
      </div>
    );
  };

  binPlans = (bin) => {
    console.log("binPlans", bin);
    const url = `/payment/plans/cards?card=${bin}`;
    this.setState({ loadingBins: true });
    AxiosAuth.get(url)
      .then(async (response) => {
        const _merchantId = response.data.merchantId;
        const __merchantId =
          _merchantId === null ||
          _merchantId === undefined ||
          _merchantId === "null" ||
          _merchantId === "undefined"
            ? "buchu"
            : _merchantId;
        this.setState({
          cardPlan: response.data.plans,
          merchantId: this.state.radioForeign ? "buchu_stdr2" : __merchantId,
          ReactNPS: this.createNPSData(__merchantId),
        });

        const endpoint = url;
        const request = JSON.stringify({
          bin: bin,
          radioValue: this.state.radioValue,
        });
        const type = "Success GET binPlans";
        const status = "merchantId Success";
        const _response = JSON.stringify(response);
        this.sendLog(request, _response, type, status, endpoint);
        this.setState({ loadingBins: false });
      })
      .catch(async (e) => {
        console.log(e);
        const err = e.response.data;
        const endpoint = url;
        const request = JSON.stringify({
          bin: bin,
          radioValue: this.state.radioValue,
        });
        const type = "Error GET binPlans";
        const status = "merchantId Error: " + err.statusCode;
        const response = JSON.stringify({ e: e, response: err });
        this.sendLog(request, response, type, status, endpoint);
        this.setState({ loadingBins: false });
      });
  };
  validPhone = () => {
    this.setState({ loading: true });
    //  if (this.state.phoneNumber && this.state.phoneNumber.length >= 8) {
    if (true) {
      this.preAuthorize();
      this.setState({ openPhoneModal: false });
    } else {
      this.setState({ openPhoneModal: true });
    }
  };

  changeCardNumber = (event) => {
    let value = event.target.value;
    const cardNumber = value
      .replace(/-/g, "")
      .replace(/_/g, "")
      .replace(/ /g, "");
    const bin = cardNumber.substring(0, 6);
    const card = cardNumber.substring(0, 2);
    let newState = {
      cardNumberMask: "1111 1111 1111 1111",
      cardCVCMask: "111",
      cardNumberSize: "20",
      cardNumber: value.replace(/-/g, ""),
      placeholderCVC: "Ej: 123",
    };

    if (card === "34" || card === "37") {
      newState.cardNumberMask = "1111 111111 11111";
      newState.cardCVCMask = "1111";
      newState.cardNumberSize = "15";
      newState.placeholderCVC = "Ej: 1234";
    }
    this.setState(newState);

    if (bin.length === 6 && this.state.bin !== bin) {
      this.setState({ bin: bin });
      this.binPlans(bin);
    }

    if (bin.length < 6 && this.state.cardPlan.length > 0) {
      this.setState({ cardPlan: [] });
      this.setState({ bin: false });
    }
  };

  handleCardNumberChange = (event) => {
    if (
      this.state.radioValue !== "debit" &&
      this.state.radioValue !== "credit"
    ) {
      let e = this.state.errors;
      e["radioValue"] =
        "Debe seleccionar un tipo de tarjeta antes de ingresar el número";
      this.setState({ errors: e, cardNumberMaskType: "number" });
    } else {
      if (this.state.cardNumberMaskType !== "tel")
        this.setState({ cardNumberMaskType: "tel" }, () => {});
      this.changeCardNumber(event);
    }
  };

  handleSavePhone = (phoneNumber) => {
    this.setState({ phoneNumber: phoneNumber });
    const phoneUrl = "/user/phone";
    const params = {
      prefixPhoneNumber: "+54",
      phoneNumber: phoneNumber,
    };
    AxiosAuth.post(phoneUrl, params)
      .then((response) => {
        if (response.status === 201) this.preAuthorize();
        this.setState({ openPhoneModal: false });
      })
      .catch((e) => {
        console.log("Error guardando telefono", e);
        this.setState({ openPhoneModal: false });
      });
  };

  handleClosePhoneModal = () => {
    this.setState({ openPhoneModal: false });
  };

  isSameDayReservation = () => {
    const today = new Date().toLocaleDateString();
    return today === this.state.reservationDate;
  };

  render() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const percentageTransfer = Math.round(
      (this.state.totalPriceShow * 2.5) / 100
    );
    const discountTransfer = this.state.totalPriceShow - percentageTransfer;
    const urlWhatsApp = "https://wa.me/5492233018749/?text=";

    return (
      <>
        <BannerTransferMobile />
        <div className="CheckoutContainer">
          <div className="Checkout">
            <div className="Checkout__container">
              <h2 className="Checkout__title">
                {/* Confirmar y pagar reserva de <b>{this.state.housingTitle}</b> */}
              </h2>
              <div className="Checkout__form">
                <div className="Checkout__form__cardsContainer">
                  <img
                    className="Checkout__form__cards"
                    src={cards}
                    alt="Tarjetas"
                  />
                </div>
                <div className="Checkout__form__inputContainer">
                  <label
                    htmlFor="card_type"
                    className="Checkout__form__inputContainer__label"
                  >
                    Método de pago
                  </label>
                  {(this.state.typeHousingReservation === "immediate" ||
                    // moment().format("DD/MM/YY") ===
                    //   moment(this.state.checkin, "DD/MM/YY").format(
                    //     "DD/MM/YY"
                    //   )
                    this.state.debitReservation) && (
                    <RadioMobile
                      key={"radioValue"}
                      id={"debit"}
                      value={"debit"}
                      name={"debit"}
                      title={"Débito"}
                      customClickEvent={this.handleOnChangeCardType}
                      blackText={true}
                      checked={this.state.radioValue === "debit"}
                    />
                  )}
                  <RadioMobile
                    key={"radioValue"}
                    id={"credit"}
                    value={"credit"}
                    name={"credit"}
                    disabled={this.state.debitReservation}
                    title={"Crédito"}
                    customClickEvent={this.handleOnChangeCardType}
                    blackText={true}
                    checked={this.state.radioValue === "credit"}
                  />
                  {/* <p>{moment().format("DD/MM/YY")}</p>
                  <p>{moment(this.state.checkin, "DD/MM/YY").format("DD/MM/YY")}</p>
                  <p>{this.state.typeHousingReservation}</p> */}
                  {/* <Radio
                    key={"radioValue"}
                    id={"transfer"}
                    value={"transfer"}
                    name={"transfer"}
                    title={"Transferencia bancaria"}
                    customClickEvent={this.handleOnChangeCardType}
                    blackText={true}
                    checked={this.state.radioValue === "transfer"}
                  /> */}
                  {this.state.errors["radioValue"] ? (
                    <ErrorMessage message={this.state.errors["radioValue"]} />
                  ) : (
                    ""
                  )}
                </div>

                {this.state.radioValue === "credit" && <TarjetInfoV2 />}

                {this.state.radioValue === "transfer" && (
                  <TransferInfo
                    price={discountTransfer}
                    percentageTransfer={percentageTransfer}
                    userData={userData}
                  />
                )}

                {/* {this.state.radioValue === "credit" ? (
                  <>
                    <TarjetInfoMobile
                      handleOnChangeForeign={this.handleOnChangeForeign}
                      radioForeign={this.state.radioForeign}
                      errors={this.state.errors}
                      cardNumberSize={this.state.cardNumberSize}
                      cardNumberMask={this.state.cardNumberMask}
                      handleCardNumberChange={this.handleCardNumberChange}
                      handleInputFocus={this.handleInputFocus}
                      handleInputChange={this.handleInputChange}
                      handleOnBlur={this.handleOnBlur}
                      cardCVCMask={this.state.cardCVCMask}
                      placeholderCVC={this.state.placeholderCVC}
                      cuitMask={this.state.cuitMask}
                      couponMessage={this.state.couponMessage}
                    />
                    <p id="#plans" className="Checkout__form__title">
                      Planes de pagos
                    </p>
                    {this.state.errors["installments"] ? (
                      <ErrorMessage
                        message={this.state.errors["installments"]}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.loadingBins ? (
                      <img
                        alt="Cargando..."
                        className="Checkout__loading"
                        src={loadingGif}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.plans
                      ? this.state.cardPlan.length > 0
                        ? this.plansListWithBIN()
                        : this.plansList()
                      : ""}
                  </>
                ) : (
                  ""
                )} */}
                {/* {this.state.radioValue === "debit" && (
                  <>
                    <TarjetInfoMobile
                      handleOnChangeForeign={this.handleOnChangeForeign}
                      radioForeign={this.state.radioForeign}
                      errors={this.state.errors}
                      cardNumberSize={this.state.cardNumberSize}
                      cardNumberMask={this.state.cardNumberMask}
                      handleCardNumberChange={this.handleCardNumberChange}
                      handleInputFocus={this.handleInputFocus}
                      handleInputChange={this.handleInputChange}
                      handleOnBlur={this.handleOnBlur}
                      cardCVCMask={this.state.cardCVCMask}
                      placeholderCVC={this.state.placeholderCVC}
                      cuitMask={this.state.cuitMask}
                      couponMessage={this.state.couponMessage}
                    />
                  </>
                )} */}
              </div>

              <div className="Checkout__detail">
                <div className="Checkout__detail__data">
                  <div className="Checkout__detail__data__item">
                    <p className="Checkout__detail__data__item__text">
                      Cantidad de Huéspedes:
                    </p>
                    <p className="Checkout__detail__data__item__text">
                      {this.state.persons === 1
                        ? `${this.state.persons} persona`
                        : `${this.state.persons} personas`}
                    </p>
                  </div>
                  <div className="Checkout__detail__data__item">
                    <p className="Checkout__detail__data__item__text">
                      Checkin:
                    </p>
                    <p className="Checkout__detail__data__item__text">
                      {this.state.checkin}
                    </p>
                  </div>
                  <div className="Checkout__detail__data__item">
                    <p className="Checkout__detail__data__item__text">
                      Checkout:
                    </p>
                    <p className="Checkout__detail__data__item__text">
                      {this.state.checkout}
                    </p>
                  </div>
                </div>
                <ul className="Checkout__detail__list">
                  <li className="Checkout__detail__list__item">
                    <p className="Checkout__detail__list__item__desc">
                      Alohamiento
                    </p>
                    <p className="Checkout__detail__list__item__amount">
                      {`$ ${Intl.NumberFormat("de-AR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(this.state.price).toFixed(2))}
                      `}
                    </p>
                  </li>
                  <li className="Checkout__detail__list__item">
                    <p className="Checkout__detail__list__item__desc">
                      Tarifa de limpieza
                    </p>
                    <p className="Checkout__detail__list__item__amount">
                      {`$ ${this.state.CleanService}`}
                    </p>
                  </li>
                  <li className="Checkout__detail__list__item">
                    <p className="Checkout__detail__list__item__desc">
                      Tarifa por servicio
                    </p>
                    <p className="Checkout__detail__list__item__amount">
                      {`$ ${Intl.NumberFormat("de-AR", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(this.state.servicePrice).toFixed(2))}
                      `}
                    </p>
                  </li>

                  {this.state.radioValue === "transfer" && (
                    <li className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Descuento 2.5%
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        {`$ ${Math.round(percentageTransfer)}`}
                      </p>
                    </li>
                  )}

                  {this.state.taxes > 0 ? (
                    <li className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Impuestos, tasas y cargos
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        {`$ ${this.state.taxes}`}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}

                  {this.state.weeklyDiscount > 0 ? (
                    <li className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Descuento semanal
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        {`$ -${this.state.weeklyDiscount}`}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}
                  {this.state.monthlyDiscount > 0 ? (
                    <li className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Descuento mensual
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        {`$ -${this.state.monthlyDiscount}`}
                      </p>
                    </li>
                  ) : (
                    ""
                  )}

                  {this.state.couponDisplay !== "" && (
                    <li className="Checkout__detail__list__item featured">
                      <p className="Checkout__detail__list__item__desc">{`Cupón "${this.state.couponCode}" | ${this.state.couponDisplay}`}</p>
                      <p className="Checkout__detail__list__item__amount">
                        -{` $${this.state.couponDiscount}`}
                      </p>
                    </li>
                  )}
                  <li className="Checkout__detail__list__item">
                    <p className="Checkout__detail__list__item__desc">Total</p>
                    <p className="Checkout__detail__list__item__amount">
                      {this.state.radioValue === "transfer" && (
                        <>
                          {this.state.locationIp !== "AR" ? (
                            <>
                              <br />
                              <strong>
                                {`U$D ${Intl.NumberFormat("de-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                }).format(
                                  parseFloat(
                                    this.state.totalPriceShow /
                                      this.state.dolarPrice
                                  ).toFixed(2)
                                )}`}
                              </strong>
                            </>
                          ) : (
                            `$ ${Intl.NumberFormat("de-AR", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            }).format(
                              parseFloat(Math.floor(discountTransfer)).toFixed(
                                2
                              )
                            )}`
                          )}
                        </>
                      )}
                      {this.state.radioValue === "debit" && (
                        <>
                          {`$ ${this.state.totalPriceShow}`}
                          {this.state.locationIp !== "AR" && (
                            <>
                              <br />
                              <strong>
                                {`U$D ${Intl.NumberFormat("de-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                }).format(
                                  parseFloat(
                                    this.state.totalPriceShow /
                                      this.state.dolarPrice
                                  ).toFixed(2)
                                )}`}
                              </strong>
                            </>
                          )}
                        </>
                      )}
                      {this.state.radioValue === "credit" && (
                        <>
                          {`$ ${this.state.totalPriceShow}`}
                          {this.state.radioForeign && (
                            <>
                              <br />
                              <strong>
                                {`U$D ${Intl.NumberFormat("de-US", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 2,
                                }).format(
                                  parseFloat(
                                    this.state.totalPriceShow /
                                      this.state.dolarPrice
                                  ).toFixed(2)
                                )}`}
                              </strong>
                            </>
                          )}
                        </>
                      )}
                    </p>
                  </li>
                  <li className="flex flex-col Checkout__detail__list__item">
                    <InputGlobal
                      prefix={"Cupón de descuento"}
                      className={"my-6"}
                      onChange={(e) => this.getCoupon(e.target.value)}
                      name={"couponDiscount"}
                      type={"text"}
                    />
                    {this.state.couponMessage && (
                      <ErrorMessage message={this.state.couponMessage} />
                    )}
                  </li>
                </ul>
                {this.state.previaje > 0 && (
                  <div className="Checkout__detail__list">
                    <div className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Crédito PREVIAJE:
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        ${parseMoney(this.state.previaje)}
                      </p>
                    </div>
                    <div className="Checkout__detail__list__item">
                      <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                        * Por favor leer los
                        <a
                          href={previajeLegal}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          términos, bases y condiciones
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                )}

                {this.state.cardType === "marketPayment" && (
                  <MPButton state={this.state} />
                )}
                {/* <div className="Checkout__detail__card">
                  <Card
                    cvc={this.state.cardCVC}
                    expiry={this.state.cardExpiry}
                    focus={this.state.cardFocus}
                    name={this.state.cardName}
                    number={this.state.cardNumber}
                    locale={{ valid: "Válido hasta" }}
                    placeholders={{ name: "Tu nombre aquí" }}
                  />
                </div> */}
                {this.state.previaje > 0 && (
                  <div className="Checkout__detail__list">
                    <div className="Checkout__detail__list__item">
                      <p className="Checkout__detail__list__item__desc">
                        Crédito PREVIAJE:
                      </p>
                      <p className="Checkout__detail__list__item__amount">
                        ${parseMoney(this.state.previaje)}
                      </p>
                    </div>
                    <div className="Checkout__detail__list__item">
                      <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                        * Por favor leer los
                        <a
                          href={previajeLegal}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          términos, bases y condiciones
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                )}

                {this.state.cardType === "marketPayment" && (
                  <MPButton state={this.state} />
                )}

                {/* {this.state.cardType === "" && <MPButton state={this.state} />} */}
                {/* {this.state.radioValue === "credit" && (
                  <ButtonPayment
                    loading={this.state.loading}
                    validPhone={() => this.validPhone()}
                  />
                )} */}
                {/* aca */}
                {/* {this.state.radioValue === "debit" && (
                  <ButtonPayment
                    loading={this.state.loading}
                    validPhone={() => this.validPhone()}
                  />
                )} */}
                <MobexPayment
                  totalPrice={this.state.realPrice}
                  bodyParams={{
                    checkin: this.state.checkin,
                    checkout: this.state.checkout,
                    guests: this.state.persons,
                    housingId: this.state.id,
                    reservationInmediate: this.state.typeHousingReservation,
                    typeCard: this.state.radioValue,
                    couponId: this.state.couponId,
                  }}
                />

                {this.state.radioValue === "transfer" && (
                  <ButtonTransfer
                    loading={this.state.loading}
                    urlWhatsApp={urlWhatsApp}
                    id={this.state.id}
                    housingTitle={this.state.housingTitle}
                    persons={this.state.persons}
                    checkin={this.state.checkin}
                    checkout={this.state.checkout}
                    price={this.state.price}
                    percentageTransfer={percentageTransfer}
                    CleanService={this.state.CleanService}
                    servicePrice={this.state.servicePrice}
                    authorizeTransfer={this.authorizeTransfer}
                    firstName={userData.firstName}
                    discountTransfer={discountTransfer}
                  />
                )}

                <button
                  className="Checkout__detail__link"
                  onClick={() => {
                    this.props.openBankPromotionModal();
                  }}
                >
                  <p>Promociones de pago</p>
                </button>
              </div>
            </div>

            {this.props.isOpen && this.props.type === "NOTIFICATION" ? (
              <ModalWrapper>
                <NotificationModal
                  title={this.state.modalErrorTitle}
                  text={this.state.modalErrorBody}
                  buttonText={"Aceptar"}
                  icon={this.state.icon}
                  showWhatsApp={true}
                />
              </ModalWrapper>
            ) : (
              ""
            )}

            {this.state.openPhoneModal ? (
              <ModalWrapper>
                <PhoneModal
                  handleSavePhone={this.handleSavePhone}
                  handleClosePhoneModal={this.handleClosePhoneModal}
                />
              </ModalWrapper>
            ) : (
              ""
            )}

            {this.props.isOpen && this.props.type === "BANK_PROMOTION" ? (
              <ModalWrapper>
                <BankPromotionModal promotions={this.state.plans} />
              </ModalWrapper>
            ) : (
              ""
            )}
          </div>
        </div>
        <ButtonGTM />
      </>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.modalReducer;
};

export default connect(mapStateToProps, {
  openBankPromotionModal,
  openNotificationModal,
})(CheckoutComponent);
