import React from "react";
import CasaError3 from "../../assets/static/Icons/CasaError3.svg";

const HouseAlertIcon = ({ size }) => {
  return (
    <img
      style={{ width: size ? size : "100%", height: size ? size : "100%" }}
      src={CasaError3}
      alt={"error"}
    />
  );
};

export default HouseAlertIcon;
