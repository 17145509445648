export const paymentErrorsEs = {
    INVALID_CARD_HOLDER: {
      input: " Invalid card holder name.",
      output:
        "No se pudo procesar el pago por que el nombre del titular es inválido. Por favor vuelva a ingresarlo. Si el problema persiste póngase en contacto con el equipo de Alohar.",
    },
    INVALID_CARD_NUMBER: {
      input: " Invalid card number.",
      output:
        "No se pudo procesar el pago por que el número de tarjeta es inválido. Por favor vuelva a ingresarlo. Si el problema persiste póngase en contacto con el equipo de Alohar.",
    },
    INVALID_EXPIRATION_DATE: {
      input: " Invalid expiration date.",
      output:
        "No se pudo procesar el pago por que la fecha de expiración es inválida. Por favor vuelva a ingresarla. Si el problema persiste póngase en contacto con el equipo de Alohar.",
    },
    INVALID_INTENT: {
      input: "The card could not be processed, please try again later",
      output:
        "No se pudo procesar el pago porque nuestro proveedor de pagos está saturado. Por favor inténtelo una vez más tarde. Si el problema persiste póngase en contacto con el equipo de Alohar.",
    },
  };


  export const paymentErrorsEn = {
    INVALID_CARD_HOLDER: {
      input: " Invalid card holder name.",
      output:
      "The payment could not be processed because the owner's name is invalid. Please re-enter it. If the problem persists, contact the Alohar team.",
    },
    INVALID_CARD_NUMBER: {
      input: " Invalid card number.",
      output:
      "The payment could not be processed because the card number is invalid. Please re-enter it. If the problem persists, contact the Alohar team.",
    },
    INVALID_EXPIRATION_DATE: {
      input: " Invalid expiration date.",
      output:
      "The payment could not be processed because the expiration date is invalid. Please re-enter it. If the problem persists, contact the Alohar team.",
     },
    INVALID_INTENT: {
      input: "The card could not be processed, please try again later",
      output:
      "The payment could not be processed because our payment provider is overwhelmed. Please try again later. If the problem persists contact the Alohar team.",
    },
  };

  export const paymentErrorsPo = {
    INVALID_CARD_HOLDER: {
      input: " Invalid card holder name.",
      output:
      "O pagamento não pôde ser processado porque o nome do proprietário é inválido. Por favor, digite-o novamente. Se o problema persistir, entre em contato com a equipe Alohar.",
    },
    INVALID_CARD_NUMBER: {
      input: " Invalid card number.",
      output:
      "O pagamento não pôde ser processado porque o número do cartão é inválido. Digite-o novamente. Se o problema persistir, entre em contato com a equipe Alohar.",
    },
    INVALID_EXPIRATION_DATE: {
      input: " Invalid expiration date.",
      output:
      "O pagamento não pôde ser processado porque a data de validade é inválida. Por favor, insira-a novamente. Se o problema persistir, entre em contato com a equipe Alohar.",
    },
    INVALID_INTENT: {
      input: "The card could not be processed, please try again later",
      output:
      "O pagamento não pôde ser processado porque nosso provedor de pagamento está sobrecarregado. Tente novamente mais tarde. Se o problema persistir, entre em contato com a equipe Alohar.",
    },
  };
