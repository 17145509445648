import React from "react";
import close from "../../assets/static/Icons/close.svg";
import hand from "../../assets/static/hand like.svg";
import arrowLeft from "../../assets/static/Icons/arrow-left.svg";
import { Link } from "react-router-dom";
import "../../assets/styles/Modals/finishedModal.sass";

const FinishedModal = ({
  title,
  link,
  buttonTitle,
  setCloseModal,
  closeModal,
  onClick,
}) => {
  
  return (
    <>
      <div className={closeModal ? "modalActive" : "modalNone"}>
        <div className="modalActive__modal">
          <div className="modalActive__modal__container">
            <button
              className="modalActive__modal__container__close"
              onClick={setCloseModal}
            >
              <img
                alt="icon"
                className="modalActive__modal__container__close__img"
                src={close}
              />
            </button>
            <img
              alt="icon"
              className="modalActive__modal__container__close__hand"
              src={hand}
            />
            <h2 className="modalActive__modal__container__title">
              ¡Excelente!
            </h2>
            {/* <p className='modalActive__modal__container__description'>Publicaste tu Alohamiento y estas listo para recibir tus próximos huéspedes.</p> */}
            <p className="modalActive__modal__container__description">
              {title}
            </p>
            {onClick ? (
              <button className="Buttons__btn-primary" onClick={onClick()}>
                {buttonTitle}
              </button>
            ) : (
              <Link className="modalActive__modal__container__link" to={link}>
                <img
                  alt="icon"
                  className="modalActive__modal__container__link__icon"
                  src={arrowLeft}
                />
                {buttonTitle}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinishedModal;
