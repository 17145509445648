import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { BASE_URL } from "../../Helpers";
import AxiosAuth from "../../services/requestApi";
import MessaggesMobile from "./containers/messagesMobile";
import MessaggesDesktop from "./containers/messagesDesktop";
import LoadingGift from "../../assets/components-icons/LoadingGift";
import "./messengers-page.sass";
import { useDataContext } from "../../store/data-context";

const LayoutMessages = ({ userId, userData }) => {
  const [openDetails, setOpenDetails] = useState(false);
  const [chatMobile, setChatMobile] = useState(false);
  const [housingMobile, setHousingMobile] = useState(false);
  const [usersMobile, setUsersMobile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const { updateData } = useDataContext();

  useEffect(() => {
    updateData({ userDataBackOffice: userData });
  }, [userId]);
  

  const getMessages = async () => {
    if (userId) {
      const url = `${BASE_URL}/user/messages/sendersBackOffice/${userId}`;
      await AxiosAuth.get(url)
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    } else {
      await AxiosAuth.get(`${BASE_URL}/v2/user/messages/senders`)
        .then((response) => {
          setUsers(response.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    if (usersMobile) {
      getMessages();
    }

    if (!isMobile) {
      setChatMobile(true);
      setHousingMobile(true);
      setUsersMobile(true);
    }
  }, [chatMobile, usersMobile, housingMobile, openDetails]);

  const refClickUser = useRef(null);

  useEffect(() => {
    if (refClickUser.current) {
      refClickUser.current.focus();
    }
  }, []);
  useEffect(() => {
    setOpenDetails(false);
  }, []);

  return (
    <>
      {isMobile ? (
        <MessaggesMobile users={users} />
      ) : loading ? (
        <div className="layoutMessages__loading">
          <LoadingGift size={250} />
          <h2 className="layoutMessages__loading__title">
            Cargando mensajes...
          </h2>
        </div>
      ) : (
        <MessaggesDesktop users={users} userId={userId} />
      )}
    </>
  );
};

export default LayoutMessages;
