// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../store/aloharForm/actions";
import AxiosAuth from "../../services/requestApi";
// Componentes
import Title from "./Steps/Title";
import Type from "./Steps/Type";
import Quantities from "./Steps/Quantities";
import Location from "./Steps/Location";
import Services from "./Steps/Services";
import Covid from "./Steps/Covid";
import Photos from "./Steps/Photos";
import Rules from "./Steps/Rules";
import Guests from "./Steps/Guests";
import Prices from "./Steps/Prices";
import Calendar from "./Steps/Calendar";
import Payment from "./Steps/Payment";
import Legal from "./Steps/Legal";

class AloharFormEdit extends Component {
  constructor(props) {
    super(props);

    if (
      parseInt(this.props.editStep) > 15 ||
      parseInt(this.props.editStep) < 3
    ) {
      window.location.href = "/";
    }

    this.props.setStep({ step: this.props.editStep });
    this.state = {
      step: parseInt(this.props.editStep),
      id: parseInt(this.props.id),
      loading: false,
      bodyParameters: {},
      currentLang: this.props.currentLang || "es",
    };
  }

  componentWillUnmount = () => {
    // localStorage.removeItem('housingPublicProcessData')
  };

  componentDidMount = async () => {
    const step = this.state.step;
    const id = this.state.id;
    const url = `housing/public/${step - 2}/${id}`;

    this.props.housingIdSetup({ id: id });

    await AxiosAuth.get(url)
      .then((response) => {
        let bodyParameters = response.data;

        if (this.state.step === 15) {
          const newBodyParameters = bodyParameters.calendar.map((calendar) => {
            /*
                Fix date to plugin
              */
            const fixEndDate = new Date(calendar.end);
            fixEndDate.setDate(fixEndDate.getDate() + 2);
            let dd = fixEndDate.getDate();
            let mm = fixEndDate.getMonth() + 1;
            const yyyy = fixEndDate.getFullYear();

            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;

            return {
              title:
                calendar.typeHousingCalendar === "calendarImport"
                  ? "Calendario importado"
                  : calendar.typeHousingCalendar === "block"
                  ? "Bloqueado"
                  : calendar.typeHousingCalendar === "season"
                  ? `Temporada: "${calendar.description} $${
                      (calendar.amount * calendar.percentage) / 100 +
                      calendar.amount
                    }"`
                  : calendar.typeHousingCalendar === "hold"
                  ? "Reserva registrada"
                  : calendar.typeHousingCalendar === "hot_sale"
                  ? "Promo"
                  : `Disponible ($ ${calendar.price})`,
              start: calendar.start,
              end: `${yyyy}-${mm}-${dd}`,
              allDay: true,
              isBlocked:
                calendar.typeHousingCalendar === "block" ||
                calendar.typeHousingCalendar === "hold",
              isAvailable:
                calendar.typeHousingCalendar !== "block" &&
                calendar.typeHousingCalendar !== "hold",
              amount: calendar.price,
              backgroundColor:
                calendar.typeHousingCalendar === "calendarImport"
                  ? "rgba(255, 182, 193, .6)"
                  : calendar.typeHousingCalendar === "season"
                  ? "rgba(255, 165, 0, .6)"
                  : calendar.typeHousingCalendar === "block"
                  ? "rgba(255, 0, 0, .6)"
                  : calendar.typeHousingCalendar === "hold"
                  ? "rgba(0, 0, 255, .6)"
                  : calendar.typeHousingCalendar === "hot_sale"
                  ? "rgba(255, 78, 0, .6)"
                  : "rgba(0, 128, 0, .6)",
              borderColor:
                calendar.typeHousingCalendar === "calendarImport"
                  ? "rgba(255, 182, 193, .6)"
                  : calendar.typeHousingCalendar === "season"
                  ? "rgba(255, 165, 0, .6)"
                  : calendar.typeHousingCalendar === "block"
                  ? "rgba(255, 0, 0, .6)"
                  : calendar.typeHousingCalendar === "hold"
                  ? "rgba(0, 0, 255, .6)"
                  : calendar.typeHousingCalendar === "hot_sale"
                  ? "rgba(255, 78, 0, .6)"
                  : "rgba(0, 128, 0, .6)",
              typeHousingCalendar: calendar.typeHousingCalendar,
            };
          });
          bodyParameters["calendar"] = newBodyParameters;
          // bodyParameters['basePrice'] = bodyParameters.basePrice
        }

        this.setState({
          bodyParameters: bodyParameters,
          loading: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLang !== prevProps.currentLang) {
      this.setState({
        currentLang: this.props.currentLang,
      });
    }
  }
  showStep = () => {
    const step = this.state.step;

    const bodyParameters = this.state.bodyParameters;
    switch (step) {
      case 3:
        return (
          <Type
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 4:
        return (
          <Quantities
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 5:
        return (
          <Location
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 6:
        return (
          <Services
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 7:
        return (
          <Covid
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 8:
        return (
          <Photos
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 9:
        return (
          <Rules
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 10:
        return (
          <Guests
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 11:
        return (
          <Title
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 12:
        return (
          <Prices
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 13:
        return (
          <Payment
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 14:
        return (
          <Legal
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      case 15:
        return (
          <Calendar
            lenguaje={this.state.currentLang}
            bodyParameters={bodyParameters}
            modeEdit
          />
        );
      default:
        window.location.href = "/";
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? this.showStep() : ""}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(AloharFormEdit);
