// Librerías
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as aloharFormActions from '../../../store/aloharForm/actions';
import validator from 'validator';
import AxiosAuth from '../../../services/requestApi'
// Componentes
import ErrorMessage from '../../Alerts/ErrorAlert';
import AvatarInput from '../Utils/AvatarInput';
import Buttons from '../Utils/Buttons';
// Recursos
import '../../../assets/styles/AloharForm/Steps/_user_step.sass';
import { BASE_URL } from '../../../Helpers';
import userStepJson from "../json/tradAloharForm.json";


/**
 * Formulario
 *
 * Foto: user_avatar
 *
 * ----
 * Teléfono:
 *
 * Prefijo por país: country_code
 * Celular: cellphone
 *
 */

class UserStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: this.props.bodyParameters.phoneNumber || '',
      prefixPhoneNumber: this.props.bodyParameters.prefixPhoneNumber || '+54',
      errors: {},
      lenguaje: this.props.lenguaje || "es",

    };

    this.handleChange = this.handleChange.bind(this);
  }
  currentLenguaje = (json) => {

    return json[this.state.lenguaje];
  };

  componentWillMount() {
    const url = BASE_URL + "/user/phone";
    AxiosAuth.get(url)
      .then((response) => {
        if (response.data.phoneNumber) {
          this.setState({
            phoneNumber: response.data.phoneNumber
          }, () => {
            this.props.userStep(this.state);
            this.handleValidate()
          })
        }
      }).catch((error) => {
        console.log(error)
        this.handleValidate()
      });
    this.setState({
      lenguaje: this.props.lenguaje,
    });

  }

  componentDidUpdate() {
    if ( this.props.lenguaje !== this.state.lenguaje)  {
      this.setState({
        lenguaje: this.props.lenguaje,
      });
    }
  }

  handleValidate = () => {

    let errors = {};
    const validate = (
      validator.isNumeric(this.state.phoneNumber)
      && this.state.prefixPhoneNumber === '+54'
      && this.state.phoneNumber.length > 8
    )

   

    if (this.state.phoneNumber.length <= 8)
      errors['phoneNumber'] = "El número de celular debe tener al menos 9 dígitos"
    if (!validator.isNumeric(this.state.phoneNumber))
      errors['phoneNumber'] = "El número de celular deben ser solo dígitos númericos"

    this.setState({ errors: errors })
    this.props.validateStep(validate);
  }

  handleBlur = () => {
    this.props.userStep(this.state);
    this.handleValidate();
  }


  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val }, () => { this.handleValidate() });
  }

  render() {
    return (
      <React.Fragment>
        <div className="responsiveUserStep">
          <div className="UserStep">
            <div className="UserStep__container">
              <div className="UserStep__container__photo">
                <h2 className="UserStep__container__photo__title">
                  {this.currentLenguaje(userStepJson).fotoPerfil}
                </h2>
                <p className="UserStep__container__photo__description">
                  {this.currentLenguaje(userStepJson).fotoPerfilAclaracion}
                </p>
                <AvatarInput currentLenguaje={this.currentLenguaje} lenguaje={this.state.lenguaje} />
              </div>
              <div className="UserStep__container__cellphone">
                <h2 className="UserStep__container__cellphone__title">
                  {this.currentLenguaje(userStepJson).agregarTelefono}
                </h2>
                <p className="UserStep__container__cellphone__description">
                  {this.currentLenguaje(userStepJson).agregarTelefonoAclaracion}

                  <br />
                  {this.currentLenguaje(userStepJson).agregarTelefonoAclaracion2}
                </p>
                <div className="container">
                  <div className="inputContainer">
                    <label className="label">
                      <span className="text-label">
                        {this.currentLenguaje(userStepJson).prefijo}
                      </span>
                    </label>
                    <input
                      name="prefixPhoneNumber" id="country_code" disabled
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete={false}
                      value={"Argentina (+54)"}
                    />
                  </div>
                  <div className="inputContainer" id="second">
                    <label className="label">
                      <span className="text-label" htmlFor="cellphone">
                        {this.currentLenguaje(userStepJson).celular}
                      </span>
                    </label>
                    <input
                      className={this.state.errors["phoneNumber"] ? "input errorInput" : "input"}
                      name="phoneNumber" id="cellphone" type="number"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      autoComplete={false}
                    />
                  </div>
                </div>
                <div className="UserStep__container__cellphone__error">
                  {
                    this.state.errors["phoneNumber"]
                      ? <ErrorMessage message={this.state.errors["phoneNumber"]} />
                      : ''
                  }
                </div>
              </div>
            </div>
            <Buttons lenguaje = {this.props.lenguaje} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {

  return { ...reducers.aloharFormReducer, ...reducers.userReducer }
};

export default connect(mapStateToProps, aloharFormActions)(UserStep);