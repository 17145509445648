// Librerías
import React from "react";
import { useParams } from "react-router-dom";
// Componentes
import AloharFormEdit from "../components/AloharForm/AloharFormEdit";
import Navbar from "../components/Navbar/Navbar";
import { useLanguage } from "../store/lenguaje/useLenguaje";

const EditAloharPage = () => {
  const { editStep, id } = useParams();
  const { currentLang } = useLanguage();
    
  return (
    <div className="AloharForm">
      <Navbar />
      <AloharFormEdit currentLang={currentLang} editStep={editStep} id={id} />
    </div>
  );
};

export default EditAloharPage;
