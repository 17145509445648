// Librerías
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
// Componentes
import Buttons from "../Utils/Buttons";
import ErrorMessage from "../../Alerts/ErrorAlert";
import ProgressBar from "../Utils/ProgressBar";
import ButtonEdit from "../Utils/ButtonEdit";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_location.sass";
import mapStyles from "../Utils/mapStyles";
import { getCity, getNeighbors, getProvinces } from "../../../services/api";
import UbicationMap from "../Utils/UbicationMap";
import ButtonEditAApp from "../Utils/ButtonEditAApp";

const Search = ({
  panTo,
  putMarker,
  adress,
  errors,
  lat,
  lng,
  city,
  setAdress,
}) => {
  const [cityReady, setCityReady] = React.useState(true);

  const {
    ready,
    suggestions: { status, data },
    value,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => NaN,
        lng: () => NaN,
      },
      radius: 5 * 1000,
    },
  });

  useEffect(() => {
    setValue(adress, false);
    putMarker(
      parseFloat(lat),
      parseFloat(lng),
      lat === null && lng === null ? "useEffect" : ""
    );
    setTimeout(() => {
      panTo(parseFloat(lat), parseFloat(lng), adress, 15);
    }, 100);
    if (city !== undefined) {
      setCityReady(false);
    }
  }, [city]);

  return (
    <Combobox
      onChange={(e) => {
        setAdress(e.target.value);
      }}
      onSelect={async (address) => {
        setValue(address, false);
        clearSuggestions();
        try {
          let newAddress = {
            address: `${address}, ${city}, Argentina`,
          };
          const results = await getGeocode(newAddress);
          const { lat, lng } = await getLatLng(results[0]);
          putMarker(lat, lng, "onSelect");
          panTo(lat, lng, address);
        } catch (error) {
          console.error(error);
        }
      }}
      className="Location__container__inputContainer Location__container__inputContainer__special"
    >
      <label
        className="Location__container__inputContainer__label"
        htmlFor="address"
      >
        Dirección (Calle y Número)
      </label>
      <ComboboxInput
        className="Location__container__inputContainer__input"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        disabled={cityReady}
        placeholder="Ejemplo: San Martín 1334"
        autocomplete={false}
      />
      {errors["adress"] ? <ErrorMessage message={errors["adress"]} /> : ""}
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ id, structured_formatting }) => {
              if (
                structured_formatting.secondary_text &&
                structured_formatting.secondary_text.includes("Mar del Plata")
              ) {
                return (
                  <ComboboxOption
                    key={id}
                    value={structured_formatting.main_text}
                  />
                );
              }
            })}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

const mapContainerStyle = {
  width: "100vw",
  heigth: "100vh",
};

const options = {
  styles: mapStyles,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
};

const Location = (props) => {
  const mapRef = useRef();
  const [marker, setMarker] = useState(null);
  const [lat, setLat] = useState(props.bodyParameters.lat || 333);
  const [lng, setLng] = useState(props.bodyParameters.lng || 333);
  const [codeTad, setCodeTad] = useState(props.bodyParameters.TAD || "");

  const [center, setCenter] = useState({
    lat: props.bodyParameters.lat || -37.996971,
    lng: props.bodyParameters.lng || -57.549099,
  });
  const [zoom, setZoom] = useState(props.bodyParameters.lat ? 18 : 12);
  const [adress, setAdress] = useState(props.bodyParameters.adress || "");
  const [adressExtra, setAdressExtra] = useState(
    props.bodyParameters.adressExtra || ""
  );
  const [floor, setFloor] = useState(props.bodyParameters.floor || "");
  const [department, setDepartment] = useState(
    props.bodyParameters.department || ""
  );
  const [errors, setErrors] = useState({});
  const [statemodeEdit, setStatemodeEdit] = useState(
    props.bodyParameters.statemodeEdit
  );
  const [cityList, setCityList] = useState([]);
  // Barrios
  const [nList, setNList] = useState([]);
  const [neighborhoodId, setNeighborhoodId] = useState(
    props.bodyParameters.neighborhood || null
  );
  const [neighborhoodSelected, setNeighborhoodSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [citySelected, setCitySelected] = useState();
  const [locationId, setLocationId] = useState(
    props.bodyParameters.locationId || null
  );
  const [caba, setCaba] = useState(0);

  const [count_validate, set_count_validate] = useState(props.count_validate);
  const [provinces, setProvinces] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState(
    props.bodyParameters.province || null
  );

  //const validate = props.validateStep
  const [init, setInit] = React.useState(false);

  const getProvinceList = async () => {
    try {
      const result = await getProvinces();
      setProvinces(result.data);
      getCityList(provinceSelected);
      setLoading(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getCityList = async (provinceId) => {
    try {
      const result = await getCity(provinceId);
      setCityList(result.data);
      setLoading(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getNeighborhoods = async (locationId) => {
    try {
      const result = await getNeighbors(locationId);
      setNList(result.data);
      setLoading(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (provinces.length === 0) getProvinceList();
    if (locationId !== null && nList.length === 0) getNeighborhoods(locationId);

    let data = {
      locationId: locationId,
      adress: adress,
      adressExtra: adressExtra,
      lat: lat,
      lng: lng,
      floor: floor,
      department: department,
      province: provinceSelected,
      TAD: codeTad,
      neighborhood: parseInt(neighborhoodId),
    };

    const validate =
      locationId > 0 &&
      adress.length > 5 &&
      lat !== null &&
      lng !== null &&
      !isNaN(lat) &&
      !isNaN(lng);

    let errors = {};

    if (locationId < 1) {
      errors["location"] = "Debe seleccionar una localidad";
    }
    if (adress.length < 5) {
      errors["adress"] = "La dirección debe ser más larga";
    }

    if (lat === null || lng === null || isNaN(lat) || isNaN(lng)) {
      errors["gps"] = "No se encontraron las coordenadas";
    }
    if (!lat && !neighborhoodId) {
      errors["neighborhood"] = "Debe seleccionar un barrio";
    } else if (!lat && neighborhoodId) {
      errors["city"] = "Debe seleccionar una ciudad";
    }

    props.validateStep(validate);
    props.locationStep(data);
    setErrors(errors);
  }, [
    lat,
    lng,
    locationId,
    neighborhoodId,
    citySelected,
    cityList,
    props.count_validate,
    codeTad,
    adress,
  ]);

  const putMarker = (lat, lng, put = false) => {
    if (put === "useEffect") {
      setMarker({
        ...marker,
        lat: NaN,
        lng: NaN,
      });
    } else {
      setMarker({
        ...marker,
        lat: lat,
        lng: lng,
      });
      latRef.current.value = lat;
      lngRef.current.value = lng;
      if (lat && lng) {
        setLat(lat);
        setLng(lng);
      }
    }
  };

  const changeMarker = (e) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    putMarker(lat, lng, "changeMarker");
  };

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const latRef = React.useRef();
  const lngRef = React.useRef();

  const pantToCity = (lat, lng) => {
    panTo(lat, lng, "", 12);
    // mapRef.current.panTo({ lat, lng });
    // mapRef.current.setZoom(12);
  };

  const panTo = React.useCallback((lat, lng, adress, zoom = 15) => {
    if (lat && lng) {
      // mapRef.current.panTo({ lat, lng });
      // mapRef.current.setZoom(zoom);
      setZoom(zoom);
      setCenter({
        lat: lat,
        lng: lng,
      });
      setLat(lat);
      setLng(lng);
      setAdress(adress);
    }
  }, []);

  const handleOnChangeProvince = (e) => {
    putMarker(NaN, NaN, "handleOnChangeProvince");
    if (e.target.value === "") {
      props.validate(false);
      errors["province"] = "Debe seleccionar una provincia";
      setErrors(errors);
    } else {
      setProvinceSelected(e.target.value);
      getCityList(parseInt(e.target.value));
    }
  };
  const handleCodeTad = (e) => {
    const removeSpaces = e.target.value.trim();
    setCodeTad(removeSpaces);
  };
  const handleOnChangeCity = (e) => {
    putMarker(NaN, NaN, "handleOnChangeCity");
    if (e.target.value === "") {
      props.validate(false);
      errors["location"] = "Debe seleccionar una ciudad";
      setErrors(errors);
    } else {
      const lat = e.target[e.target.selectedIndex].getAttribute("data-lat");
      const lng = e.target[e.target.selectedIndex].getAttribute("data-lng");
      const locationID = parseInt(
        e.target[e.target.selectedIndex].getAttribute("data-location")
      );
      const locationString = e.target.value;

      setCitySelected(locationString);
      setLocationId(locationID);
      getNeighborhoods(locationID);
      // pantToCity(parseFloat(lat), parseFloat(lng));
      setLat(lat);
      setLng(lng);
    }
  };

  const handleOnChangeNeighborhood = (e) => {
    if (e.target.value !== "") {
      const lat = e.target[e.target.selectedIndex].getAttribute("data-lat");
      const lng = e.target[e.target.selectedIndex].getAttribute("data-lng");
      const nID = parseInt(
        e.target[e.target.selectedIndex].getAttribute("data-location")
      );
      setNeighborhoodSelected(e.target.value);
      setNeighborhoodId(nID);
      pantToCity(parseFloat(lat), parseFloat(lng));
    }
  };

  useEffect(() => {
    if (cityList.length > 0) {
      setCaba(cityList[0].id);
    }
  }, [cityList]);

  useEffect(() => {
    panTo(parseFloat(lat), parseFloat(lng), adress, 15);
  }, []);

  return (
    <React.Fragment>
      <div className="LocationResponsive">
        <div className="Location">
          <div className="Location__title">
            {props.modeEdit ? (
              ""
            ) : (
              <ProgressBar title={"UBICACIÓN"} stepAt={"Paso 1: Lo esencial"} />
            )}
          </div>
          <div className="Location__container">
            <div className="Location__container-left">
              <h2 className="Location__container__title">
                ¿Cuál es la ubicación de tu espacio?
              </h2>
              <p className="Location__container__description">
                Los huéspedes solo podrán ver tu dirección después de reservar.
              </p>
              {/* PAIS / REGIÓN */}
              {/* PAIS // Provincia */}
              <div className="Location__container__inputContainerGrid">
                {/* PAIS */}
                <div className="Location__container__inputContainer">
                  <label
                    className="Location__container__inputContainer__label"
                    htmlFor="country"
                  >
                    País / Región
                  </label>
                  <input
                    className="Location__container__inputContainer__input"
                    name="country"
                    id="country"
                    type="text"
                    value="Argentina"
                    disabled
                  />
                </div>
                {/* Provincia */}
                <div className="Location__container__inputContainer">
                  <label
                    className="Location__container__inputContainer__labelSecondary"
                    htmlFor="state"
                  >
                    Provincia
                  </label>
                  <select
                    onChange={(e) => handleOnChangeProvince(e)}
                    value={provinceSelected}
                    defaultValue={provinceSelected}
                    className="Location__container__inputContainer__select"
                    disabled={!loading}
                  >
                    <option value="" key="">
                      Seleccione una provincia
                    </option>
                    {provinces
                      .sort((a, b) => {
                        return a.province < b.province
                          ? -1
                          : a.province > b.province
                          ? 1
                          : 0;
                      })
                      .map((p) => {
                        return (
                          <option value={p.id} key={"province_" + p.id}>
                            {p.province}
                          </option>
                        );
                      })}
                  </select>
                  {errors["province"] ? (
                    <ErrorMessage message={errors["province"]} />
                  ) : (
                    ""
                  )}
                  {/* <input className="Location__container__inputContainer__input" name="state" id="state" type="text" value="Buenos Aires" disabled/> */}
                </div>
                {caba === 428 && (
                  <div className="Location__container__inputContainer">
                    <label
                      className="Location__container__inputContainer__labelSecondary"
                      htmlFor="TAD"
                    >
                      Nro. habilitación CABA
                    </label>
                    <input
                      id="TAD"
                      type="text"
                      onChange={(e) => handleCodeTad(e)}
                      value={codeTad}
                      name="TAD"
                      className="Location__container__inputContainer__input"
                      placeholder={"Ingresar codigo"}
                    />
                  </div>
                )}
              </div>
              <div className="Location__container__inputContainer">
                <label
                  htmlFor="booking_anticipation"
                  className="Location__container__inputContainer__labelSecondary"
                >
                  Ciudad
                </label>
                <select
                  onChange={(e) => handleOnChangeCity(e)}
                  value={citySelected}
                  className="Location__container__inputContainer__select"
                  disabled={!loading}
                >
                  <option value="" key="">
                    Seleccione una ciudad
                  </option>
                  {cityList
                    .sort((a, b) => {
                      return a.location < b.location
                        ? -1
                        : a.location > b.location
                        ? 1
                        : 0;
                    })
                    .map((city) => {
                      return (
                        <option
                          data-location={city.id}
                          selected={city.id === locationId}
                          data-lat={city.lat}
                          data-lng={city.lng}
                          value={city.location}
                          key={"city_" + city.id}
                        >
                          {city.location}
                        </option>
                      );
                    })}
                </select>

                {/* Latitud */}
                <input ref={latRef} type="hidden" name="lat" id="lat" />
                {/* Longitud */}
                <input ref={lngRef} type="hidden" name="lng" id="lng" />
                {errors["city"] ? (
                  <ErrorMessage message={errors["city"]} />
                ) : (
                  ""
                )}
              </div>

              {/* Barrios */}
              {nList.length > 0 && (
                <>
                  <div className="Location__container__inputContainer">
                    <label
                      htmlFor="booking_anticipation"
                      className="Location__container__inputContainer__label"
                    >
                      Barrio
                    </label>
                    <select
                      onChange={(e) => handleOnChangeNeighborhood(e)}
                      value={neighborhoodSelected}
                      className="Location__container__inputContainer__select"
                      disabled={!loading}
                    >
                      <option value="" key="">
                        Seleccione un barrio
                      </option>
                      {nList
                        .sort((a, b) => {
                          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                        })
                        .map((neighborhood) => {
                          return (
                            <option
                              data-location={neighborhood.id}
                              selected={neighborhood.id === neighborhoodId}
                              data-lat={neighborhood.lat}
                              data-lng={neighborhood.lng}
                              value={neighborhood.id}
                              key={"neighborhood_" + neighborhood.id}
                            >
                              {neighborhood.name}
                            </option>
                          );
                        })}
                    </select>
                    {errors["neighborhood"] ? (
                      <ErrorMessage message={errors["neighborhood"]} />
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
              {/* DIRECCIÓN */}
              <Search
                panTo={panTo}
                putMarker={putMarker}
                setAdress={setAdress}
                errors={errors}
                adress={adress}
                lat={lat}
                lng={lng}
                city={citySelected}
              />
              {/* Departamento, Piso (OPCIONAL) */}

              <div className="Location__container__inputContainerGridSecondary">
                {/* PAIS */}
                <div className="Location__container__inputContainer">
                  <label
                    className="Location__container__inputContainer__label"
                    htmlFor="floor"
                  >
                    Piso
                  </label>
                  <input
                    className="Location__container__inputContainer__input"
                    autocomplete="none"
                    name="floor"
                    value={floor}
                    placeholder="-"
                    onChange={(e) => setFloor(e.target.value)}
                    id="floor"
                    type="text"
                  />
                  {errors["floor"] ? (
                    <ErrorMessage message={errors["floor"]} />
                  ) : (
                    ""
                  )}
                </div>
                {/* Provincia */}
                <div className="Location__container__inputContainer">
                  <label
                    className="Location__container__inputContainer__label"
                    htmlFor="department"
                  >
                    Departamento
                  </label>
                  <input
                    className="Location__container__inputContainer__input"
                    autocomplete="none"
                    name="department"
                    value={department}
                    placeholder="-"
                    onChange={(e) => setDepartment(e.target.value)}
                    id="department"
                    type="text"
                  />
                  {errors["department"] ? (
                    <ErrorMessage message={errors["department"]} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {marker ? (
              <div className="Location__container-right">
                <div className="FormMap">
                  <UbicationMap
                    latitude={lat}
                    longitude={lng}
                    setLatitude={setLat}
                    setLongitude={setLng}
                    pantToCity={pantToCity}
                  />
                </div>
                {errors["gps"] ? <ErrorMessage message={errors["gps"]} /> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
          {props.modeEdit ? <ButtonEditAApp /> : <Buttons />}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(Location);
