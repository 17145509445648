/* eslint-disable react/react-in-jsx-scope */
// Import Swiper React components
import React from "react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import hotSale from "../../../../src/assets/static/hot_sale/alohar-hot-sale.png";
import levelPlatinium from "../../../../src/assets/static/housing-promotions/bandas-plata-borde.png";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

import "../../../assets/styles/SearchPage/Utils/_swiper.sass";
import { BASE_URL } from "../../../Helpers";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

const CardSlider = (props) => {
  const maxAmount = 4;
  // const photos = props.housingMedia.filter((photo) => {
  //   return photo.cover !== true;
  // });
  const photos = props.housingMedia.sort((a, b) => {
    if (a.cover > b.cover) {
      return -1;
    }
    if (a.cover < b.cover) {
      return 1;
    }
    return 0;
  });
  const textStyle = props.map === true ? "InfoWindow" : "SmallCard";

  const slides = photos.slice(0, maxAmount).map((photo) => {
    const img =
      photo.media !== null
        ? `${BASE_URL}/${photo.media.replace("public/", "")}`
        : "https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png";

    return (
      <SwiperSlide>
        <img
          className={`${textStyle}__imgcontainer__img`}
          src={img}
          alt="Card"
        />
      </SwiperSlide>
    );
  });
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation={!props.disabledSlider}
      pagination={{ clickable: true }}
      className={`relative  overflow-hidden ${textStyle}__imgcontainer`}
    >
      {props.valideighlight &&
        props.housingPromotions?.priorityLevel.priority === 2 && (
          <img
            src={levelPlatinium}
            alt="level platinum"
            className="absolute top-0 left-0 z-50 w-[45%]"
          />
        )}
      {/* {props.level === 1 && (
        <img
          src={levelGold}
          alt="Hot sale"
          className="absolute z-50 left-1 top-1"
        />
      )}  */}
      {props.hotSale && [0, 2].includes(props.status) && (
        <img
          src={hotSale}
          alt="Hot sale"
          className="absolute right-[5%] z-50 w-10 top-0"
        />
      )}
      {slides}
    </Swiper>
  );
};

export default CardSlider;
