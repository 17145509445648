import React from "react";
import MaskedInput from "react-maskedinput";
import EyeIcon from "../../../assets/components-icons/EyeIcon";
import "./input-global.sass";
import { useRef } from "react";
const InputGlobal = ({
  name,
  type,
  onChange,
  onClick,
  value,
  handleBlur,
  maxLength,
  minLength,
  min,
  max,
  cuit,
  width,
  height,
  border,
  prefix,
  textAlign,
  price,
  formatPrice,
  disabled,
  className,
}) => {
  const inputRef = useRef(null);

  const buttonRef = () => {
    inputRef.current.focus();
  };
  const handleKeyDown = (event) => {
    // Verificar si la tecla presionada es '+' o '-'
    if (event.key === "+" || event.key === "-") {
      event.preventDefault(); // Prevenir la acción predeterminada
    }
  };

  return (
    <div
      className={`inputGlobal ${className} flex `}
      style={{
        width: `${width}`,
        height: `${height}`,
        border: border ? "1px solid #ff4e00" : "",
        borderRadius: border ? "4px" : "",
      }}
    >
      <label htmlFor={name} className="inputGlobal__title">
        {prefix}
      </label>
      {cuit ? (
        <MaskedInput
          type="cuit"
          name={name}
          mask="11-11111111-1"
          id={name}
          className="inputGlobal__inputMask"
          onChange={onChange}
          onPaste={onChange}
          onKeyDown={onChange}
          onBlur={handleBlur}
          maxLength={maxLength}
          minLength={minLength}
          min={min}
          max={max}
          value={value}
          disabled={disabled}
        />
      ) : (
        <>
          <h2
            className={price ? "inputGlobal__view" : "inputGlobal__input"}
            onClick={() => buttonRef()}
          >
            {price > 0
              ? ` ${Intl.NumberFormat("de-AR").format(price)}`
              : ` ${Intl.NumberFormat("de-AR").format(value)}`}
          </h2>
          <input
            onKeyDown={handleKeyDown}
            disabled={disabled}
            ref={inputRef}
            maxLength={maxLength}
            minLength={minLength}
            min={min ? min : 0}
            max={max}
            type={type ? type : "password"}
            name={name}
            id={name}
            className={price ? " inputGlobal__input" : "inputGlobal__view"}
            style={{
              background: "transparent",
              textAlign: textAlign ? textAlign : "",
            }}
            placeholder={type === "number" ? price : value}
            onChange={onChange}
            onClick={onClick}
            onBlur={handleBlur}
            defaultValue={
              type === "number"
                ? ` ${Intl.NumberFormat("de-AR").format(value)}`
                : value
            }
          />
        </>
      )}

      {type === "password" && <EyeIcon size={25} />}
    </div>
  );
};

export default InputGlobal;
