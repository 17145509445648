// Librerías
import React, { Component } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Componentes
import Buttons from "../Utils/Buttons";
import ButtonEdit from "../Utils/ButtonEdit";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_legal.sass";
import Pdf from "../../../assets/static/legal.pdf";
import download from "../../../assets/static/Icons/download.svg";
import ProgressBar from "../Utils/ProgressBar";
import legalJson from "../json/tradAloharForm.json";

class Legal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modeEdit: this.props.modeEdit || false,
      count_validate: this.props.count_validate,
      lenguaje: this.props.lenguaje || "es",
    };
  }
  
  currentLenguaje = (json) => {
    return json[this.state.lenguaje];
  };
  componentDidUpdate(prevProps) {
    if (this.props.lenguaje !== prevProps.lenguaje) {
      this.setState({ lenguaje: this.props.lenguaje });
    }
  }
  
  render() {

    // this.props.validateStep(true);

    return (
      <React.Fragment>
        <div className="legalResponsive">
          <div className="Legal">
            {this.state.modeEdit ? (
              ""
            ) : (
              <ProgressBar
                title={this.currentLenguaje(legalJson).terminosYCondiciones}
              />
            )}

            <h1 className="Legal__title">
              {this.currentLenguaje(legalJson).terminosYCondiciones2}
            </h1>
            <h3 className="Legal__subtitle">
              {this.currentLenguaje(legalJson).terminosYCondicionesAclaracion}
            </h3>
            <p className="Legal__title">
              {this.currentLenguaje(legalJson).alcanceTyC}
            </p>
            <p className="Legal__description">
              {this.currentLenguaje(legalJson).generalidadesTyC}
            </p>
            <br />
            <p className="Legal__description">
              {this.currentLenguaje(legalJson).administradorTyC}
            </p>
            <br />
            <a href={Pdf} target="_blank" rel="noopener noreferrer">
              <img alt="descargar pdf" src={download} />
              {this.currentLenguaje(legalJson).descargarTyC}
            </a>
            <div className="Legal__buttons">
              {this.state.modeEdit ? (
                <ButtonEdit lenguaje={this.props.lenguaje} />
              ) : (
                <Buttons lenguaje={this.props.lenguaje} />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(Legal);
