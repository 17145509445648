// Librerías
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosAuth from "../../services/requestApi";
import queryString from "query-string";

// Componentes
import { Helmet } from "react-helmet";
import ConfirmedMap from "./Utils/ConfirmedMap";
// Recursos
import "../../assets/styles/Confirmed/__confirmed.sass";
import { cancelationsEs, cancelationsEn, cancelationsPo } from "../Utils/CancelationPolicy";
import loadingGif from "../../assets/static/loading.gif";
import moment from "moment";
import { parseMoney } from "../../Helpers";
import { useLanguage } from "../../store/lenguaje/useLenguaje";
import globalJson from "../../utilities/traduccGlobal.json";
import confirmedComponentJson from "./tradConfirmed.json";
import useCurrentLanguage from "../../services/currentlenguaje"

moment.locale("es");

const ConfirmedComponentAlohador = () => {
  const { currentLang } = useLanguage();

  const cancelations = currentLang === "es" ? cancelationsEs 
  : currentLang === "en" ? cancelationsEn
  : currentLang === "po" ? cancelationsPo
  : cancelationsEs

  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [reservation, setReservation] = useState();
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [rules, setRules] = useState([]);
  const [price, setPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [percentagePrice, setPercentagePrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [payDate, setPayDate] = useState(0);
  const { id } = useParams();
  const {handleFilterLang} = useCurrentLanguage () ;


  const url = "reservation/" + id;
  useEffect(() => {
    if (!init) {
      setInit(true);
      AxiosAuth.get(url)
        .then((response) => {
          setReservation(response.data);
          const ci = moment(response.data.checkin).utc(true);
          setCheckin(ci.format("dddd D MMMM"));
          const co = moment(response.data.checkout).utc(true);
          setCheckout(co.format("dddd D MMMM"));
          const _rules = response.data.housing.housingRule.map((r) => r.rule);
          setRules(_rules);

          const prices = response.data.prices;
          let _price = 0;
          let _cleanPrice = 0;
          prices.map((p) => {
            if (p.priceType === "BASE_PRICE") {
              setPrice(truncar(p.total, 2));
              _price = truncar(p.total, 2);
            }
            if (p.priceType === "CLEANING_PRICE") {
              setCleanPrice(truncar(p.total, 2));
              _cleanPrice = truncar(p.total, 2);
            }
            if (p.priceType === "WEEKLY_DISCOUNT")
              setWeeklyDiscount(truncar(p.total, 2));
            if (p.priceType === "MONTHLY_DISCOUNT")
              setMonthlyDiscount(truncar(p.total, 2));
          });

          setPayDate(ci.add("days", 2).format("dddd D MMMM"));

          setCouponDiscount(truncar(response.data.couponDiscount, 2));
          setFee(truncar(response.data.hostFee * 100, 2));
        })
        .catch((e) => {
          console.log(e.response);
        })
        .then(() => {
          setLoading(false);
        });
    }
    setServicePrice(
      price + cleanPrice - monthlyDiscount - weeklyDiscount - couponDiscount
    );
    setPercentagePrice(servicePrice * 0.07865);
  });

  const detailStyle = () => {
    if (reservation && reservation.status === "pending") {
      return {
        minHeight: `60%`,
      };
    } else {
      return {
        minHeight: `100%`,
      };
    }
  };

  const truncar = (num, digitos) => {
    if (num.toString().length < 3) return num;
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);

    return _num ? _num[1] : num;
  };
  return (
    <div className="Confirmed">
      {loading ? (
        <img style={{ margin: "auto" }} src={loadingGif} />
      ) : (
        <>
          <div className="Confirmed__container">
            <h2 className="Confirmed__container__title">
              {reservation && reservation.status === "cancelled"
                ?  handleFilterLang (confirmedComponentJson).reservaCancelada
               
                : ""}
              {reservation && reservation.status === "pending"
                ? handleFilterLang (confirmedComponentJson).reservaPendiente
                 
                : ""}
              {reservation && reservation.status === "confirmed" ? (
                <>
                {handleFilterLang (confirmedComponentJson).tuHuespedConfirmo}
                  {" "}
                  {reservation ? reservation.housing.location.location : ""}!
                </>
              ) : (
                ""
              )}
            </h2>
            <p className="Confirmed__container__text"></p>
            <p className="Confirmed__container__text">
            {handleFilterLang (confirmedComponentJson).confirmacionPorCorreo}
              <b>{reservation ? reservation.email : ""}</b>.
            </p>
            <p className="Confirmed__container__text">
            {handleFilterLang (confirmedComponentJson).infoReserva}

              {" "}
              <Link
                to="/profile/housing"
                className="Confirmed__container__link"
              >
                {handleFilterLang (globalJson).misAlojamientos}
                
              </Link>
            </p>
            <div className="Confirmed__container__separator"></div>
            <div className="Confirmed__container__details">
              <div className="Confirmed__container__details__rules">
                <h3 className="Confirmed__container__details__rules__title">
                {handleFilterLang (globalJson).normasCasa}

                  
                </h3>
                <ul className="Confirmed__container__details__rules__list">
                  <li className="Confirmed__container__details__rules__list__item">
                    Check-in: {handleFilterLang (globalJson).de}{" "}
                    {reservation
                      ? reservation.startCheckInTime.slice(0, -3)
                      : ""}{" "}
                    {handleFilterLang (globalJson).a}{" "}
                    {reservation ? reservation.endCheckinTime.slice(0, -3) : ""}
                  </li>
                  <li className="Confirmed__container__details__rules__list__item">
                    Check-out:{" "}
                    {reservation ? reservation.checkoutTime.slice(0, -3) : ""}
                  </li>
                  <li className="Confirmed__container__details__rules__list__item">
                    {rules.includes("smooke") ? handleFilterLang (globalJson).se : handleFilterLang (globalJson).noSe }
                    {handleFilterLang (globalJson).fumar}
                  </li>
                  <li className="Confirmed__container__details__rules__list__item">
                    {rules.includes("pets") ? handleFilterLang (globalJson).se : handleFilterLang (globalJson).noSe}
                    {handleFilterLang (globalJson).mascotas}
                  </li>
                  <li className="Confirmed__container__details__rules__list__item">
                    {rules.includes("party") ? handleFilterLang (globalJson).se : handleFilterLang (globalJson).noSe}
                    {handleFilterLang (globalJson).eventos}
                  </li>
                  <li className="Confirmed__container__details__rules__list__item">
                    {reservation ? reservation.ruleExtra : ""}
                  </li>
                </ul>
              </div>
              <div className="Confirmed__container__details__policy">
                <h3 className="Confirmed__container__details__policy__title">
                {handleFilterLang (globalJson).politicaCancelacion}
                  
                </h3>
                <p className="Confirmed__container__details__rules__text">
                  {reservation
                    ? cancelations[reservation.cancellationType]
                    : ""}
                  <br />
                  <br />
                  <i style={{ fontSize: "10px" }}>
                    *Check in: {handleFilterLang (confirmedComponentJson).contemplacionCheckin}
                  </i>
                </p>
              </div>
            </div>
          </div>
          {/*  && reservation.status === "confirmed"  */}
          {reservation ? (
            <div className="Confirmed__detail" style={detailStyle()}>
              <h2 className="Confirmed__detail__title">
                {reservation ? reservation.housing.title : ""}
              </h2>
              {reservation.status === "confirmed" ? (
                <>
                  <p className="Confirmed__detail__location">
                    {reservation ? reservation.housing.adress : ""}{" "}
                    {reservation ? reservation.housing.adressExtra : ""},{" "}
                    {reservation ? reservation.housing.location.location : ""}
                  </p>
                  <div className="Confirmed__detail__map">
                    <ConfirmedMap
                      lat={reservation ? reservation.housing.lat : ""}
                      lng={reservation ? reservation.housing.lng : ""}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="Confirmed__detail__container">
                <p className="Confirmed__detail__container__dates">

                  Checkin: {checkin}
                </p>
                <p className="Confirmed__detail__container__dates">
                  Checkout: {checkout}
                </p>
                <p className="Confirmed__detail__container__people">
                {handleFilterLang (globalJson).huespedes}: {reservation.guests}
                </p>
              </div>
              <ul className="Confirmed__detail__list">
                <li className="Confirmed__detail__list__item">
                  <p className="Confirmed__detail__list__item__desc">
                  {handleFilterLang (globalJson).tarifaAlojamiento}:
                  </p>
                  <p className="Confirmed__detail__list__item__amount">
                    ${" "}
                    {price
                      .toString()
                      .split(/(?=(?:\d{3})+(?:,|$))/g)
                      .join(".")}
                  </p>
                </li>

                <li className="Confirmed__detail__list__item">
                  <p className="Confirmed__detail__list__item__desc">

                    {handleFilterLang (globalJson).tarifaLimpieza}:
                  </p>
                  <p className="Confirmed__detail__list__item__amount">
                    ${" "}
                    {cleanPrice
                      .toString()
                      .split(/(?=(?:\d{3})+(?:,|$))/g)
                      .join(".")}
                  </p>
                </li>

                {couponDiscount > 0 ? (
                  <li className="Confirmed__detail__list__item">
                    <p className="Confirmed__detail__list__item__desc">
                    {handleFilterLang (globalJson).descuentoCupon}:
                      
                    </p>
                    <p className="Confirmed__detail__list__item__amount">
                      -$
                      {couponDiscount
                        .toString()
                        .split(/(?=(?:\d{3})+(?:,|$))/g)
                        .join(".")}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {weeklyDiscount > 0 ? (
                  <li className="Confirmed__detail__list__item">
                    <p className="Confirmed__detail__list__item__desc">
                    {handleFilterLang (globalJson).descuentoSemanal}:
                    </p>
                    <p className="Confirmed__detail__list__item__amount">
                      -$
                      {weeklyDiscount
                        .toString()
                        .split(/(?=(?:\d{3})+(?:,|$))/g)
                        .join(".")}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {monthlyDiscount > 0 ? (
                  <li className="Confirmed__detail__list__item">
                    <p className="Confirmed__detail__list__item__desc">
                    {handleFilterLang (globalJson).descuentoMensual}:
                    </p>
                    <p className="Confirmed__detail__list__item__amount">
                      -$
                      {monthlyDiscount
                        .toString()
                        .split(/(?=(?:\d{3})+(?:,|$))/g)
                        .join(".")}
                    </p>
                  </li>
                ) : (
                  ""
                )}

                <li className="Confirmed__detail__list__item">
                  <p className="Confirmed__detail__list__item__desc">
                  {handleFilterLang (globalJson).tarifaServicio}:
                  </p>
                  <p className="Confirmed__detail__list__item__amount">
                    - ${Intl.NumberFormat("de-AR").format(percentagePrice, 2)}
                  </p>
                </li>

                <li className="Confirmed__detail__list__item">
                  <p className="Confirmed__detail__list__item__desc">{handleFilterLang (globalJson).total}:</p>
                  <p className="Confirmed__detail__list__item__amount">
                    $
                    {Intl.NumberFormat("de-AR").format(
                      price +
                        cleanPrice -
                        weeklyDiscount -
                        monthlyDiscount -
                        couponDiscount -
                        percentagePrice
                    )}
                  </p>
                </li>

                {reservation &&
                (reservation.status === "confirmed" ||
                  reservation.status === "pending") ? (
                  <li className="Confirmed__detail__list__item">
                    <p className="Confirmed__detail__list__item__desc">
                    {handleFilterLang (confirmedComponentJson).fechaEstimadaPago}:
                      
                    </p>
                    <p className="Confirmed__detail__list__item__amount">
                      {payDate}
                    </p>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default ConfirmedComponentAlohador;
