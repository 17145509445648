// Librerías
import React, { useState, useEffect } from "react";
import {
  openConfirmModal,
  openNotificationModal,
} from "../../store/modal/actions";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import { BASE_URL } from "../../Helpers";
import axios from "axios";
import AxiosAuth from "../../services/requestApi";
import queryString from "query-string";
// Componentes
import ErrorAlert from "../Alerts/ErrorAlert";
import ModalWrapper from "../Modals/ModalWrapper";
import NotificationModal from "../Modals/NotificationModal";
import { useHistory } from "react-router-dom";
// Recursos
// import '../../assets/styles/Users/_change_password.sass';
import {
  faExclamationCircle,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import eye from "../../assets/static/Icons/eye.svg";
import ButtonPrimary from "../ui/button-primary";
import ModalGlobal from "../ui/modal-global";
import HandleLikeIcon from "../../assets/components-icons/HandleLike";
// import CasaError3 from "../../assets/static/Icons/CasaError3.svg";
import "../../assets/styles/ProfileCard/user_profile.sass";
import HouseAlertIcon from "../../assets/components-icons/HouseAlertIcon";
import useCurrentLanguage from "../../services/currentlenguaje";
import userEditTrad from "../Users/tradUsers.json"

const ChangePassword = ({ buttonBack }) => {
  const { handleFilterLang } = useCurrentLanguage();

  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const user = useSelector((state) => state.userReducer, shallowEqual);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [icon, SetIcon] = useState(faExclamationCircle);
  const [errorInput, setErrorInput] = useState("");
  const [success, setSuccess] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validate, setValidate] = useState(false);
  const [recoverOrUpdate, setRecoverOrUpdate] = useState("recover");
  const [modalErrorBody, setModalErrorBody] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const ifValidate = (input) => {
    if (user.login) {
      if (input === "old_password" || input === undefined) {
        /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
        if (!re.test(oldPassword)) {
          return {
            v: false,
            e: 
                  handleFilterLang(userEditTrad).errorContraseña,
            i: "old_password",
          };
        }
      }
    }

    if (input === "new_password" || input === undefined) {
      /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (!re.test(password)) {
        return {
          v: false,
          e: 
          handleFilterLang(userEditTrad).errorContraseña,
          i: "new_password",
        };
      }
    }

    if (input === "repeated_password" || input === undefined) {
      if (password !== password2) {
        return {
          v: false,
          e: 
          handleFilterLang(userEditTrad).errorContraseña2,
          i: "repeated_password",
        };
      }
    }

    return { v: true, e: "", i: "" };
  };

  const ifSuccess = (input) => {
    if (input === "new_password" || input === undefined) {
      /* 1 mayuscula, 1 minuscula, 1 digito, 8 digitos*/
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
      if (re.test(password)) {
        return {
          v: true,
          e: 
          handleFilterLang(userEditTrad).contraseñaOk,
          i: "new_password",
        };
      }
    }
    return { v: false, e: "", i: "" };
  };

  const handleValidate = (input) => {
    setError("");
    const _ifValidate = ifValidate(input);
    const _ifSuccess = ifSuccess(input);
    setValidate(_ifValidate.v);
    if (!_ifValidate.v) {
      setErrorInput(_ifValidate.i);
      setError(_ifValidate.e);
      setValidPassword(false);
    }
    if (_ifSuccess.v) {
      setValidPassword(_ifSuccess.e);
    }

    return _ifValidate.v;
  };

  const recoverPassword = () => {
    if (password === password2) {
      axios
        .post(`${BASE_URL}/auth/recover_password`, {
          password: password,
          email: email,
          token: token,
        })
        .then(function (response) {
          if (response.status === 201) {
            SetIcon(faThumbsUp);
            setSuccess(true);
            dispatch(openConfirmModal());
          }
        })
        .catch(function (error) {
          SetIcon(faExclamationCircle);
          setSuccess(false);
          setModalErrorBody(
            handleFilterLang(userEditTrad).errorContraseña3  +
              error.response.data.message
          );
          dispatch(openNotificationModal());
        });
    }
  };

  const updatePassword = async () => {
    const url = `${BASE_URL}/user/password`;
    const bodyParameters = {
      new_password: password,
      old_password: oldPassword,
    };
    await AxiosAuth.post(url, bodyParameters)
      .then((data) => {
        if (data.status === 201) {
          SetIcon(faThumbsUp);
          setSuccess(true);
          dispatch(openConfirmModal());
        }

        return true;
      })
      .catch((e) => {
        SetIcon(faExclamationCircle);
        setModalErrorBody(
          handleFilterLang(userEditTrad).errorContraseña3  + e.response.data.message
        );
        setSuccess(false);
        dispatch(openNotificationModal());
        return false;
      });
  };

  useEffect(() => {
    if (user.login) {
      setRecoverOrUpdate("update");
    } else {
      const query = queryString.parse(window.location.search);
      const { email, token } = query;
      setEmail(email);
      setToken(token);
    }
  }, [email, token]);

  const handleUpdate = () => {
    const valid = handleValidate();

    if (valid) {
      user.login ? updatePassword() : recoverPassword();
    }
  };

  return (
    <>
      <div className="userInfo__container">
        <ModalGlobal
          title={"asdasd"}
          closeModal={openModal}
          setCloseModal={() => setOpenModal(!openModal)}
        >
          <div className="bankAccount__modal">
            {success ? (
              <>
                <HandleLikeIcon size={100} color={"#ff4e00"} />
                <h2 className="bankAccount__modal__title">
                {handleFilterLang(userEditTrad).contraseñaActualizada1}
                  
                </h2>
              </>
            ) : (
              <>
                <HouseAlertIcon size={100} />
                <h2 className="bankAccount__modal__title">
                {handleFilterLang(userEditTrad).noSeActualizoContraseña} <br /> {handleFilterLang(userEditTrad).intentedenuevo}
                </h2>
              </>
            )}
            <ButtonPrimary
              title={handleFilterLang(userEditTrad).volver}
              onClick={() => setOpenModal(!openModal)}
            />
          </div>
        </ModalGlobal>
        <h1 className="bankAccount__title">{handleFilterLang(userEditTrad).cambiarContraseña}</h1>
        <div className="userInfo__container__info">
          {user.login ? (
            <div className="userInfo__container__info__content">
              <label
                htmlFor="old_password"
                className="userInfo__container__info__content__title"
              >
                {handleFilterLang(userEditTrad).contraseñaActual}
                
              </label>
              <input
                type={showOldPassword ? "text" : "password"}
                name="old_password"
                id="old_password"
                className="userInfo__container__info__content__description"
                placeholder=
                {handleFilterLang(userEditTrad).contraseñaActual1}
                
                onChange={(e) => setOldPassword(e.target.value)}
                onBlur={() => handleValidate("old_password")}
              />
              <i
                style={showOldPassword ? { color: "#FF4E00" } : {}}
                onClick={() => {
                  setShowOldPassword(!showOldPassword);
                }}
              >
                <img src={eye} alt={"Icono"} />
              </i>
            </div>
          ) : (
            ""
          )}
          <div className="userInfo__container__info__content">
            <label
              htmlFor="new_password"
              className="userInfo__container__info__content__title"
            >
                {handleFilterLang(userEditTrad).nuevaContraseña}

              
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="new_password"
              id="new_password"
              className="userInfo__container__info__content__description"
              placeholder=
                {handleFilterLang(userEditTrad).nuevaContraseña1}
              
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => handleValidate("new_password")}
            />
            <i
              style={showPassword ? { color: "#FF4E00" } : {}}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <img src={eye} alt={"Icono"} />
            </i>
          </div>
          <div className="userInfo__container__info__content">
            <label
              htmlFor="repeated_password"
              className="userInfo__container__info__content__title"
            >
              {handleFilterLang(userEditTrad).repetirContraseña}
              
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="repeated_password"
              id="repeated_password"
              className="userInfo__container__info__content__description"
              placeholder=
              {handleFilterLang(userEditTrad).repetirContraseña1}
              
              onChange={(e) => setPassword2(e.target.value)}
              onBlur={() => handleValidate("repeated_password")}
            />
            <i
              style={showPassword ? { color: "#FF4E00" } : {}}
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              <img src={eye} alt={"Icono"} />
            </i>
          </div>
          {validPassword ? (
            <div className="userInfo__container__info__content__error">
              <ErrorAlert type={"success"} message={validPassword} />
            </div>
          ) : (
            ""
          )}
          {error && errorInput === "old_password" ? (
            <div className="userInfo__container__info__content__error">
              <ErrorAlert message={error} />
            </div>
          ) : (
            ""
          )}
          {error && errorInput === "new_password" ? (
            <div className="userInfo__container__info__content__error">
              <ErrorAlert message={error} />
            </div>
          ) : (
            ""
          )}
          {error && errorInput === "repeated_password" ? (
            <div className="userInfo__container__info__content__error">
              <ErrorAlert message={error} />
            </div>
          ) : (
            ""
          )}
          <div className="userInfo__container__info__btn">
            <button
              className="userInfo__container__info__btn__savePass"
              onClick={handleUpdate}
            >
              {handleFilterLang(userEditTrad).guardarContraseña}


              
            </button>
          </div>
          <div className="bankAccount__buttons">
            <ButtonPrimary
              secondaryColor={true}
              title={handleFilterLang(userEditTrad).volver}
              onClick={buttonBack}
            />
            {/* <ButtonPrimary title={"Guardar"} onClick={() => handleValidate()} /> */}
          </div>
        </div>
      </div>
      {modalState.isOpen && modalState.type === "NOTIFICATION" ? (
        <ModalWrapper>
          <NotificationModal
            title={"Error"}
            text={modalErrorBody}
            buttonText={handleFilterLang(userEditTrad).aceptar}
            icon={icon}
          />
        </ModalWrapper>
      ) : (
        ""
      )}

      {modalState.isOpen && modalState.type === "CONFIRM" ? (
        <ModalWrapper>
          <NotificationModal
            title={"Contraseña actualizada"}
            text={"La constrasela fue actualizada correctamente."}
            icon={icon}
            buttonText={handleFilterLang(userEditTrad).aceptar}
          />
        </ModalWrapper>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.modalReducer;
};

export default connect(mapStateToProps, { openNotificationModal })(
  ChangePassword
);
