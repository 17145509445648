import React from "react";
import { useRef,useEffect } from "react";
import { useHistory } from "react-router-dom";
import HandleLikeIcon from "../../../assets/components-icons/HandleLike";
import LandingContainer from "../../landing-container";

const ThankYouPageRegister = () => {
  const router = useHistory();
  const myRefname = useRef(null);

  const redirectRegisterSuccess = () => {
    setTimeout(() => {
      router.push("/register-success");
    }, 30);
  };
  useEffect(() => {
    myRefname.current.click();
    redirectRegisterSuccess();
  },[]);



  return (
    <LandingContainer>
      <div className="successPayment">
        <h1 className="successPayment__title">
          Felicidades te acabas de registrar
        </h1>
        <div className="successPayment__img">
          <HandleLikeIcon width={"100%"} color="#FF4E00" />
        </div>
        <button
          id="boton-gracias-registro-completo"
          onClick={() => redirectRegisterSuccess()}
        >
          boto automatico
        </button>
        <p ref={myRefname}>boton-gracias-registro-completo</p>
      </div>
    </LandingContainer>
  );
};

export default ThankYouPageRegister;
