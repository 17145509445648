import { create } from "zustand";

import LocalStorageService from "./../../services/localStorageService";

export const useLanguage = create((set) => {
  const lang = LocalStorageService.getlanguage();

  return {
    currentLang: lang ? lang : "es",
    setCurrentLang: (value) => {
      LocalStorageService.setlanguage(value);
      set({ currentLang: value });
    },
  };
});
