// Librerías
import React, { useEffect } from "react";
import AxiosAuth from "../../../services/requestApi";
// Componentes
import Buttons from "../Utils/Buttons";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_values.sass";
import valuesJson from "../json/tradAloharForm.json";
import useCurrentLanguage from "../../../services/currentlenguaje";


const Values = ({lenguaje}) => {
  const [name, setName] = React.useState(null);
  const { handleFilterLang } = useCurrentLanguage();
  


  async function getUserName() {
    await AxiosAuth.get("/user/name").then((response) => {
      setName(response.data);
    });
  }

  useEffect(() => {
    getUserName();
  });

  return (
    <div className="Values">
      <div className="Values__left">
        <div className="Values__floating">
          <h1 className=" Values__title">
            {handleFilterLang(valuesJson).bienvenidoaAlohar}

            {name ? (
              <React.Fragment>
                ,<br />
                <strong>{name}</strong>!
              </React.Fragment>
            ) : (
              "!"
            )}
          </h1>
          <p className=" Values__description">
            {handleFilterLang(valuesJson).misionBienvenida1}

          </p>
          <p className=" Values__description">
            {handleFilterLang(valuesJson).misionBienvenida2}

            <b>
              {handleFilterLang(valuesJson).misionBienvenida3}
            </b>.
          </p>
          <p className="Values__description">
            {handleFilterLang(valuesJson).misionBienvenida4}

          </p>
          <div className="Values__button">
          <Buttons lenguaje = {lenguaje}/>
          </div>
        </div>
      </div>
      <div className="Values__right">
        <p className="Values__tagline">
          {handleFilterLang(valuesJson).elsaAlohadora}

          <br />
          {handleFilterLang(valuesJson).elsaAlohadora2}

        </p>
      </div>
    </div>
  );
};

export default Values;
