// Librerías
import React, { useState, useEffect, useRef, Suspense } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
} from "../../store/modal/actions";
import AxiosAuth from "../../services/requestApi";
import { BASE_URL } from "../../Helpers";
// Componentes
import UserMenu from "./UserMenu";
// Modales
import ModalWrapper from "../Modals/ModalWrapper";
import LoginModal from "../Modals/LoginModal";
import RegisterModal from "../Modals/RegisterModal";
import RecoverModal from "../Modals/RecoverModal";
// Recursos
import BLACK_LOGO_ALOHAR from "../../assets/static/Navbar/BLACK_LOGO_ALOHAR.svg";
import logoBlanco from "../../assets/static/logotipo.png";
import logoOrange from "../../assets/static/ALOHAR_LOGO_naranja.png";
import NotificationMessage from "../MessengersPage/Utils/notification-message";
import NotificationHousing from "./notification-housing";
// import aloharOrange from "../../assets/static/ALOHAR_LOGO_naranja.png";
import aloharOrange from "../../assets/static/Icons/aloharOrange.svg";
import MainInputSearchNew from "../MainSearch/MainInputSearchNew";
import BackButton from "../Buttons/BackButton";
import "../../assets/styles/Navbar/_navbar.sass";
import UserAvatar from "./UserAvatar";
import CanonicalUrl from "../../canonical-url";
import InputSelect from "../ui/select-input";
import { useLanguage } from "../../store/lenguaje/useLenguaje";
import useCurrentLanguage from "../../services/currentlenguaje";
import globalJson from "../../utilities/traduccGlobal.json";
import arg from "../../assets/static/flags/arg.png";
import po from "../../assets/static/flags/br.png";
import eeuu from "../../assets/static/flags/eeuu.png";
import LanguageDropdown from "../ui/languageDropdown";

// isLogged, isLanding: se explican solos. (true|false)
// logo: (white|null) White pondrá la versión blanca del logo
// text: (white|black) => Cambia el color de las letras a esos colores.
// register: (primary: color Naranja|null: Blanco) Cambia el bg del botón.

const Navbar = React.memo(
  ({
    addSearch,
    isLanding,
    logo,
    text,
    shadow,
    hideSearchButtons = true,
    hideAlohadorButtons = false,
    showMobile = true,
    hideButtons = false,
    editorial,
    messages,
    icon,
  }) => {
    const languages = [
      { code: "es", name: "Español", flag: arg },
      { code: "po", name: "Portugués", flag: po },
      { code: "en", name: "Inglés", flag: eeuu },
    ];
    const { setCurrentLang, currentLang } = useLanguage();
    const { handleFilterLang } = useCurrentLanguage();

    const role = {
      role: "housing",
    };

    let userRol;

    try {
      const storedRole = localStorage.getItem("role");
      const stringRole = JSON.stringify(storedRole);
      const jsonRole = JSON.parse(stringRole);
      if (jsonRole === "undefined") {
        localStorage.setItem("role", JSON.stringify(role));
      }
      userRol = storedRole ? JSON.parse(storedRole) : role;
    } catch (error) {
      userRol = role;
    }
    const dispatch = useDispatch();

    const modalState = useSelector((state) => state.modalReducer, shallowEqual);
    const user = useSelector((state) => state.userReducer, shallowEqual);
    const ref = useRef();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [messagesUnreaded, setMessagesUnreaded] = useState(0);
    const [latestMessage, setLatestMessage] = useState();
    const [housingListStatusPending, setHousingListStatusPending] = useState(
      []
    );
    const router = useHistory();

    const currentLocation = window.location.pathname;
    const queryString = window.location.search;
    const redirect = `${currentLocation.replace(
      "/alojamiento-temporal",
      ""
    )}${queryString.replace("/alojamiento-temporal", "")}`;

    const createHousing = () => {
      localStorage.removeItem("housingPublicProcessData");
      router.push("/quiero-ser-alohador");
    };

    const landing = (isLanding) => {
      if (isLanding) {
        return "true";
      }
      return "false";
    };

    const fShowMobile = (showMobile) => {
      if (showMobile) {
        return "true";
      }
      return "false";
    };

    function selectModal(modalType) {
      switch (modalType) {
        case "LOGIN":
          return (
            <ModalWrapper key="login_modal">
              <LoginModal />
            </ModalWrapper>
          );
        case "REGISTER":
          return (
            <ModalWrapper key="register_modal">
              <RegisterModal />
            </ModalWrapper>
          );
        case "RECOVER":
          return (
            <ModalWrapper key="recover_modal">
              <RecoverModal />
            </ModalWrapper>
          );
        default:
          return "";
      }
    }

    const setLogo = (logo, isLanding) => {
      if (logo === "orange") {
        return logoOrange;
      } else {
        return logoBlanco;
      }
    };

    const getMessages = async () => {
      if (router.location.pathname === "/") {
        const url = `${BASE_URL}/messages-unreaded`;
        await AxiosAuth.get(url)
          .then((response) => {
            setMessagesUnreaded(response.data.messagesUnread);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };

    const initialState = [];

    const [housingListPending, setHousingListPending] = useState([]);
    const [loading, setLoading] = useState(true);
    const [housingId, setHousingId] = useState(initialState);
    const [listHousing, setListHousing] = useState(false);
    useEffect(() => {
      // se traen los alojamientos
      const url = `${BASE_URL}/housing/my`;
      if (router.location.pathname === "/") {
        setLoading(true);

        AxiosAuth.get(url)
          .then(function (response) {
            if (response.data.length > 0) {
              setListHousing(true);
            }
            response.data.map((i) =>
              AxiosAuth.get("reservations/all/" + i.id)
                .then((response) => {
                  const responsePending = response.data.filter(
                    (e) => e.status === "pending"
                  );

                  setHousingListPending(responsePending);
                  const housingListPending = responsePending.find(
                    (e) => e.housing.id
                  );
                  if (housingListPending !== undefined) {
                    setHousingId((prevState) => [
                      ...prevState,
                      housingListPending,
                    ]);
                  }

                  setLoading(false);
                })
                .catch((e) => {
                  console.log(e);
                })
            );
          })
          .catch(function (error) {
            setLoading(false);
          });
      }
    }, []);
    useEffect(() => {
      getMessages();
    }, []);

    useEffect(() => {
      if (user.login) {
      }
      const data = housingListPending.filter((e) => e.status === "pending");
      setHousingListStatusPending(data);
    }, [housingListPending, user.login]);

    const LoggedButtons = () => {
      return (
        <Suspense fallback={<></>}>
          <CanonicalUrl />

          <div
            className="usermenubutton padding_0"
            onClick={
              user.login
                ? () => setIsMenuOpen(!isMenuOpen)
                : () => dispatch(openLoginModal())
            }
          >
            <div className="usermenubutton__imgcontainer padding_0">
              {<UserAvatar />}
              {!isMenuOpen && user.login && (
                <NotificationMessage title={latestMessage} />
              )}
            </div>

            {user.login && isMenuOpen ? (
              <UserMenu
                listHousing={listHousing}
                housingListStatusPending={housingListStatusPending}
                redirect={redirect}
                messagesUnreaded={messagesUnreaded}
                isMenuOpen={isMenuOpen}
                read={messagesUnreaded}
              />
            ) : (
              ""
            )}
          </div>
        </Suspense>
      );
    };
    return (
      <Suspense fallback={<></>}>
        {router.location.pathname === "/" ? (
          <div className="housingListContainer">
            <div className="housingListContainer__container">
              {!loading &&
                Object.values(housingId).map((e) => (
                  <NotificationHousing
                    key={e.housing.id}
                    housingListPending={housingListPending}
                    id={e.housing.id}
                    title={`Tenés una Reserva pendiente`}
                    titleHousing={e.housing.title}
                  />
                ))}
            </div>
          </div>
        ) : (
          ""
        )}

        <nav
          className={`${
            !icon && "bg-white"
          } sm:hidden   w-full py-4 fixed top-0 left-0 z-50 ${
            messages && "bg-white"
          }`}
        >
          <div className="flex items-center justify-between w-[89%] h-full mx-auto">
            <span className="flex gap-1">
              <BackButton />

              {icon && (
                <a href="/">
                  <img
                    loading="eager"
                    width={"160px"}
                    src={aloharOrange}
                    alt="Alohar"
                  />
                </a>
              )}
            </span>
            <div className="flex items-center gap-4">
              <LanguageDropdown
                languages={languages}
                setCurrentLang={setCurrentLang}
                currentLang={currentLang}
              />
              <div
                className="grid cursor-pointer sm:relative place-items-center"
                onClick={
                  !user.login
                    ? () => {
                        router.push("/login");
                      }
                    : () => {
                        setIsMenuOpen(!isMenuOpen);
                      }
                }
              >
                <div className="user_menu__top__avatar ">{<UserAvatar />}</div>
              </div>

              {/* {!isMenuOpen && user.login && <NotificationMessage title={read} />} */}
              {isMenuOpen && (
                <UserMenu
                  listHousing={listHousing}
                  redirect={redirect}
                  messagesUnreaded={messagesUnreaded}
                  isMenuOpen={isMenuOpen}
                  read={messagesUnreaded}
                  housingListStatusPending={housingListStatusPending}
                />
              )}
            </div>
          </div>
        </nav>
        <div
          className="hidden navbar sm:flex h-[125px] "
          landing={landing(isLanding)}
          text={text}
          shadow={shadow}
          show={fShowMobile(showMobile)}
        >
          <div className=" navbar__container">
            <Link className={"links"} to="/" rel="noopener noreferrer">
              <img
                alt="logo alohar"
                src={editorial ? BLACK_LOGO_ALOHAR : setLogo(logo, isLanding)}
                className="logo"
              />
            </Link>
            {addSearch ? (
              <div className="navbar__mainSearch">
                {/* <MainInputSearch addSearch={true} /> */}
                <MainInputSearchNew addSearch={true} />
              </div>
            ) : (
              ""
            )}
            <div className="flex items-center justify-center gap-4 ml-4">
              <LanguageDropdown
                languages={languages}
                setCurrentLang={setCurrentLang}
                currentLang={currentLang}
              />
              {hideButtons ? (
                ""
              ) : (
                <div className="menu_navbar w-max">
                  {addSearch
                    ? ""
                    : [
                        hideAlohadorButtons ? (
                          ""
                        ) : (
                          <p
                            key="l1"
                            className={editorial ? "linksBlack" : "links p-4 "}
                            // to="/quiero-ser-alohador"
                            onClick={() => {
                              createHousing();
                            }}
                          >
                            {handleFilterLang(globalJson).serAlohador}
                          </p>
                        ),
                        isLanding || hideSearchButtons ? (
                          ""
                        ) : (
                          <Link
                            rel="noopener noreferrer"
                            key="l2"
                            className="links"
                            to="/search"
                          >
                            Buscar
                          </Link>
                        ),
                      ]}
                  {user.login ? <LoggedButtons /> : <LoggedButtons />}
                </div>
              )}
            </div>
          </div>
        </div>

        {modalState.isOpen ? selectModal(modalState.type) : ""}
      </Suspense>
    );
  }
);

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
})(Navbar);
