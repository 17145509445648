// Librerías
import React, { useEffect, useState } from "react";
import MaskedInput from "react-maskedinput"; // Mask for Inputs without CSS, great plugin
import ErrorMessage from "../Alerts/ErrorAlert";
// Recursos
import "../../assets/styles/ProfileCard/user_profile.sass";
import AxiosAuth from "../../services/requestApi";
import { useHistory } from "react-router-dom";
import icon_point from "../../assets/static/Icons/location-pin.svg";
import FinishedModal from "../Modals/FinishedModal";
import ChangePassword from "./ChangePassword";
import BankAccount from "../UserProfilePage/bank-account";
import useCurrentLanguage from "../../services/currentlenguaje";
import userEditTrad from "./tradUsers.json"



const UserEditComponent = ({ password, showBankAccount, closeView }) => {
  const { handleFilterLang } = useCurrentLanguage();

  const [userData, setUserData] = useState(false);
  const [about, setAbout] = useState("");
  const [locationStr, setLocationStr] = useState("");
  const [languageStr, setLanguageStr] = useState("");
  const [join, setJoin] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthday, setBirthday] = useState("");
  const [dni, setDni] = useState("");
  const [modal, setModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [showError, setShowError] = useState({});
  const history = useHistory();

  const getUserData = () => {
    const url = `/user/myProfile`;
    AxiosAuth.get(url)
      .then((response) => {
        const {
          birthDay,
          locationStr,
          languageStr,
          dni,
          phoneNumber,
          firstName,
          about,
        } = response.data;
        setUserData(response.data);

        const date = new Date(response.data.issued);
        let _birthDay = birthDay;
        if (birthDay) {
          const birthDate = new Date(response.data.birthDay);
          _birthDay = `${birthDate.getFullYear()}-${
            birthDate.getMonth() + 1
          }-${birthDate.getDay()}`;
        }

        setAbout(about);
        setBirthday(birthDay);
        setJoin(date.getFullYear());
        setLocationStr(locationStr);
        setLanguageStr(languageStr);
        setDni(dni === "0" ? "" : dni);
        setPhoneNumber(parseInt(phoneNumber));
        setFirstName(firstName);

        const data = {
          birthDate: _birthDay,
          dni: dni === "0" ? "" : dni,
          about: about,
          phoneNumber: phoneNumber,
          locationStr: locationStr,
          languageStr: languageStr,
        };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendData = () => {
    const url = `/user/profile`;
    const data = {
      birthDay: birthday,
      dni: dni,
      about: about,
      phoneNumber: String(phoneNumber),
      locationStr: locationStr,
      languageStr: languageStr,
      prefixPhoneNumber: "+54",
    };

    AxiosAuth.put(url, data)
      .then((response) => {
        history.push("/profile");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!userData) {
      getUserData();
    }
  });

  const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{1,2}-\d{1,2}$/;
    if (!dateString.match(regEx)) return false; // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    if (d.getFullYear() < 1900) return false;
    return true;
    //return d.toISOString().slice(0,10) === dateString;
  };

  const validateData = () => {
    const errors = {};
    if (about.length < 5) errors["about"] = 
          handleFilterLang(userEditTrad).infoUsers
    
          ;
    if (locationStr.length < 5)
      errors["locationStr"] =
    handleFilterLang(userEditTrad).errorLocalidad

        ;
    if (languageStr.length < 5)
      errors["languageStr"] = 
    handleFilterLang(userEditTrad).errorIdioma;
    if (dni.length < 7 || dni.length > 10)
      errors["dni"] = 
    handleFilterLang(userEditTrad).errorDNI;
    
    if (!isValidDate(birthday))
      errors["birthday"] = 
    handleFilterLang(userEditTrad).errorFechaNac;
    
    if (phoneNumber.length <= 10)
      errors["phoneNumber"] =
    handleFilterLang(userEditTrad).errorCelular;

       
    Object.keys(errors).length === 0 && setShowError({})
    Object.keys(errors).length === 0 ? sendData() : setShowError(errors);
    Object.keys(errors).length === 0 ? setModal(true) : setModal(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === "about") setAbout(value);
    if (name === "locationStr") setLocationStr(value);
    if (name === "languageStr") setLanguageStr(value);
    if (name === "dni") setDni(value);
    if (name === "birthday") setBirthday(value);
    if (name === "phoneNumber") setPhoneNumber(value);
  };

  const handleSubmit = () => {
    validateData();
  };

  return (
    <div className="userInfo">
      <div className={modal ? " userInfo__modal" : "userInfo__modalNone "}>
        <FinishedModal
          closeModal={modal}
          setCloseModal={() => setModal(false)}
          link={"/profile/housing"}
          title={handleFilterLang(userEditTrad).perfilCompleto} 
          buttonTitle={handleFilterLang(userEditTrad).volver} 
        />
      </div>
      <div className="userInfo__container">
        {showBankAccount || password ? (
          ""
        ) : (
          <>
            <div className="userInfo__container__header">
              <p className="userInfo__container__header__init">
               
              {handleFilterLang(userEditTrad).seUnioEn} 
              {join}
              </p>
              <p className="userInfo__container__header__title">{firstName}</p>
            </div>
            <div className="userInfo__container__info">
              <div className="userInfo__container__info__contentTextarea">
                <label
                  htmlFor="about_you"
                  className="userInfo__container__info__contentTextarea__title"
                >
              {handleFilterLang(userEditTrad).acercaDeMi} 

                
                </label>
                <textarea
                  className="userInfo__container__info__contentTextarea__textarea"
                  name="about"
                  id="about"
                  placeholder=
              {handleFilterLang(userEditTrad).sobreVos}
                  
              
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={about}
                ></textarea>
                <span className="userInfo__container__info__contentTextarea__limit">
                  {about === null ? 0 : about.length} de 100
                </span>
              </div>

              {showError["about"] ? (
                <div className="userInfo__container__info__error">
                  {" "}
                  <ErrorMessage message={showError["about"]} />{" "}
                </div>
              ) : (
                ""
              )}

              <div className="userInfo__container__info__content">
                <label
                  htmlFor="location"
                  className="userInfo__container__info__content__title"
                >
              {handleFilterLang(userEditTrad).ubicacion}

                  
                </label>
                <img
                  className="userInfo__container__info__content__icon"
                  alt="Ubication"
                  src={icon_point}
                />
                <input
                  type="text"
                  name="locationStr"
                  id="locationStr"
                  className="userInfo__container__info__content__description"
                  placeholder={handleFilterLang(userEditTrad).ubicacion2}

                  onChange={handleChange}
                  onBlur={handleChange}
                  value={locationStr}
                />
              </div>
              {showError["locationStr"] ? (
                <div className="userInfo__container__info__content__error">
                  <ErrorMessage message={showError["locationStr"]} />
                </div>
              ) : (
                ""
              )}

              <div className="userInfo__container__info__content">
                <label
                  htmlFor="languages"
                  className="userInfo__container__info__content__title"
                >
                  {handleFilterLang(userEditTrad).idioma}
                  
                </label>
                {/* <img className='userInfo__container__info__content__icon' alt="Languages" src={icon_idiomas}/> */}
                <input
                  type="text"
                  name="languageStr"
                  id="languageStr"
                  className="userInfo__container__info__content__description"
                  placeholder=
                  {handleFilterLang(userEditTrad).ejemploIdioma}
                  
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={languageStr}
                />
              </div>
              {showError["languageStr"] ? (
                <div className="userInfo__container__info__content__error">
                  <ErrorMessage message={showError["languageStr"]} />{" "}
                </div>
              ) : (
                ""
              )}
              <div className="userInfo__container__info__content">
                <label
                  htmlFor="birthday"
                  className="userInfo__container__info__content__title"
                >
                  {handleFilterLang(userEditTrad).fechaNac}

               
                </label>
                <input
                  type="date"
                  name="birthday"
                  id="birthday"
                  className="userInfo__container__info__content__description"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={birthday}
                />
              </div>
              {showError["birthDay"] ? (
                <div className="userInfo__container__info__content__error">
                  <ErrorMessage message={showError["birthDay"]} />{" "}
                </div>
              ) : (
                ""
              )}

              <div className="userInfo__container__info__content">
                <label
                  htmlFor="cellphone"
                  className="userInfo__container__info__content__title"
                >
                  {handleFilterLang(userEditTrad).celular}

                  
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  className="userInfo__container__info__content__description"
                  placeholder={`${handleFilterLang(userEditTrad).ejemplo}: +54 1 223 999 9999`}
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={phoneNumber}
                />
              </div>
              {showError["phoneNumber"] && (
                <div className="userInfo__container__info__content__error">
                  <ErrorMessage message={showError["phoneNumber"]} />
                </div>
              )}
              <div className="userInfo__container__info__content">
                <label
                  htmlFor="location"
                  className="userInfo__container__info__content__title"
                >
                  {handleFilterLang(userEditTrad).dni}
                 
                </label>
                <MaskedInput
                  type="text"
                  mask="11.111.111"
                  name="dni"
                  id="dni"
                  className="userInfo__container__info__content__description"
                  placeholder="Ej: 68.002.489"
                  onChange={handleChange}
                  onBlur={handleChange}
                  value={dni}
                />
              </div>
              {showError["dni"] ? (
                <div className="userInfo__container__info__content__error">
                  <ErrorMessage message={showError["dni"]} />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}

        {showBankAccount && <BankAccount buttonBack={() => closeView()} />}
        {password && <ChangePassword buttonBack={() => closeView()} />}
      </div>
      {showBankAccount || password ? (
        ""
      ) : (
        <button className="userInfo__btn" onClick={handleSubmit}>
          <p className="userInfo__btn__text">
          {handleFilterLang(userEditTrad).guardar}
          
          </p>
        </button>
      )}

      {/* <button className="UserEditComponent__btn__outline" onClick={()=>{history.push("../")}}>
                        <p className="UserEditComponent__btn__outline__text">Volver</p>
                    </button> */}

      {/* <div className="userInfo__container"> */}

      {/* </div> */}
    </div>
  );
};

export default UserEditComponent;
