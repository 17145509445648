// Librerías
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import * as moment from "moment";
import axios from "axios";
import queryString from "query-string";
import { BASE_URL, parseMoney } from "../../../Helpers";
import {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
  openContactAlohadorModal,
  openReportModal,
} from "../../../store/modal/actions";
// Componente
import Search from "./Search";
import ModalWrapper from "../../Modals/ModalWrapper";
import MessengerModal from "../../Modals/MessengerModal";
import ReportModal from "../../Modals/ReportModal";
// Recursos
import "../../../assets/styles/PropertyPage/Utils/_book.sass";
import previajeLegal from "../../../assets/static/previaje.pdf";
import ButtonPrimary from "../../ui/button-primary";
import { restart } from "../../../store/aloharForm/actions";
import useCurrentLanguaje from "../../../services/currentlenguaje";
import jsonBook from "../tradPropertyPage.json";
import jsonGlobal from "../../../utilities/traduccGlobal.json";


const Book = ({ housing }) => {

const { handleFilterLang } = useCurrentLanguaje();

  const modalState = useSelector((state) => state.modalReducer, shallowEqual);
  const {
    basePrice,
    id,
    user,
    maxNight,
    minNight,
    guests,
    title,
    typeHousingReservation,
    timeAdvanceBook,
  } = housing;

  const userId = user.id;
  const firstName = user.firstName;
  const [loading, setLoading] = useState(false);
  const searchState = useSelector((state) => state.searchReducer, shallowEqual);
  const userState = useSelector((state) => state.userReducer, shallowEqual);
  const [price, setPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [realPrice, setRealPrice] = useState(0);
  const [realBasePrice, setRealBasePrice] = useState(0);
  const [days, setDays] = useState(0);
  const [error, setError] = useState("");
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [previaje, setPreviaje] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const dispatch = useDispatch();
  const [messageSistem, setMessageSistem] = useState(false);
  const [datePrice, setDatePrice] = useState();
  let { adults, checkin, checkout, childs } = searchState;
  const errors = {
    "Reservation Exceeds max number of guests":
    handleFilterLang(jsonBook).errorMaximoPersonas
    + guests,
    "Checkin date is minor than checkout date":
    handleFilterLang(jsonBook).errorFechaCheckin,
    "Reservation Exceeds max nights for housing":
    handleFilterLang(jsonBook).errorMaximoNoches +
      maxNight,
    "checkin or checkout are in the past": handleFilterLang(jsonBook).errorFechaInMayor,
    "Reservation below min nights for housing":
    handleFilterLang(jsonBook).errorMinimoNoches + minNight,
    "Housing is not available for the current dates":
    handleFilterLang(jsonBook).errorAlohamientoNoDisponible,
  };

  const formatDate = (dateCalendar) => {
    const arrayDate = dateCalendar.split("/");
    return arrayDate.length === 3
      ? arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0]
      : false;
  };

  const formatDates = (dateCalendar) => {
    const arrayDate =
      dateCalendar === undefined ? false : dateCalendar.split("/");
    return arrayDate.length === 3 ? arrayDate[0] : false;
  };
  const loadUrlParameters = () => {
    const query = queryString.parse(window.location.search);
    const guests =
      query.adults && query.childs
        ? parseInt(query.adults) + parseInt(query.childs)
        : false;
    const bookingStart = query.checkin ? formatDate(query.checkin) : false;
    const bookingEnd = query.checkout ? formatDate(query.checkout) : false;
    const city = query.city ? query.city : false;
  };
  const calcRealBasePrice = (r) => {
    let start = moment(checkin, "D/M/YY");
    let end = moment(checkout, "D/M/YY");
    let duration = moment.duration(end.diff(start));
    let days = duration.asDays();
    if (days) {
      setRealBasePrice(Math.floor(truncar(r / days, 2)));
      setDays(days);
    }
  };
  const selectModal = (modalType) => {
    // if(!userState.login){
    //   return <ModalWrapper key="login_modal"><LoginModal/></ModalWrapper>
    // }
    switch (modalType) {
      case "CONTACT_ALOHADOR":
        return (
          <ModalWrapper key="contacto_modal">
            <MessengerModal
              firstName={firstName}
              housingId={id}
              userId={userId}
              checkin={checkin}
              checkout={checkout}
              messageSistem={true}
            />
          </ModalWrapper>
        );
      case "REPORT":
        return (
          <ModalWrapper key="report_modal">
            <ReportModal housingId={id} />
          </ModalWrapper>
        );
      default:
        return "";
    }
  };

  const truncar = (num, digitos) => {
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);
    return _num ? _num[1] : num;
  };

  const handleActionModal = (modal) => {
    if (!userState.login) {
      dispatch(openLoginModal());
    } else {
      if (modal === "sistem") {
        setMessageSistem(true);
        dispatch(openContactAlohadorModal("sistem"));
      }
      if (modal === "contact") dispatch(openContactAlohadorModal("sistem"));
      if (modal === "report") dispatch(openReportModal());
    }
  };

  const clearPrice = () => {
    setServicePrice(0);
    setPrice(0);
    setCleanPrice(0);
    setWeeklyDiscount(0);
    setMonthlyDiscount(0);
    setRealPrice(0);
    setTotalPrice(0);
    setDays(0);
    setRealBasePrice(0);
    setTaxes(0);
    setPreviaje(0);
  };

  const quote = async () => {
    const url = BASE_URL + `/housing/${id}/reservations/cost`;

    const bodyParameters = {
      id: id,
      checkin: moment(checkin, "D/M/YY").format("YYYY-MM-DD"),
      checkout: moment(checkout, "D/M/YY").format("YYYY-MM-DD"),
      guests: adults + childs,
    };

    clearPrice();

    await axios
      .post(url, bodyParameters)
      .then((data) => {
        const prices = data.data.prices;
        prices.forEach((p) => {
          if (p.priceType === "SERVICE_FEE")
            setServicePrice(truncar(p.total, 2));
          if (p.priceType === "BASE_PRICE") {
            setPrice(truncar(p.total, 2));
            calcRealBasePrice(truncar(p.total, 2));
          }
          if (p.priceType === "CLEANING_PRICE")
            setCleanPrice(truncar(p.total, 2));
          if (p.priceType === "WEEKLY_DISCOUNT")
            setWeeklyDiscount(truncar(p.total, 2));
          if (p.priceType === "MONTHLY_DISCOUNT")
            setMonthlyDiscount(truncar(p.total, 2));
        });
        setPreviaje(truncar(data.data.previajeAmount, 2));
        setTaxes(truncar(data.data.taxes, 2));
        setRealPrice(data.data.totalPrice);
        setTotalPrice(truncar(data.data.totalPrice, 2));
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 400) {
          setError(errors[e.response.data.message]);
          clearPrice();
        }
        if (e.response.status === 500) {
          setError(0);
        }
      })
      .then(() => {
        setLoading(false);
      });
  };
  const formatDateMonth = (format) => {
    const arrayDate = format === undefined ? false : format.split("/");
    return arrayDate.length === 3 ? arrayDate[1] : false;
  };

  useEffect(() => {
    if (checkout !== "" && checkin !== "") {
      setLoading(true);
      clearPrice();
      setError("");
      quote();
    }
    if (formatDateMonth(checkin) !== formatDateMonth(checkout)) {
      let firstDate = moment(checkin, "D/M/YY");
      let secondDate = moment(checkout, "D/M/YY");
      let milliseconds = 24 * 60 * 60 * 1000;
      let millisecondsDays = Math.abs(firstDate - secondDate);
      let elapsedMilliseconds = Math.round(millisecondsDays / milliseconds);
      setDatePrice(elapsedMilliseconds);
    } else {
      setDatePrice(formatDates(checkout) - formatDates(checkin));
    }
  }, [checkout, checkin, adults, childs]);
  useEffect(() => {
    restart();
  }, []);
  const validFrom =
    housing.housingPromotions.length > 0 &&
    moment(housing.housingPromotions[0].valid_from);
  const validTo =
    housing.housingPromotions.length > 0 &&
    moment(housing.housingPromotions[0].valid_to);
  const dateToday = moment();

  return (
    <React.Fragment>
      <div className="Book">
        <h3 className="Book__title">
          <strong>
            ARS $
            {realBasePrice !== 0 && days !== 0
              ? realBasePrice
                  .toString()
                  .split(/(?=(?:\d{3})+(?:,|$))/g)
                  .join(".")
              : basePrice
                  .toString()
                  .split(/(?=(?:\d{3})+(?:,|$))/g)
                  .join(".")}
          </strong>
          /{handleFilterLang(jsonGlobal).noche}

        </h3>
        <Search
          quote={totalPrice}
          housingId={id}
          servicePrice={servicePrice}
          price={price}
          cleanPrice={cleanPrice}
          totalPrice={totalPrice}
          realPrice={realPrice}
          monthlyDiscount={monthlyDiscount}
          weeklyDiscount={weeklyDiscount}
          loading={loading}
          title={title}
          typeHousingReservation={typeHousingReservation}
          timeAdvanceBook={timeAdvanceBook}
          clearPrice={clearPrice}
          previaje={previaje}
          taxes={taxes}
          error={error}
          idReceptor={user.id}
          handleActionModal={handleActionModal}
          housingPromotions={
            housing.housingPromotions.length > 0 && housing.housingPromotions[0]
          }
        />
        <p className="Book__desc">
          {handleFilterLang(jsonBook).noCargos}
        </p>
        <ul className="Book__list">
          <li className="Book__list__item">
            <p className="Book__list__item__desc"> {handleFilterLang(jsonGlobal).minimoDeNoches}</p>
            <p className="Book__list__item__desc">{minNight}</p>
          </li>
          <li className="Book__list__item">
            <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).maximoDeNoches}</p>
            <p className="Book__list__item__desc">{maxNight}</p>
          </li>
          <li className="Book__list__item">
            <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).precioPor} {days} {handleFilterLang(jsonGlobal).noches}</p>
            <p className="Book__list__item__amount">${parseMoney(price)}</p>
          </li>
          <li className="Book__list__item">
            <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).tarifaLimpieza}</p>
            <p className="Book__list__item__amount">
              ${parseMoney(cleanPrice)}
            </p>
          </li>
          <li className="Book__list__item">
            <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).tarifaServicio}</p>
            <p className="Book__list__item__amount">
              ${parseMoney(servicePrice)}
            </p>
          </li>
          {taxes > 0 ? (
            <li className="Book__list__item">
              <p className="Book__list__item__desc">
              {handleFilterLang(jsonGlobal).impuestosTasas}
              </p>
              <p className="Book__list__item__amount">${parseMoney(taxes)}</p>
            </li>
          ) : (
            ""
          )}
          {weeklyDiscount > 0 ? (
            <li className="Book__list__item">
              <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).descuentoSemanal}
</p>
              <p className="Book__list__item__amount">
                -${parseMoney(weeklyDiscount)}
              </p>
            </li>
          ) : (
            ""
          )}
          {monthlyDiscount > 0 ? (
            <li className="Book__list__item">
              <p className="Book__list__item__desc">D{handleFilterLang(jsonGlobal).descuentoMensual}</p>
              <p className="Book__list__item__amount">
                -${parseMoney(monthlyDiscount)}
              </p>
            </li>
          ) : (
            ""
          )}
          <li className="Book__list__item">
            <p className="Book__list__item__desc">{handleFilterLang(jsonGlobal).total}</p>
            <p className="Book__list__item__amount">
              ${parseMoney(totalPrice)}
            </p>
          </li>
          {error.length > 0 && (
            <li className="Book__list__item">
              <p className="Book__list__item__amount">{handleFilterLang(jsonGlobal).error}: {error}</p>
            </li>
          )}
        </ul>
        {previaje > 0 ? (
          <div className="Book__list">
            <div className="Book__list__item">
              <p className="Book__list__item__desc">Crédito PREVIAJE:</p>
              <p className="Book__list__item__amount">
                ${parseMoney(previaje)}
              </p>
            </div>
            <div className="Book__list__item">
              <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                * {handleFilterLang(jsonBook).leerTyC}{" "}
                <a
                  href={previajeLegal}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {handleFilterLang(jsonGlobal).terminosBasesCondiciones}
                </a>
                .
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="BookMobile">
        <div className="BookMobile__container">
          <div className="BookMobile__container__pricecontainer">
            {totalPrice === 0 ? (
              <p className="SmallCard__infocontainer__price__amount__pernight">
                "(*){handleFilterLang(jsonBook).ingreseFecha} <br />
                {handleFilterLang(jsonBook).conocerPrecio}"
              </p>
            ) : (
              <>
                <p className="BookMobile__container__pricecontainer__price">
                  AR$ {parseMoney(totalPrice)}
                </p>
                <p className="SmallCard__infocontainer__price__amount__pernight">
                {handleFilterLang(jsonBook).precioTotalPor} {datePrice} {handleFilterLang(jsonGlobal).noches}
                </p>
              </>
            )}
          </div>
          {error ? (
            <ButtonPrimary
              width={"100%"}
              title={"Consultar disponibilidad"}
              onClick={() => {
                handleActionModal("sistem");
              }}
            />
          ) : (
            <Link
              className="BookMobile__container__cta"
              to={`/intermediate-step/${id}?typeHousingReservation=${typeHousingReservation}&idReceptor=${user.id}`}
            >
              {handleFilterLang(jsonGlobal).reservar}
            </Link>
          )}
        </div>
      </div>
      <br />
      {modalState.isOpen ? (
        selectModal(modalState.type)
      ) : (
        <div className="Book__ctas">
          <ButtonPrimary
          secondaryColor={true}
            width={"100%"}
            title={handleFilterLang(jsonGlobal).contactarAlAlohador}
            onClick={() => {
              handleActionModal("sistem");
            }}
          />

          <button
            className="Book__cta__small"
            onClick={() => {
              handleActionModal("report");
            }}
          >
            {handleFilterLang(jsonBook).denunciarAnuncio}
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(null, {
  openLoginModal,
  openRegisterModal,
  openRecoverModal,
  openReportModal,
  restart,
})(Book);
