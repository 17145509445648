// Librerías
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { StepTitle } from "semantic-ui-react";
// Componentes
// Recursos
import "../../assets/styles/FAQ/_faq.sass"
import terms from '../../assets/static/legal.pdf';
import privacy from '../../assets/static/privacidad.pdf';
import Accordion from "../Utils/Accordion";
import SideMenu from "../Utils/SideMenu";
import {
    mostCommonQuestionsEs,
    mostCommonQuestionsEn,
    mostCommonQuestionsPo
} from "./faqComponentLang";
import useCurrentLanguage from "../../services/currentlenguaje";
import jsonFAQ from "./tradFAQ.json";


const FAQComponentMobile = ({ lenguaje }) => {
    const [questionsType, setQuestionsType] = useState("");
    const [title, setTitle] = useState("");
    const [jsonCommonQuestions, setjsonCommonQuestions] = useState("mostCommonJsonEs");

    const { handleFilterLang } = useCurrentLanguage();


    useEffect(() => {
        if (lenguaje === "es") {
            setjsonCommonQuestions(mostCommonQuestionsEs);
        }
        if (lenguaje === "en") {
            setjsonCommonQuestions(mostCommonQuestionsEn);
        }
        if (lenguaje === "po") {
            setjsonCommonQuestions(mostCommonQuestionsPo);
        }
    }, [lenguaje]);






    const questions = Array.isArray(jsonCommonQuestions)
        ? jsonCommonQuestions.filter(
            (mostCommonQuestions) => mostCommonQuestions.questionsType === questionsType
        )
        : [];
    return (
        <React.Fragment>
            <div className="FAQContainer">
                <div className='FAQContainer__sideBar'>
                    {/* <SideMenu /> */}
                </div>
                <div className="FAQ">
                    <div className="FAQ__menu">
                        <p className={`FAQ__menu__question ${questionsType === "covid" ? "active" : ""}`} onClick={() => { setQuestionsType("covid") }}>
                            {handleFilterLang(jsonFAQ).covid}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "register" ? "active" : ""}`} onClick={() => { setQuestionsType("register") }}>
                            {handleFilterLang(jsonFAQ).registro}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "login" ? "active" : ""}`} onClick={() => { setQuestionsType("login") }}>
                            {handleFilterLang(jsonFAQ).inicioVerificacion}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "password" ? "active" : ""}`} onClick={() => { setQuestionsType("password") }}>
                            {handleFilterLang(jsonFAQ).contraseña}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "profile-configuration" ? "active" : ""}`} onClick={() => { setQuestionsType("profile-configuration") }}>
                            {handleFilterLang(jsonFAQ).configuracionPerfil}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "housing-configuration" ? "active" : ""}`} onClick={() => { setQuestionsType("housing-configuration") }}>
                            {handleFilterLang(jsonFAQ).configuracionAlohamiento}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "bookings" ? "active" : ""}`} onClick={() => { setQuestionsType("bookings") }}>
                            {handleFilterLang(jsonFAQ).reservas}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "bookings-comms" ? "active" : ""}`} onClick={() => { setQuestionsType("bookings-comms") }}>
                            {handleFilterLang(jsonFAQ).comunicacionReservas}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "cancelled" ? "active" : ""}`} onClick={() => { setQuestionsType("cancelled") }}>
                            {handleFilterLang(jsonFAQ).cancelaciones}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "taxes" ? "active" : ""}`} onClick={() => { setQuestionsType("taxes") }}>
                            {handleFilterLang(jsonFAQ).impositivo}

                        </p>
                        <p className={`FAQ__menu__question ${questionsType === "accountabilities" ? "active" : ""}`} onClick={() => { setQuestionsType("accountabilities") }}>
                            {handleFilterLang(jsonFAQ).responsabilidades}

                        </p>
                    </div>
                    <div className="FAQ__content">
                        {
                            questions.filter((r) => {
                                if (questionsType === "covid") return r.questionsType === "covid"
                                if (questionsType === "register") return r.questionsType === "register"
                                if (questionsType === "login") return r.questionsType === "login"
                                if (questionsType === "password") return r.questionsType === "password"
                                if (questionsType === "profile-configuration") return r.questionsType === "profile-configuration"
                                if (questionsType === "housing-configuration") return r.questionsType === "housing-configuration"
                                if (questionsType === "bookings") return r.questionsType === "bookings"
                                if (questionsType === "bookings-comms") return r.questionsType === "bookings-comms"
                                if (questionsType === "cancelled") return r.questionsType === "cancelled"
                                if (questionsType === "taxes") return r.questionsType === "taxes"
                                if (questionsType === "accountabilities") return r.questionsType === "accountabilities"
                            }).map(e => (
                                <Accordion
                                    key={e.id}
                                    title={e.question}
                                    content={e.answer}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="FAQMobile">
                <h2 className="FAQMobile__title">
                    <p className={`FAQMobile__title__btn${(title !== "") ? " active" : ""}`}
                        onClick={() => {
                            setTitle("")
                            setQuestionsType("")
                            window.scrollTo(0, 0)
                        }}>
                    </p>
                    {/* <p className="FAQMobile__title__title">
                        {(questionsType !== "") ? title : "Centro de Ayuda"}
                    </p> */}
                </h2>
                <div className={`FAQMobile__menu${(title !== "") ? "" : " active"}`}>
                    <p className={`FAQMobile__menu__question ${questionsType === "covid" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("COVID")
                        setQuestionsType("covid")
                    }}>
                        {handleFilterLang(jsonFAQ).covid}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "register" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Registro")
                        setQuestionsType("register")
                    }}>
                        {handleFilterLang(jsonFAQ).registro}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "login" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Inicio y Verificación")
                        setQuestionsType("login")
                    }}>
                        {handleFilterLang(jsonFAQ).inicioVerificacion}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "password" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Contraseña")
                        setQuestionsType("password")
                    }}>
                        {handleFilterLang(jsonFAQ).contraseña}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "profile-configuration" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Configuración del Perfil")
                        setQuestionsType("profile-configuration")
                    }}>
                        {handleFilterLang(jsonFAQ).configuracionPerfil}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "housing-configuration" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Configuración del Alohamiento")
                        setQuestionsType("housing-configuration")
                    }}>
                        {handleFilterLang(jsonFAQ).configuracionAlohamiento}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "bookings" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Reservas")
                        setQuestionsType("bookings")
                    }}>
                        {handleFilterLang(jsonFAQ).reservas}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "bookings-comms" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Comunicación Reservas")
                        setQuestionsType("bookings-comms")
                    }}>
                        {handleFilterLang(jsonFAQ).comunicacionReservas}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "cancelled" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Cancelaciones")
                        setQuestionsType("cancelled")
                    }}>
                        {handleFilterLang(jsonFAQ).cancelaciones}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "taxes" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Impositivo")
                        setQuestionsType("taxes")
                    }}>
                        {handleFilterLang(jsonFAQ).impositivo}

                    </p>
                    <p className={`FAQMobile__menu__question ${questionsType === "accountabilities" ? "active" : ""}`} onClick={() => {
                        window.scrollTo(0, 0)
                        setTitle("Responsabilidades")
                        setQuestionsType("accountabilities")
                    }}>
                        {handleFilterLang(jsonFAQ).responsabilidades}

                    </p>
                </div>
                <div className="FAQMobile__content">
                    {
                        questions.filter((r) => {
                            if (questionsType === "covid") return r.questionsType === "covid"
                            if (questionsType === "register") return r.questionsType === "register"
                            if (questionsType === "login") return r.questionsType === "login"
                            if (questionsType === "password") return r.questionsType === "password"
                            if (questionsType === "profile-configuration") return r.questionsType === "profile-configuration"
                            if (questionsType === "housing-configuration") return r.questionsType === "housing-configuration"
                            if (questionsType === "bookings") return r.questionsType === "bookings"
                            if (questionsType === "bookings-comms") return r.questionsType === "bookings-comms"
                            if (questionsType === "cancelled") return r.questionsType === "cancelled"
                            if (questionsType === "taxes") return r.questionsType === "taxes"
                            if (questionsType === "accountabilities") return r.questionsType === "accountabilities"
                        }).map(e => (
                            <Accordion
                                key={e.id}
                                title={e.question}
                                content={e.answer}
                            />
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default FAQComponentMobile;