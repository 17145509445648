// Librerías
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AxiosAuth from "../../services/requestApi";
import queryString from "query-string";
// Componentes
import { Helmet } from "react-helmet";
import ConfirmedMap from "./Utils/ConfirmedMap";
// Recursos
import "../../assets/styles/Confirmed/__confirmed.sass";
import previajeLegal from "../../assets/static/previaje.pdf";
import { cancelationsEs, cancelationsEn, cancelationsPo } from "../Utils/CancelationPolicy";
import loadingGif from "../../assets/static/loading.gif";
import moment from "moment";
import { parseMoney } from "../../Helpers";
import SuccessReservation from "../../containers/landings/success-reservation-googleTag";
import { useLanguage } from "../../store/lenguaje/useLenguaje"
moment.locale("es");

const CheckoutComponentMobile = () => {

  const { currentLang } = useLanguage();

  const cancelations = currentLang === "es" ? cancelationsEs 
  : currentLang === "en" ? cancelationsEn
  : currentLang === "po" ? cancelationsPo
  : cancelationsEs

  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [reservation, setReservation] = useState();
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [rules, setRules] = useState([]);
  const [price, setPrice] = useState(0);
  const [cleanPrice, setCleanPrice] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  const [weeklyDiscount, setWeeklyDiscount] = useState(0);
  const [previaje, setPreviaje] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [email, setEmail] = useState();
  const [housingTransfer, setHousingTransfer] = useState();
  const query = queryString.parse(window.location.search);
  const { from } = query;
  const timestamp = new Date().getUTCMilliseconds();

  const { id } = useParams();
  const url = "reservation/" + id;
  const transferReservation = JSON.parse(
    localStorage.getItem("infoReservation")
  );
  useEffect(() => {
    setHousingTransfer(transferReservation);
    if (!init) {
      setInit(true);
      AxiosAuth.get(url)
        .then((response) => {
          setReservation(response.data);
          const ci = moment(response.data.checkin).utc(true);
          setCheckin(ci.format("dddd D MMMM"));
          const co = moment(response.data.checkout).utc(true);
          setCheckout(co.format("dddd D MMMM"));
          const _rules = response.data.housing.housingRule.map((r) => r.rule);
          setRules(_rules);
          setTotalPrice(truncar(response.data.totalPrice, 2));
          const prices = response.data.prices;
          setEmail(reservation.email);
          prices.forEach((p) => {
            if (p.priceType === "SERVICE_FEE")
              setServicePrice(truncar(p.total, 2));
            if (p.priceType === "BASE_PRICE") setPrice(truncar(p.total, 2));
            if (p.priceType === "CLEANING_PRICE")
              setCleanPrice(truncar(p.total, 2));
            if (p.priceType === "WEEKLY_DISCOUNT")
              setWeeklyDiscount(truncar(p.total, 2));
            if (p.priceType === "MONTHLY_DISCOUNT")
              setMonthlyDiscount(truncar(p.total, 2));
          });
          setPreviaje(truncar(response.data.previajeAmount, 2));
          setTaxes(truncar(response.data.taxes, 2));
        })
        .catch((e) => {
        })
        .then(() => {
          setLoading(false);
        });
    }
  }, []);

  const detailStyle = () => {
    if (reservation && reservation.status === "pending") {
      return {
        height: `70%`,
      };
    } else {
      return {
        height: `auto`,
      };
    }
  };

  const truncar = (num, digitos) => {
    if (num.toString().length < 3) return num;
    const re = new RegExp("(\\d+\\.\\d{" + digitos + "})(\\d)");
    const _num = num.toString().match(re);

    return _num ? _num[1] : num;
  };
  return (
    <>
      <SuccessReservation />

      <div className="confirmedContainer">
        <div className="Confirmed">
          {reservation && from === "checkout" ? (
            // reservation && reservation.status === "confirmed" && from === "checkout"?
            <Helmet>
              <script>
                {`
              gtag('event', 'conversion', {
                  'send_to': 'AW-453872768/e0cNCOvIj_EBEICZttgB',
                  'value':${totalPrice},
                  'currency': 'ARS',
                  'transaction_id': ${timestamp}
              });

              fbq('track', 'Purchase', {currency: "ARS", value: ${totalPrice}});
            `}
              </script>
            </Helmet>
          ) : (
            ""
          )}

          {loading ? (
            <img style={{ margin: "auto" }} src={loadingGif} />
          ) : (
            <>
              <div className="Confirmed__container">
                <h2 className="Confirmed__container__title">
                  {reservation && reservation.status === "pending"
                    ? "Falta que el alohador confirme tu reserva"
                    : ""}
                  {reservation && reservation.status === "confirmed" ? (
                    <>
                      ¡Te vas de viaje a
                      {reservation ? reservation.housing.location.location : ""}
                      !
                    </>
                  ) : (
                    ""
                  )}
                </h2>
                <p className="Confirmed__container__text"></p>
                <p className="Confirmed__container__text">
                  Te enviamos un correo electrónico con la información de la
                  reservasrfsdf
                  <b>{housingTransfer ? housingTransfer.email : email}</b>.
                </p>
                {housingTransfer && (
                  <p className="Confirmed__container__text">
                    Podés encontrar la información de todas tus reservas en la
                    sección
                    <Link
                      to="/profile/travels"
                      className="Confirmed__container__link"
                    >
                      Mis Reservas
                    </Link>
                  </p>
                )}

                <div className="Confirmed__container__separator"></div>
                <div className="Confirmed__container__details">
                  <div className="Confirmed__container__details__rules">
                    <h3 className="Confirmed__container__details__rules__title">
                      {housingTransfer
                        ? "Normas de la casa"
                        : "Informacion de reserva"}
                    </h3>
                    <ul className="Confirmed__container__details__rules__list">
                      {housingTransfer && (
                        <>
                          <li className="Confirmed__container__details__rules__list__item">
                            {`Checkin: ${housingTransfer.checkin}`}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {`Check Out: ${housingTransfer.checkout}`}
                          </li>
                        </>
                      )}
                      {reservation && (
                        <>
                          <li className="Confirmed__container__details__rules__list__item">
                            Check-in: De
                            {reservation
                              ? reservation.startCheckInTime.slice(0, -3)
                              : ""}
                            a
                            {reservation
                              ? reservation.endCheckinTime.slice(0, -3)
                              : ""}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            Check-out:
                            {reservation
                              ? reservation.checkoutTime.slice(0, -3)
                              : ""}
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("smooke") ? "Se " : "No se "}permite
                            fumar
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("pets") ? "Se " : "No se "}admiten
                            mascotas
                          </li>
                          <li className="Confirmed__container__details__rules__list__item">
                            {rules.includes("party") ? "Se " : "No se "}permiten
                            fiestas ni eventos
                          </li>
                        </>
                      )}
                      <li className="Confirmed__container__details__rules__list__item">
                        {reservation ? reservation.ruleExtra : ""}
                      </li>
                    </ul>
                  </div>
                  <div className="Confirmed__container__details__policy">
                    <h3 className="Confirmed__container__details__policy__title">
                      Política de Cancelación
                    </h3>
                    <p className="Confirmed__container__details__rules__text">
                      {reservation
                        ? cancelations[reservation.cancellationType]
                        : ""}
                      <i style={{ fontSize: "10px" }}>
                        *Check in: Se contemplan horas y días completas antes de
                        la hora local de llegada del anuncio (indicada en el
                        correo electrónico de confirmación).
                      </i>
                    </p>
                  </div>
                </div>
              </div>
              {/*  && reservation.status === "confirmed"  */}

              {reservation ? (
                <div className="Confirmed__detail" style={detailStyle()}>
                  <h2 className="Confirmed__detail__title">
                    {reservation ? reservation.housing.title : ""}
                  </h2>
                  {reservation.status === "confirmed" ? (
                    <>
                      <p className="Confirmed__detail__location">
                        {reservation ? reservation.housing.adress : ""}{" "}
                        {reservation ? reservation.housing.adressExtra : ""},{" "}
                        {reservation
                          ? reservation.housing.location.location
                          : ""}
                      </p>
                      <div className="Confirmed__detail__map">
                        <ConfirmedMap
                          lat={reservation ? reservation.housing.lat : ""}
                          lng={reservation ? reservation.housing.lng : ""}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="Confirmed__detail__container">
                    <p className="Confirmed__detail__container__dates">
                      Checkin: {checkin}
                    </p>
                    <p className="Confirmed__detail__container__dates">
                      Checkout: {checkout}
                    </p>
                    <p className="Confirmed__detail__container__people">
                      Huéspedes: {reservation.guests}
                    </p>
                  </div>
                  <ul className="Confirmed__detail__list">
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                        Tarifa por alojamiento:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(price)}
                      </p>
                    </li>
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                        Tarifa de limpieza:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(cleanPrice)}
                      </p>
                    </li>
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                        Tarifa por servicio:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(servicePrice)}
                      </p>
                    </li>
                    {taxes > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                          Impuestos, tasas y cargos
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          $ {parseMoney(taxes)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    {weeklyDiscount > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                          Descuento semanal
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          -${parseMoney(weeklyDiscount)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    {monthlyDiscount > 0 ? (
                      <li className="Confirmed__detail__list__item">
                        <p className="Confirmed__detail__list__item__desc">
                          Descuento mensual
                        </p>
                        <p className="Confirmed__detail__list__item__amount">
                          -${parseMoney(monthlyDiscount)}
                        </p>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="Confirmed__detail__list__item">
                      <p className="Confirmed__detail__list__item__desc">
                        Total:
                      </p>
                      <p className="Confirmed__detail__list__item__amount">
                        $ {parseMoney(totalPrice)}
                      </p>
                    </li>
                  </ul>
                  {previaje > 0 ? (
                    <div className="Checkout__detail__list">
                      <div className="Checkout__detail__list__item">
                        <p className="Checkout__detail__list__item__desc">
                          Crédito PREVIAJE:
                        </p>
                        <p className="Checkout__detail__list__item__amount">
                          ${parseMoney(previaje)}
                        </p>
                      </div>
                      <div className="Checkout__detail__list__item">
                        <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                          * Por favor leer los{" "}
                          <a href={previajeLegal} target="_blank">
                            términos, bases y condiciones
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="Confirmed__detail" style={detailStyle()}>
                  {housingTransfer !== null && (
                    <>
                      <h2 className="Confirmed__detail__title">
                        {housingTransfer ? housingTransfer.title : ""}
                      </h2>
                      <div className="Confirmed__detail__container">
                        <p className="Confirmed__detail__container__dates">
                          Checkin: {housingTransfer.checkin}
                        </p>
                        <p className="Confirmed__detail__container__dates">
                          Checkout: {housingTransfer.checkout}
                        </p>
                        <p className="Confirmed__detail__container__people">
                          Huéspedes: {housingTransfer.persons}
                        </p>
                      </div>
                      <ul className="Confirmed__detail__list">
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                            Tarifa por alojamiento:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {parseMoney(housingTransfer.price)}
                          </p>
                        </li>
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                            Tarifa de limpieza:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {parseMoney(housingTransfer.serviceClean)}
                          </p>
                        </li>
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                            Tarifa por servicio:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {parseMoney(housingTransfer.servicePrice)}
                          </p>
                        </li>
                        {weeklyDiscount > 0 ? (
                          <li className="Confirmed__detail__list__item">
                            <p className="Confirmed__detail__list__item__desc">
                              Descuento semanal
                            </p>
                            <p className="Confirmed__detail__list__item__amount">
                              -${parseMoney(housingTransfer.weeklyDiscount)}
                            </p>
                          </li>
                        ) : (
                          ""
                        )}
                        {monthlyDiscount > 0 ? (
                          <li className="Confirmed__detail__list__item">
                            <p className="Confirmed__detail__list__item__desc">
                              Descuento mensual
                            </p>
                            <p className="Confirmed__detail__list__item__amount">
                              -{parseMoney(housingTransfer.monthlyDiscount)}
                            </p>
                          </li>
                        ) : (
                          ""
                        )}
                        <li className="Confirmed__detail__list__item">
                          <p className="Confirmed__detail__list__item__desc">
                            Total:
                          </p>
                          <p className="Confirmed__detail__list__item__amount">
                            {parseMoney(housingTransfer.total)}
                          </p>
                        </li>
                      </ul>
                    </>
                  )}
                  {previaje > 0 ? (
                    <div className="Checkout__detail__list">
                      <div className="Checkout__detail__list__item">
                        <p className="Checkout__detail__list__item__desc">
                          Crédito PREVIAJE:
                        </p>
                        <p className="Checkout__detail__list__item__amount">
                          {parseMoney(previaje)}
                        </p>
                      </div>
                      <div className="Checkout__detail__list__item">
                        <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                          * Por favor leer los{" "}
                          <a href={previajeLegal} target="_blank">
                            términos, bases y condiciones
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutComponentMobile;
