// Librerías
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import * as aloharFormActions from "../../../store/aloharForm/actions";
// Componentes
import ErrorMessage from "../../Alerts/ErrorAlert";
import Buttons from "../Utils/Buttons";
import ProgressBar from "../Utils/ProgressBar";
import ButtonEdit from "../Utils/ButtonEdit";
// Recursos
import "../../../assets/styles/AloharForm/Steps/_title.sass";
import titleJson from "../json/tradAloharForm.json";
/**
 * Formulario:
 * Titulo: housing_title
 * Descripción: housing_description
 */

class Title extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.bodyParameters.title || "",
      description: this.props.bodyParameters.description || "",
      modeEdit: this.props.modeEdit || false,
      errors: {},
      count_validate: this.props.count_validate,
      lenguaje: this.props.lenguaje || "es",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  currentLenguaje = (json) => {
    return json[this.state.lenguaje];
  };

  handleValidate = ({ inputName, showError }) => {
    let errors = {};
    const messengers = {
      title: this.currentLenguaje(titleJson).errorTitulo,
      description: this.currentLenguaje(titleJson).errorDescripcion,
    };
    const validate =
      this.state.title.length > 5 && this.state.description.length > 5;

    if (inputName) {
      if (inputName === "title" && this.state.title.length <= 5)
        errors["title"] = messengers["title"];
      if (inputName === "description" && this.state.description.length <= 5)
        errors["description"] = messengers["description"];
    } else {
      if (this.state.title.length <= 5) errors["title"] = messengers["title"];
      if (this.state.description.length <= 5)
        errors["description"] = messengers["description"];
    }

    if (showError) this.setState({ errors: errors });
    this.props.validateStep(validate);
  };

  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    let errors = {};
    if (nam === "description" && val.length > 1000) {
      errors["description"] =
        this.currentLenguaje(titleJson).errorMaximaLongitud;
      this.setState({ errors: errors });
      return;
    }

    if (nam === "title" && val.length > 50) {
      errors["title"] = this.currentLenguaje(titleJson).errorMaximaLongitud;
      this.setState({ errors: errors });
      return;
    }

    this.setState({ [nam]: val }, () => {
      this.handleValidate({ inputName: nam, showError: true });
      this.props.titleStep(this.state);
    });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.lenguaje !== this.props.lenguaje) {
      this.setState({ lenguaje: this.props.lenguaje });
    }
  }

  render() {
    
    return (
      <React.Fragment>
        <div className="titleResponsive">
          <div className="titleResponsive__Title">
            {this.state.modeEdit ? (
              ""
            ) : (
              <ProgressBar
                title={this.currentLenguaje(titleJson).tituloYDescripcion}
                stepAt={this.currentLenguaje(titleJson).paso3}
              />
            )}
            <div className="titleResponsive__Title__container">
              <h2 className="titleResponsive__Title__title">
                {this.currentLenguaje(titleJson).tituloAlohamiento}
              </h2>
              <p className="titleResponsive__Title__description">
                {this.currentLenguaje(titleJson).tituloAlohamientoAclaracion}
              </p>
              <div className="titleResponsive__Title__inputContainer ">
                <label
                  className="titleResponsive__Title__inputContainer__label"
                  htmlFor="housing_title"
                >
                  {this.currentLenguaje(titleJson).titulo}
                </label>
                <div className="max_width">
                  <input
                    className={
                      this.state.errors["title"]
                        ? "titleResponsive__Title__inputContainer__input errorInput"
                        : "titleResponsive__Title__inputContainer__input"
                    }
                    name="title"
                    id="housing_title"
                    type="text"
                    value={this.state.title}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    autoComplete={false}
                  />
                  <span className="titleResponsive__Title__inputContainer__textarea__counter">
                    {this.state.title.length}
                    {this.currentLenguaje(titleJson).de50}
                  </span>
                  <div className="titleResponsive__Title__error">
                    {this.state.errors["title"] ? (
                      <ErrorMessage message={this.state.errors["title"]} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="titleResponsive__Title__inputContainer">
                <h2 className="titleResponsive__Title__title">
                  {this.currentLenguaje(titleJson).descripcionAlohamiento}
                </h2>
                <p className="titleResponsive__Title__description">
                  {
                    this.currentLenguaje(titleJson)
                      .descripcionAlohamientoAclaracion
                  }
                </p>
                <div className="max_width">
                  <label
                    className="titleResponsive__Title__inputContainer__label"
                    htmlFor="housing_title"
                  >
                    {this.currentLenguaje(titleJson).descripcion}
                  </label>
                  <textarea
                    className="titleResponsive__Title__inputContainer__textarea"
                    name="description"
                    id="housing_description"
                    rows="4"
                    value={this.state.description}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    placeholder={
                      this.currentLenguaje(titleJson).describeAlohamiento
                    }
                  ></textarea>
                  <span className="titleResponsive__Title__inputContainer__textarea__counterDescription">
                    {this.state.description.length}
                    {this.currentLenguaje(titleJson).de1000}
                  </span>
                  <div className="titleResponsive__Title__error">
                    {this.state.errors["description"] ? (
                      <ErrorMessage
                        message={this.state.errors["description"]}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            {this.state.modeEdit ? (
              <ButtonEdit lenguaje={this.props.lenguaje} />
            ) : (
              <Buttons lenguaje={this.props.lenguaje} />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (reducers) => {
  return reducers.aloharFormReducer;
};

export default connect(mapStateToProps, aloharFormActions)(Title);
